import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import LoadingSpinner from '../../components/common/loader/LoadingSpinner'
import { home_path } from '../../config/pages_paths'
import { getRestaurant } from '../../store/actions/restaurantsActions'
import { getMenu, setMenu, setRestaurant } from './services/actions'

function RestaurantWrapper({ component: Component }) {
  const {
    restaurant: { restaurant, },
    // booking: { reservationDetails: { reservation } }
  } = useSelector(state => state)
  const { restaurantId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  const fetchData = useCallback(() => {
    if (restaurantId) {
      dispatch(getRestaurant(restaurantId, history))
      dispatch(getMenu(restaurantId))
    }
    setLoading(false)
  }, [dispatch, restaurantId, history])


  useEffect(() => {
    return () => {
      dispatch(setRestaurant({}))
      dispatch(setMenu({}))
    }
  }, [dispatch])

  const redirectHome = useCallback(() => {
    restaurant.availability === "coming soon" &&
      history.push(home_path)
  }, [restaurant, history])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    redirectHome()
  }, [redirectHome])

  return loading ? (
    <LoadingSpinner />
  ) : Component ? (
    <Component />
  ) : null
}

export default RestaurantWrapper
