import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import "./ExpirationTimerModal.css"
import { setExpirationTimerModal } from '../../store/actions/feedbackActions'
import clock from "../../assets/clock.svg";
import { expirationTimerButtonTiltAndMoveShakingAnimationStartTime,expirationTimerShakingAnimationShowingTime, expirationTimerShakingAnimationStartTime, expirationTimerShakingAnimationTotalApperingTime, expirationTimerVisibilityStartTime } from '../../config/keys'
import Modal from '../modal/Modal'
import CustomButton from '../common/CustomButton'
import { editTimerExpirationTime } from '../../pages/reservations/services/actions'
import { useHistory } from 'react-router'
import reconnectSocket from '../../handlers/reconnectSocket'

function ExpirationTimerModal({ timeLeft }) {
  const dispatch = useDispatch()
  const [showShakingAnimation, setShowShakingAnimation] = useState(false)
  const [showTiltAndShakeAnimation, setShowTiltAndShakeAnimation] = useState(false)
  const [waitForAnimation, setWaitForAnimation] = useState(true)
  const { booking: {currentReservation}, feedback: {expirationTimerModal}, orders: {ordersRounds}, auth: { user: { id } } } = useSelector(state => state)
  const reservationId = currentReservation?.reservation_id
  const expirationTimerModalEmptyData = useMemo(() => {
    return { show: false, text: "", paragraph: "" };
  }, []);
  const history = useHistory()

  const verifyActivity = () => {
    dispatch(editTimerExpirationTime(reservationId))
    dispatch(setExpirationTimerModal(expirationTimerModalEmptyData))

    reconnectSocket(currentReservation, id, history, dispatch)
  }

  if (timeLeft.seconds <= expirationTimerShakingAnimationStartTime) {
    if (showShakingAnimation === false && waitForAnimation === true) {
      setTimeout(() => {
        setShowShakingAnimation(true)
        setWaitForAnimation(false)
      }, expirationTimerShakingAnimationShowingTime * 1000)
    }

    if (showShakingAnimation === true && waitForAnimation === false) {
      setTimeout(() => {
        setShowShakingAnimation(false)
        setWaitForAnimation(false)
      }, expirationTimerShakingAnimationShowingTime * 1000)
      setTimeout(() => {
        setShowShakingAnimation(true)
        setWaitForAnimation(false)
      }, expirationTimerShakingAnimationTotalApperingTime * 1000)
    }
  }

  if (timeLeft.seconds <= expirationTimerButtonTiltAndMoveShakingAnimationStartTime) {
    showShakingAnimation && setShowShakingAnimation(false)
    !showTiltAndShakeAnimation && setShowTiltAndShakeAnimation(true)
  }

  useEffect(() => {
    if (timeLeft.seconds <= 0) {
      dispatch(setExpirationTimerModal(expirationTimerModalEmptyData))

      // if (ordersRounds.length === 0 || (ordersRounds.length === 1 && ordersRounds[0].round === null)) {
      //   const data = { show: true, text: "Your order session expired", paragraph: "Please order again and complete your order." }
      //   setTimeout(() => {
      //     dispatch(setTimerExpiredMessageModal(data))
      //   }, 3000)
      // }
    } else if (timeLeft.seconds > expirationTimerVisibilityStartTime) {
      dispatch(setExpirationTimerModal(expirationTimerModalEmptyData))
    }
  }, [dispatch, timeLeft, expirationTimerModalEmptyData, ordersRounds])

  return (
      <Modal isPopUp className="expiration-timer-modal expiration-timer-modal-verify-activity-modal" show={expirationTimerModal.show}>
      <div>
        <img alt="chef" className='expiration-timer-modal-clock-img' src={clock} />
        <h3>{expirationTimerModal.text}</h3>
        <p>
        {expirationTimerModal.paragraph}
        </p>
        <p className='message-margin-bottom'><strong>{timeLeft.time === "NaN:NaN" ? "Loading..." : timeLeft.time}</strong></p>
        <CustomButton
          onClick={verifyActivity}
          text={"Continue"}
          wrapperClassName={classnames("expiration-timer-modal-verify-activity-button", showShakingAnimation && "expiration-timer-modal-verify-activity-button-shake-animation", showTiltAndShakeAnimation && "expiration-timer-modal-verify-activity-button-tilt-n-move-shaking-animation")}
          textClassName="expiration-timer-modal-verify-activity-btn-text"
        />
      </div>
    </Modal>
  )
}

export default ExpirationTimerModal
