const versionConfigEnum = {
    empty_modifier_group: 'empty_modifier_group',
    show_table_number: 'show_table_number',
    show_booking_flow: 'show_booking_flow',
    is_anonymous_user_identification_enabled: 'is_anonymous_user_identification_enabled',
    restaurants_filter: 'restaurants_filter',
    navbar_links: 'navbar_links',
    menu_steps: 'menu_steps',
    menu_steps_user: 'menu_steps_user',
    host_booking_presence: 'host_booking_presence',
}

export default versionConfigEnum