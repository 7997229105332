import isEmpty from 'lodash.isempty'
import React from 'react'
import { CloseIcon } from '../../assets/svgs-google/GoogleIcons'
import limitStringLength from '../../utils/limitStringLength'
import Image from '../common/Image'

function MenuItemDetails({
  image,
  menu_item_name,
  name,
  about,
  price,
  unaltered_price,
  reduceDetailSize = true,
  modifierList,
  specialRequirments,
},) {
  const itemName = menu_item_name || name;

  return (
    <div className={'menu-item-details-container'}>
      <Image
        src={image}
        menu
      />
      <div>
        <h3>{reduceDetailSize ? limitStringLength(itemName, 35) : itemName}</h3>
        <p>{reduceDetailSize ? limitStringLength(about, 70) : about}</p>
        <span>${unaltered_price ? parseFloat(price) === parseFloat(unaltered_price) ? price : unaltered_price : price}</span>

        <div>
          {!isEmpty(modifierList) &&
            <>
              <h3>Selected modifiers</h3>
              <MenuItemModifiers modifierList={modifierList} />
            </>
          }
          {!isEmpty(specialRequirments) &&
            <div style={{ marginTop: "1rem" }}>
              <h3>Special requirements</h3>
              <h5>{specialRequirments}</h5>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default MenuItemDetails

export function MenuItemModifiers({ modifierList }) {
  return (
    <h5 style={{ marginTop: '0.5rem' }}>{modifierList}</h5>
  )
}

export function CloseButton({ onClick }) {
  return (
    <div onClick={onClick} className={'circle-close-btn'}>
      {/* <img
        className="text-center"
        alt="close"
        src={CloseIcon}
        style={iconStyle}
      /> */}
      {CloseIcon()}
    </div>
  )
}

export function NewCloseButton({ onClick }) {
  return (
    <div onClick={onClick} className={'new-circle-close-btn'}>
      <img
        className="text-center"
        alt="close"
        src={CloseIcon}
        style={iconStyle}
      />
    </div>
  )
}

const iconStyle = {
  cursor: 'pointer',
  width: '14px',
  height: '14px',
  objectFit: 'contain',
  right: '24px'
}
