import { isEmpty, isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { available_times_modal, password_reset_request_modal, register_success_modal } from '../../config/modal_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import useForm from '../../hooks/useForm'
import {
  login,
  // loginWithFacebook,
  loginWithGoogle, register, updateUser
} from '../../pages/auth/services/actions'
import { setActiveModal, setOnFullPageModal, setResponseMessage } from '../../store/actions/feedbackActions'
import { setNewUser } from '../../store/actions/usersActions'
import validateUserNewProfile from '../../validation/validateUserNewProfile'
import validateUserProfile from '../../validation/validateUserProfile'
import InputField from '../common/InputField'
import ModalHeading2 from '../common/ModalHeading2'
import Modal from '../modal/Modal'
// import FacebookLoginRender from 'react-facebook-login'
import GoogleLogin from 'react-google-login'
import AppleLogin from '../../pages/auth/login/AppleLogin'
import validateLogin from '../../validation/login'
import ExternalLoginButton from './ExternalLoginButton'
// import FacebookImg from '../../assets/facebook.svg'
import GoogleImg from '../../assets/google.svg'
import useFloatingIslandActions from '../../hooks/useFloatingIslandActions'
import ModalHeading from '../common/ModalHeading'

function AdditionalUserInfoModal({ show, close }) {
  const dispatch = useDispatch()
  const {
    errors,
    user: { user: userDetails, newUser },
    feedback: { activeModalMetadata, },
    booking: { invitationToken, timesAvailable },
  } = useSelector(state => state)
  const [formErrors, setErrors] = useState({})
  const [activeTab, setActiveTab] = useState("signup")


  const { proceed, DOMslot1, DOMslot2, createButtonPortal } = useFloatingIslandActions()

  useEffect(() => {
    dispatch(setOnFullPageModal(true))
    return () => dispatch(setOnFullPageModal(false))
  }, [dispatch])



  const onSubmit = () => {
    const newValues =
      (isEmpty(userDetails) || userDetails.is_anonymous)
        ? {
          email: signUpValues.email.trim(),
          first_name: signUpValues.first_name.trim(),
          last_name: signUpValues.last_name.trim(),
          phone: signUpValues.phone.trim(),
          new_password: signUpValues.new_password.trim(),
          confirm_password: signUpValues.confirm_password.trim(),
        } : {
          first_name: signUpValues.first_name.trim(),
          last_name: signUpValues.last_name.trim(),
          phone: signUpValues.phone.trim(),
        }
    setSignUpValues(newValues)

    const { errors, isValid } =
      (isEmpty(userDetails) || userDetails.is_anonymous)
        ? validateUserNewProfile(newValues)
        : validateUserProfile(newValues)

    if (!isValid) {
      setErrors(errors)
      return null
    } else {
      setErrors({})
    }

    (isEmpty(userDetails) || userDetails.is_anonymous)
      ? handleRegister(newValues)
      : handleUpdate(newValues)

  }

  const loginUser = async () => {
    const { errors, isValid } = validateLogin(loginValues)
    if (!isValid) {
      setErrors(errors)
      return null
    } else setErrors({})
    const result = await dispatch(login({ ...loginValues, reservation: invitationToken }))
    if (result && timesAvailable) {
      dispatch(setActiveModal(available_times_modal))
    }
  }

  const { values: loginValues, handleSubmit: loginHandleSubmit, handleChange: loginHandleChange } = useForm(
    { email: '', password: '' },
    loginUser
  )

  const { values: signUpValues, setValues: setSignUpValues, handleChange: handleSignUpChange, handleSubmit: handleSignUpSubmit } = useForm(
    {
      email: userDetails?.email?.includes("anonUser_") || !userDetails?.email ? "" : userDetails?.email,
      first_name: userDetails?.first_name || '',
      last_name: userDetails?.last_name || '',
      phone: userDetails?.phone || '',
      new_password: "",
      confirm_password: "",
    },
    onSubmit
  )

  const handleRegister = async (values) => {
    if (isEqual(values, newUser)) {
      dispatch(setActiveModal(register_success_modal, { ...values, onBooking: true }))
      // localStorageHelper.saveItem(BOOKING_DATA, bookingData)
    }
    else {
      const result = await dispatch(register(values))
      if (result) {
        dispatch(setNewUser(values))
        onModalClose()
        dispatch(setActiveModal(register_success_modal, { ...values, onBooking: true }))
      }
    }
  }

  const handleUpdate = async (values) => {
    const updatedUser = await dispatch(updateUser(values))
    if (updatedUser && timesAvailable) {
      dispatch(setActiveModal(available_times_modal))
    }
  }

  const onPasswordResetClickHandler = () => {
    dispatch(setActiveModal(password_reset_request_modal))
  }

  // const responseFacebook = response => {
  //   response && dispatch(loginWithFacebook(response.accessToken, invitationToken))
  // }

  const responseGoogle = response => {
    let token = ''
    if (response.access_token) token = response.access_token
    if (response.tokenObj) token = response.tokenObj.id_token
    response && dispatch(loginWithGoogle(token, invitationToken))
  }

  const onGoogleLoginFailure = response => {
    console.log('Google login failure ', response)
  }

  const onModalClose = () => {
    dispatch(setOnFullPageModal(false))
    close()
  }

  useEffect(() => {
    if (
      userDetails.first_name &&
      userDetails.last_name &&
      userDetails.phone
    )
      close()
  }, [userDetails, close])

  useEffect(() => {
    if (activeModalMetadata?.email || activeModalMetadata?.first_name) {
      setSignUpValues(activeModalMetadata)
    }
  }, [activeModalMetadata, setSignUpValues])

  // useEffect(() => {
  //   if (responseMessage) dispatch(getUser())
  // }, [responseMessage, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setResponseMessage(''))
    }
  }, [dispatch])


  /* check active title
        "Sign-up" :
        "Additional info required"
  */

  return (
    <Modal className="login-modal additional-user-modal" show={show} close={onModalClose}>
      {isEmpty(userDetails) || userDetails.is_anonymous ?
        <ModalHeading2 className="login-header" title1="Log-in" title2="Sign-up" activeTitle={activeTab === "signup" ? "Sign-up" : "Log-in"} onSignUp={() => setActiveTab("signup")} onLogin={() => setActiveTab("login")} onClick={onModalClose} />
        : <ModalHeading className="additional-user-heading" title="Additional info required" onClick={onModalClose} />
      }
      <Row>
        {activeTab === "signup" ? (
          <>
            <Column className="text-center align-items-center mt-4" md={12} lg={12}>
              <div className="login-heading">
                <h3>Please fill in the form</h3>
              </div>
            </Column>
            <Column style={{margin:"0 0 4rem"}}>
              <form onSubmit={handleSignUpSubmit}>
                <InputField
                  name="first_name"
                  onChange={handleSignUpChange}
                  label="First Name"
                  value={signUpValues.first_name}
                  error={formErrors.first_name || errors.first_name} />

                <InputField
                  name="last_name"
                  onChange={handleSignUpChange}
                  label="Last Name"
                  value={signUpValues.last_name}
                  error={formErrors.last_name || errors.last_name} />

                <InputField
                  name="phone"
                  onChange={handleSignUpChange}
                  label="Phone number"
                  info={'Please input the phone number with + and the country code'}
                  value={signUpValues.phone}
                  error={formErrors.phone || errors.phone} />

                <InputField
                  name="email"
                  onChange={handleSignUpChange}
                  label="E-mail address"
                  disabled={!(isEmpty(userDetails) || (!isEmpty(userDetails) && userDetails.is_anonymous === true))}
                  value={signUpValues?.email?.includes("anonUser_") || !signUpValues?.email ? "" : signUpValues.email}
                  error={formErrors.email || errors.email} />

                {isEmpty(userDetails) || userDetails.is_anonymous ?
                  <InputField
                    name="new_password"
                    type="password"
                    onChange={handleSignUpChange}
                    label="Password"
                    value={signUpValues.new_password}
                    error={formErrors.new_password || errors.new_password} /> :
                  null}
                {isEmpty(userDetails) || userDetails.is_anonymous ?
                  <InputField
                    name="confirm_password"
                    type="password"
                    onChange={handleSignUpChange}
                    label="Confirm Password"
                    value={signUpValues.confirm_password}
                    error={formErrors.confirm_password || errors.confirm_password} /> :
                  null}

                {/* <LoginButton onClick={onSubmit} /> */}
                {createButtonPortal(proceed(onSubmit, null, null, true), DOMslot1)}
              </form>
            </Column>

            {!userDetails.user_id &&
              <div id="footer" className="login-footer">
                <div className="already-have-account">
                  <span>
                    Already have an account?{' '}
                    <span className=" create-account" onClick={() => setActiveTab("login")}>
                      Log-in
                    </span>
                  </span>
                </div>
              </div>
            }
          </>
        ) : (
          <>
            <Column className="text-center align-items-center m-4" md={12} lg={12}>
              <div className="login-heading">
                <h3>Hey, good to see you again!</h3>
              </div>
            </Column>
            <Column className="mx-auto align-items-center" md={12} lg={12}>
              <form onSubmit={loginHandleSubmit}>
                <InputField
                  type="email"
                  required={true}
                  name="email"
                  onChange={loginHandleChange}
                  label="E-mail address"
                  value={loginValues.email}
                  error={formErrors.email || errors.email}
                />
                <InputField
                  type="password"
                  name="password"
                  onChange={loginHandleChange}
                  label="Password"
                  value={loginValues.password}
                  error={formErrors.password || errors.password}
                />

                <div className="external-login-btns mx-auto">
                  <span className="login-with" style={{ marginRight: '16px' }}>
                    Log-in with
                  </span>{' '}
                  {/* <FacebookLoginRender
                appId="847432139083677"
                autoLoad={false}
                callback={responseFacebook}
                disableMobileRedirect={true}
                fields="name,email,picture"
                scope="public_profile,email"
                isMobile={false}
                render={renderProps => (
                  <ExternalLoginButton
                    className="facebook"
                    onClick={renderProps.onClick}
                    img={FacebookImg}
                  />
                )}
              /> */}
                  <GoogleLogin
                    clientId="55700200557-8un2ipb96sqmhpuuf8ilh9riv062st5j.apps.googleusercontent.com"
                    render={renderProps => (
                      <ExternalLoginButton
                        onClick={renderProps.onClick}
                        img={GoogleImg}
                      />
                    )}
                    onSuccess={responseGoogle}
                    onFailure={() => onGoogleLoginFailure}
                  />
                  <AppleLogin />
                </div>
                <button
                  style={{ display: 'none' }}
                  type="submit"
                  onSubmit={loginHandleSubmit}
                />
                {/* <LoginButton onClick={loginUser} /> */}
                {createButtonPortal(proceed(loginUser, null, null, true), DOMslot1)}
              </form>
            </Column>
            <div id="footer" className="login-footer">
              <div className="forgot-password-container">
                <span>Forgot password? Happens to the best.</span>
                <br />
                <span
                  className="reset-password"
                  onClick={onPasswordResetClickHandler}
                >
                  {' '}
                  Re-set your password{' '}
                </span>
              </div>

              <div className="create-account-container">
                <span>
                  New to Voila?{' '}
                  <span className=" create-account" onClick={() => setActiveTab("signup")}>
                    Create an account
                  </span>
                </span>
              </div>
            </div>
          </>
        )}
      </Row>
    </Modal>
  )
}

export default AdditionalUserInfoModal
