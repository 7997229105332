import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
    reservations_path_now
} from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import useSocket from '../../hooks/useSocket'
import { setIsForWhomToPayConfirmed, setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { getUser, setIsPay, setIsProceededToCheckoutSelection } from '../../store/actions/usersActions'
import localStorageHelper from '../../utils/localStorageHelper'
import { getReservationDetails, setPayingForOthers } from "../book/services/actions"
import { setIsMenuStepsFeatureEnabled } from '../menu/services/actions'
import { getCheckoutInfo, getLastRoundOrders, getOrders, getOrdersNoRounds, getOrdersRounds, setNewNameForAnonGuest } from '../order/services/actions'
import ReservationTypes from './ReservationTypes'
import './ReservationsPage.css'
import { getIdsSomeonePaysFor, isSomeonePayingForCurrentUser } from './Reservationutils'
import {
    getCurrentReservation,
    getReservations,
    setOtherTryingToPay,
    setRefreshCheckout,
    setUserIdsSomeonePaysFor
} from './services/actions'
// import { MENU_STEP_FEATURE } from '../../constants/index'

function ReservationsPageWrapper({ component: Component }) {
    const {
        booking: { reservations, currentReservation, payingForOthersState, },
        auth: { isAuthenticated, user },
        user: { user: { user_id }, isProceededToCheckoutSelection },
        feedback: { connectSocketAfterIdleTimeout, refreshDataForOpenedMenuItem },
        // orders: { lastRoundOrders },
        versionConfig: { navbar_links: navbarLinks, menu_steps }
    } = useSelector(state => state)
    const dispatch = useDispatch()

    const location = useLocation()
    const history = useHistory()
    const [showOrdersRoundsComponent, setShowOrdersRoundsComponent] = useState(false)

    const onRefresh = useCallback((reservation_id) => {
        if (reservation_id) {
            dispatch(getCurrentReservation());
            dispatch(getOrders(reservation_id));
            dispatch(getOrdersRounds(reservation_id));
            dispatch(getOrdersNoRounds(reservation_id));
            dispatch(setRefreshCheckout(true));
            dispatch(setIsPay(false));
            dispatch(getLastRoundOrders(reservation_id));
            isProceededToCheckoutSelection && dispatch(setIsProceededToCheckoutSelection(false));

            if (refreshDataForOpenedMenuItem.menuItemId) {
                dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: true }));
            } else {
                dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: null, refresh: false }));
            }
        }
    },
        [dispatch, refreshDataForOpenedMenuItem.menuItemId, isProceededToCheckoutSelection]
    );

    const onPaymentExpired = useCallback(({ data }) => {
        // workaround for when the payment resets properly the socket is called 2 times
        // if the payment hasn't been reset properly the socket is called once
        for (let i = 0; i < 2; i++) {
            dispatch(setPayingForOthers(payingForOthersState.filter(state => state !== data)))
            if (data.payerId === user.id) {
                dispatch(getCheckoutInfo(currentReservation?.reservation_id, [user.id]))
                dispatch(setIsPay(false))
                dispatch(setIsProceededToCheckoutSelection(false))
            }
        }
    }, [dispatch, user.id, currentReservation?.reservation_id, payingForOthersState])

    const onRefreshWithoutResetingIsPay = useCallback(
        reservation_id => {
            if (reservation_id) {
                dispatch(getOrders(reservation_id, true))
                dispatch(getOrdersRounds(reservation_id))
                dispatch(getOrdersNoRounds(reservation_id))
                dispatch(setRefreshCheckout(true))
                dispatch(getLastRoundOrders(reservation_id))
            }
        },
        [dispatch]
    )

    const updateIsMenuStepsFeatureEnaled = useCallback(() => {
        const menuStepsFeatureExists = localStorageHelper.exists("MENU_STEP_FEATURE")
        const menuStepFeature = localStorageHelper.getItem("MENU_STEP_FEATURE")
        dispatch(setIsMenuStepsFeatureEnabled(menuStepsFeatureExists ? menuStepFeature : menu_steps))
    }, [dispatch, menu_steps])

    const onOtherTryingToPay = useCallback(
        ({ usersTryingToPay }) => {
            dispatch(
                setOtherTryingToPay(
                    isSomeonePayingForCurrentUser(usersTryingToPay, user.id)

                )
            )

            dispatch(
                setUserIdsSomeonePaysFor(
                    getIdsSomeonePaysFor(usersTryingToPay, user.id)
                )
            )

            dispatch(setPayingForOthers(usersTryingToPay))
        },
        [dispatch, user.id]
    )

    const onUserInfoChanged = useCallback(
        (data) => {
            dispatch(setNewNameForAnonGuest(data.userId, data.userName))
        },
        [dispatch]
    )
  
    const onGuestsPresenceChanged = useCallback((reservationId) => {
        dispatch(getReservationDetails(reservationId, history))
        dispatch(getOrdersRounds(reservationId))
    }, [dispatch, history])

    const { emitFriendSelected } = useSocket({
        reservationId: (currentReservation || {}).reservation_id,
        shouldConnect: location.pathname === reservations_path_now && !connectSocketAfterIdleTimeout,
        onRefresh,
        onGuestsPresenceChanged,
        onOtherTryingToPay,
        onRefreshWithoutResetingIsPay,
        onPaymentExpired,
        onUserInfoChanged
    })

    useEffect(() => {
        updateIsMenuStepsFeatureEnaled()
    },[updateIsMenuStepsFeatureEnaled])

    useEffect(() => {
        async function fetchData() {
            await dispatch(getCurrentReservation())
        }
        fetchData()
    }, [dispatch])

    useEffect(() => {
        if (!user_id) {
            dispatch(getUser())
        }
    }, [dispatch, user_id])

    useEffect(() => {
        isAuthenticated && dispatch(getReservations())
    }, [dispatch, isAuthenticated])

    useEffect(() => {
        if (currentReservation?.reservation_id) {
            dispatch(getLastRoundOrders(currentReservation?.reservation_id))
            setShowOrdersRoundsComponent(true)
        }
    }, [dispatch, currentReservation?.reservation_id])

    useEffect(() => {
        return () => {
            dispatch(setIsPay(false))
            dispatch(setIsProceededToCheckoutSelection(false))
            dispatch(setIsForWhomToPayConfirmed(false))
        }
    }, [dispatch])


    return (
        <>
            <Row className="reservations-row justify-content-center">
                <Column md={10} lg={8}>
                    <Row>
                        {!!navbarLinks
                            ? <Column md="4" lg="4">
                                {/*Renamed from Reservations to Dining*/}
                                <h2 className="page-heading">Dining</h2>
                                <ReservationTypes
                                    upcomingBadge={reservations?.upcoming.length ?? 0}
                                    ongoingBadge={reservations?.ongoing ? 1 : 0}
                                    pastBadge={reservations?.past.length ?? 0}
                                />
                            </Column>
                            : <Column md="4" lg="4" />
                        }
                        <Column className="scrollable-list" md="8" lg="8">
                            {
                                <Component
                                    emitFriendSelected={emitFriendSelected}
                                    showOrdersRoundsComponent={showOrdersRoundsComponent}
                                    reservationId={currentReservation?.reservation_id}
                                    pastReservations={reservations.past}
                                    upcomingReservations={reservations.upcoming}
                                    // handleReservationHavigation={handleReservationHavigation}
                                    history={history}
                                />
                            }
                        </Column>
                    </Row>
                </Column>
            </Row>
        </>
    )
}


export default ReservationsPageWrapper