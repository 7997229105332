import classnames from 'classnames'
import isEmpty from 'lodash.isempty'
import React from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Column from '../../containers/layout/Column'
import Image from '../common/Image'
import ManageReservation2 from './ManageReservation2'
import './RestaurantHeaderImage.css'

function RestaurantHeaderImage({
  images,
  className = 'text-center',
  reservation,
  latestUpcoming,
  // reservations,
  // reservationOnCurrentRestaurant,
  restaurantIdOnCurrentRestaurant,
  // reservationTypeOnCurrentRestaurant,
  // ongoing,
}) {


  return (
    <Column className={classnames(className, 'mb-4')} md="12" lg="10" xl={8}>
      {!!images.length &&
        <Carousel
          infiniteLoop
          autoPlay={true}
          interval={10000}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            let className = 'dot '
            if (isSelected) {
              className += 'selected '
            }

            if (!isEmpty(reservation)) {
              className += 'dots-margin-bottom'
            }

            return <li className={className} onClick={onClickHandler}
              onKeyDown={onClickHandler} role="button" tabIndex="0" aria-label={`slide item ${index}`} value="1"></li>
          }}
        >
          {images.map((imgSrc, index) => {
            return (
              // <LazyLoadImage
              //   alt={'Restaurant-Image'}
              //   effect="blur"
              //   height={'100%'}
              //   width={'100%'}
              //   style={{ maxHeight:"640px", objectFit: 'fill'}}
              //   src={imgSrc || EmptyRestaurantImagePlaceholder}
              //   key={index}
              //   className="restaurant-carousel-image"
              //   placeholderSrc={EmptyRestaurantImagePlaceholder}
              //   onError={onImageError}
              // />
              <Image
                key={index}
                alt={'restaurant-image'}
                style={{ maxHeight: "640px", objectFit: 'cover', minHeight: "250px", minWidth: "350px" }}
                src={imgSrc}
                className="restaurant-carousel-image"
              />
            )
          })}
        </Carousel>
      }
      {/* {reservationOnCurrentRestaurant ?
      <ManageReservation
      reservationTypeOnCurrentRestaurant={reservationTypeOnCurrentRestaurant}
      restaurantIdOnCurrentRestaurant={restaurantIdOnCurrentRestaurant}
      ongoing={ongoing}
      {...reservation} /> : null} */}
      {
        !isEmpty(latestUpcoming) && latestUpcoming?.restaurant_id === restaurantIdOnCurrentRestaurant ?
          <ManageReservation2
            {...latestUpcoming}
          /> :
          null
      }
    </Column>
  )
}

export default RestaurantHeaderImage
