import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clock from "../../assets/clock.svg";
import { home_path } from '../../config/pages_paths';
import { clearRestaurantReservation } from '../../store/actions/restaurantsActions';
import { setCurrentReservation } from '../../pages/reservations/services/actions';
import { setTimerExpiredMessageModal } from '../../store/actions/feedbackActions';
import CustomButton from '../common/CustomButton';
import Modal from '../modal/Modal';
import "./TimerExpiredMessageModal.css";

function TimerExpiredMessageModal() {
    const dispatch = useDispatch()
    const { feedback: { timerExpiredMessageModal } } = useSelector(state => state)
    const history = useHistory()

    const close = () => {
      const data = { show: false, text: "", paragraph: "" }
      dispatch(setTimerExpiredMessageModal(data))
      dispatch(setCurrentReservation(null))
      dispatch(clearRestaurantReservation())
      history.push(home_path)
    }

  return (
      <Modal isPopUp className="timer-expired-message-modal timer-expired-message-modal-verify-activity-modal" show={timerExpiredMessageModal.show} close={close}>
      <div>
        <img alt="chef" className='timer-expired-message-modal-clock-img' src={clock} />
        <h3>{timerExpiredMessageModal.text}</h3>
        <p>{timerExpiredMessageModal.paragraph}</p>
        <CustomButton
          onClick={close}
          text={"Okay"}
          wrapperClassName={("timer-expired-message-modal-verify-activity-button")}
          textClassName="timer-expired-message-modal-verify-activity-btn-text"
        />
      </div>
    </Modal>
  )
}

export default TimerExpiredMessageModal
