import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { expirationTimerDurationTime, expirationTimerVisibilityStartTime } from '../config/keys'
import { home_path } from '../config/pages_paths'
import { orderStatusEnum } from '../enums/orderStatusEnum'
import { getTimerExpirationTime } from '../pages/reservations/services/actions'
import { setActiveModal, setExpirationTimerModal, setTimerExpiredMessageModal } from '../store/actions/feedbackActions'
import { debounce } from "lodash"
import { setClearReservationOrders } from '../pages/order/services/actions'

const useExpirationTimer = () => {
  const dispatch = useDispatch()
  const {
    booking: { currentReservation },
    orders: { ordersRounds },
  } = useSelector(state => state)
  const history = useHistory()
  const [timeLeft, setTimeLeft] = useState({ time: null, seconds: null })
  const [showTimer, setShowTimer] = useState({ show: false, reset: 0 })
  const expirationTime = currentReservation?.expiration_time

  const showExpirationTimerModalData = {
    show: true, text: "Are you still here?", paragraph: " Your order is not completed, please complete your order for the selected items. Otherwise, this session will expire and you will have to select your items again."
  }
  const hideExpirationTimerModalData = { show: false, text: "", paragraph: "" }

  const refreshExpirationToken = useCallback(
    debounce((reservationId) => {
      if (ordersRounds.length >= 1 && ordersRounds[0].round !== null) return;
      dispatch(getTimerExpirationTime(reservationId));
    }, 1000),
    [dispatch]
  );

  const orderExpired = useCallback(
    debounce(() => {
      history.push(home_path);
      const data = { show: true, text: "Your order session expired", paragraph: "Please order again and complete your order." }
      dispatch(setExpirationTimerModal(hideExpirationTimerModalData));
      dispatch(setTimerExpiredMessageModal(data));
      // Clear User Reservation Orders
      dispatch(setClearReservationOrders())
  }, 1000),
    [dispatch]
  );

  // add x more minutes to the current time
  const expirationDate = useMemo(() => {
    let date = new Date(expirationTime);
    date.setMinutes(date.getMinutes() + expirationTimerDurationTime);

    return new Date(date);
  }, [expirationTime]);

  useEffect(() => {
    if (!expirationTime) return;
    if (ordersRounds.length >= 1 && ordersRounds[0].round !== null) return;

    const recalculateAndFormatTimeLeft = () => {
      // this function makes e.g. time: 6:1 to be time: 06:01
      function leftPad(aNumber, aLength) {
        if (aNumber.toString().length >= aLength) return aNumber;
        return (Math.pow(10, aLength) + Math.floor(aNumber)).toString().substring(1);
      }

      const msLeft = new Date(expirationDate) - new Date()

      const totalSeconds = Math.floor(msLeft / 1000)
      const minutes = leftPad(Math.floor(totalSeconds / 60), 2);
      const seconds = leftPad(totalSeconds - minutes * 60, 2);

      const totalTime = minutes + ":" + seconds

      setTimeLeft({ time: totalTime, seconds: totalSeconds })
    }

    recalculateAndFormatTimeLeft()
    const timerId = setInterval(recalculateAndFormatTimeLeft, 1000)

    return () => {
      clearInterval(timerId)
    }
  }, [expirationTime])

  useEffect(() => {
    if (timeLeft.seconds !== null && Math.sign(timeLeft.seconds) === -1) return; // checks if number have negative value

    if (timeLeft.seconds !== null && timeLeft.seconds <= 0) {
      dispatch(setExpirationTimerModal(hideExpirationTimerModalData))
      setTimeLeft({ time: null, seconds: null })
      setShowTimer({ show: false, reset: false })
    } else {
      if (timeLeft.seconds !== null && timeLeft.seconds <= expirationTimerVisibilityStartTime && currentReservation?.order_status === orderStatusEnum.orderStarted) {
        if (!showTimer.show && !showTimer.reset) setShowTimer({ ...showTimer, show: true })

        if (showTimer.show && !showTimer.reset) {
          setShowTimer({ ...showTimer, reset: true })
          dispatch(setExpirationTimerModal(showExpirationTimerModalData))
          dispatch(setActiveModal(""))
        }
      } else {
        if (showTimer.show) {
          setShowTimer({ show: false, reset: false })
          dispatch(setExpirationTimerModal(hideExpirationTimerModalData))
        }
      }
    }
  }, [timeLeft, ordersRounds, expirationTimerVisibilityStartTime])

  useEffect(() => {
    if (timeLeft.seconds <= 0 && timeLeft.seconds !== null && currentReservation?.order_status === orderStatusEnum.orderStarted) {
      if (ordersRounds.length === 0 || (ordersRounds.length === 1 && ordersRounds[0].round === null)) {
        orderExpired()
      }
    }
  }, [timeLeft.seconds, orderExpired])

  return {
    // activeModal,
    timeLeft,
    // refreshReservations,
    refreshExpirationToken,
    expirationTime,
    orderExpired
  }
}


export default useExpirationTimer
