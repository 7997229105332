import isEmpty from 'lodash.isempty'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import BookingOptions from '../../components/book/BookingOptions'
import {
  booking_reservation_path
} from '../../config/pages_paths'
import { createFormattedDateString } from '../../config/timeDate'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import { setRestaurant } from '../restaurant/services/actions'
import './BookingPage.css'
import { bookTable, searchFreeTables, setBookingSuccess } from './services/actions'
import localStorageHelper from '../../utils/localStorageHelper'
import { BOOKING_DATA } from '../../constants'

function BookingPage() {
  const {
    restaurant: { restaurant },
    restaurants: { restaurants },
    feedback: { responseMessage },
    booking: { reservationId },
    user: { user },
    auth: {isAuthenticated }
  } = useSelector(state => state)

  const { restaurantId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  // const { hasAllFields, onMissingFields } = useUserDetails({
  //   requireAllFields: true
  // })

  useEffect(() => {
    if (!isEmpty(restaurants)) {
      const restaurantFromId =
        restaurants.find(val => +val.restaurant_id === +restaurantId) || {}
      dispatch(setRestaurant(restaurantFromId))
    }
  }, [dispatch, restaurantId, restaurants, restaurant])

  const onBookClickHandler = (
    date,
    sizeOfParty = 1,
    specialRequest,
    emailMarketingOpt,
    tableType
  ) => {
    const formattedDate = createFormattedDateString(date)
    dispatch(
      bookTable(
        {
          date: formattedDate,
          sizeOfParty,
          specialRequest,
          emailMarketingOpt,
          tableType
        },
        restaurantId
      )
    )
  }

  useEffect(() => {
    if (responseMessage && reservationId) {
      history.push(
        booking_reservation_path
        .replace(':restaurantId', restaurantId)
        .replace(':reservationId', reservationId)
        )
        dispatch(setBookingSuccess(true))
      }
  }, [dispatch,responseMessage, reservationId, history, restaurantId])

  useEffect(() => {
    const bookingDataStorage = localStorageHelper.getItem(BOOKING_DATA);
    if(bookingDataStorage) {
      dispatch(searchFreeTables(bookingDataStorage, isAuthenticated));
      localStorageHelper.removeItem(BOOKING_DATA);
    }
  },[dispatch, isAuthenticated])


  // useEffect(() => {
  //   dispatch(getUser())
  //   return () => {
  //     dispatch(setActiveModal(''))
  //   }
  // }, [dispatch])

  return (
    <Row className="mb-4">
      <Column className="text-center booking-left-column" md={6} lg={4}>
        {restaurant?.image ?
          <img
            alt="restaurant"
            className="booking-restaurant-image"
            src={restaurant.image}
          /> :
          <div className='booking-restaurant-image'></div>
        }
        {/* <Image

alt="restaurant"
className="booking-restaurant-image"
src={restaurant.image}
        /> */}
        <div className="booking-restaurant-heading mx-auto">{restaurant.name}</div>
        <div className="booking-restaurant-address mx-auto">
          {restaurant.address}
        </div>
      </Column>
      <Column className="booking-settings-column" md={6} lg={4}>
        <BookingOptions
          restaurantId={restaurantId}
          timezone={restaurant.timezone}
          // hasAllFields={hasAllFields}
          onBookClickHandler={
            // hasAllFields ?
            onBookClickHandler
            // : onMissingFields
          }
          user={user}
          isAuthenticated={isAuthenticated}
        />
      </Column>
    </Row>
  )
}

export default BookingPage
