import { isNull } from 'lodash'
import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import AddMenuItemModalNew from '../../components/orders/AddMenuItemModalNew'
import RecommendedMenuItemsModal from '../../components/orders/RecommendedMenuItemsModal'
import MenuItemDetailsModal from '../../components/restaurant/MenuItemDetailsModal'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import queryParamsEnum from '../../enums/queryParamsEnum'
import useQuery from '../../hooks/useQuery'
import { setOnFullPageModal, setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { setIsPay } from '../../store/actions/usersActions'
import { getModifiersForMenuItem, getSelectedModifiersForOrderItem, setModifiersForMenuItem, setRestaurantById, setSelectedModifiersForOrderItem } from '../restaurant/services/actions'
import ActiveSectionItems from './ActiveSectionItems'
import MenuListApproved from './MenuListApproved'
import './RestaurantMenuApproved.css'
import { getRestaurantMenuApproved, setActiveMenuAndSectionInfoApproved, setIsMenuStepsFeatureEnabled, setMenuDrawerOpened, setMenuItemRecommendations, setMenuOrdersDrawerOpened, setMenuSelectedStep, setRestaurantMenuApproved } from './services/actions'
import MenuOrders from '../../components/ordersRounds/MenuOrders'
import { useMediaQuery } from 'react-responsive';
import localStorageHelper from '../../utils/localStorageHelper'
import useQuantityActions from '../../hooks/useQuantityActions'
import { MENU_STEP_FEATURE } from '../../constants'
import { booking_reservation_path } from '../../config/pages_paths'


const RestaurantMenuApproved = ({
    onBooking = false,
    onReservation = false,
    restaurantIdFromReservation,
    reservationId,
    orderQuantities,
    onAddClickHandler = () => { },
}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { restaurantId: restaurantIdParams } = useParams()
    const { getQueryParam, setQueryParam } = useQuery()
    const queryParam = getQueryParam(queryParamsEnum.step)

    const {
        restaurants: { restaurants },
        restaurant: { restaurant, modifiersFromDb, selectedModifiersFromDb },
        orders: { lastRoundOrders, menuItemsQuantity, sectionItemsQuantity },
        menu: {
            restaurantMenuApproved,
            selectedMenuAndSectionsByStep,
            uniqueMenuSectionSteps,
            menuSelectedStep,
            activeMenuAndSectionInfoApproved: {
                activeMenuId,
                activeMenuName,
                activeMenuSectionId,
                activeMenuSectionName,
            },
            activeMenuSectionItemsApproved,
            // activeMenuSectionItemsWithRoundForUserApproved,
            menuItemRecommendations,
            onMenuItemRecommendationsModal,
            menuDrawerOpened,
            menuOrdersDrawerOpened,
            isMenuStepsFeatureEnabled
        },
        user: { isPay, user },
        feedback: { onFullPageModal, refreshDataForOpenedMenuItem },
        versionConfig: { empty_modifier_group, menu_steps, menu_steps_user }
    } = useSelector(state => state)

    //local state
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const drawerRef = useRef(null)
    const activeMenuSectionItemsApprovedRef = useRef(null);

    const [menuItemDetails, setMenuItemDetails] = useState({})
    const [recommendedItemDetails, setRecommendedItemDetails] = useState({})
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const [restaurantId, setRestaurantId] = useState(null)
    const [hasInitialMenuOpened, setHasInitialMenuOpened] = useState(false)
    const [onMenuItemDetailsModal, setOnMenuItemDetailsModal] = useState(false)
    const [onAddEditDeleteItemModal, setOnAddEditDeleteItemModal] = useState(false)
    const [onAddRecommendedMenuItemsModal, setOnAddRecommendedMenuItemsModal] = useState(false)

    const { handleGetItemInfo, handleItemQuantityUpdate } = useQuantityActions()

    // #region functions

    const handleIsMenuStepsFeatureEnabled = () => {
        dispatch(setIsMenuStepsFeatureEnabled(isMenuStepsFeatureEnabled ? false : true))
        localStorageHelper.saveItem(MENU_STEP_FEATURE, !isMenuStepsFeatureEnabled)
        isMenuStepsFeatureEnabled && setQueryParam(queryParamsEnum.step)
    }

    const handleMenuItemDetails = async (itemDetails) => {
        // dispatch(setModifiersForMenuItem([]))
        // dispatch(setSelectedModifiersForOrderItem([]))
        if (itemDetails) {
            handleGetItemInfo(itemDetails, setMenuItemDetails, reservationId)

            if (onReservation && restaurantId) {
                await handleModifiersReset(itemDetails.menu_item_id, itemDetails).then(() => {
                    setOnAddEditDeleteItemModal(true)
                })
            }
            else {
                setOnMenuItemDetailsModal(true)
            }

        }
        else {
            setMenuItemDetails({})
            setOnMenuItemDetailsModal(false)
        }
    }

    const handleModifiersReset = async (menuItemId, itemDetails) => {
        const itemFromOrders = lastRoundOrders.find(val => val.menu_item_id === menuItemId) || {}
        await dispatch(setRefreshDataForOpenedMenuItem({ menuItemId, refresh: false }))
        if (itemFromOrders && itemFromOrders.order_item_id) {
            await dispatch(getSelectedModifiersForOrderItem(itemFromOrders.order_item_id, restaurantId))
        }
        else {
            await dispatch(getModifiersForMenuItem(itemDetails.menu_item_id, itemDetails.menu_item_section_id, restaurantId))
        }
        if (isPay) {
            dispatch(setIsPay(false))
        }
    }

    const handleRecommendedItemDetails = async (itemDetails) => {
        if(itemDetails) {
            // const itemDetails = activeMenuSectionItemsApproved?.find(item => item.menu_item_id === recommendedItemId) || menuItemRecommendations?.find(item => item.menu_item_id === recommendedItemId)
            handleGetItemInfo(itemDetails, setRecommendedItemDetails, reservationId)

            await handleModifiersReset(itemDetails.menu_item_id, itemDetails).then(() => {
                setOnAddRecommendedMenuItemsModal(false)
                setOnAddEditDeleteItemModal(true)
            })
        }
    }

    const handleOnCloseMenuItemModal = () => {
        setOnAddEditDeleteItemModal(false)
        setMenuItemDetails({})
        if (menuItemRecommendations) {
            dispatch(setMenuItemRecommendations({
                menuItemRecommendations: [],
                lastRoundOrders
            }))
        }
        dispatch(setOnFullPageModal(false))
        // dispatch(setSelectedModifiersForOrderItem([]))
        dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: null, refresh: false }))
    }

    const handleOnAddRecommendedItemModal = () => {
        setOnAddEditDeleteItemModal(false)
        setOnAddRecommendedMenuItemsModal(true)
        dispatch(setModifiersForMenuItem([]))
        dispatch(setSelectedModifiersForOrderItem([]))
        setRecommendedItemDetails({})
    }

    const handleCloseRecommendedMenuItemModal = () => {
        if (menuItemRecommendations) {
            setOnAddRecommendedMenuItemsModal(true)
            setRecommendedItemDetails({})
            dispatch(setModifiersForMenuItem([]))
            dispatch(setSelectedModifiersForOrderItem([]))
        }
        setOnAddEditDeleteItemModal(false)
    }

    const handleCloseMenuItemRecommendationModal = () => {
        setOnAddRecommendedMenuItemsModal(false)
        setMenuItemDetails({})
        if (menuItemRecommendations) {
            dispatch(setMenuItemRecommendations({
                menuItemRecommendations: [],
                lastRoundOrders
            }))
        }
        dispatch(setOnFullPageModal(false))
    }

    const handleIsMenuOpened = () => {
        setIsMenuOpened(prevState => !prevState)
    }

    const handleIsMenuDrawerOpened = () => {
        menuOrdersDrawerOpened && dispatch(setMenuOrdersDrawerOpened(false))
        dispatch(setMenuDrawerOpened(menuDrawerOpened ? false : true))
    }
    const handleIsMenuOrdersDrawerOpened = () => {
        menuDrawerOpened && dispatch(setMenuDrawerOpened(false))
        dispatch(setMenuOrdersDrawerOpened(menuOrdersDrawerOpened ? false : true))
    }

    const handleChangeMenu = (menuId, menuName) => {
        if (menuId !== activeMenuId && menuName !== activeMenuName) {
            if (!isMenuOpened) {
                handleIsMenuOpened()
            }
            dispatch(setActiveMenuAndSectionInfoApproved({ menuId, menuName, lastRoundOrders, isMenuStepsFeatureEnabled, menuSelectedStep }))
            // updateOrderDataOnMenuItems({activeMenuSectionItemsApproved, })
        }
        else {
            handleIsMenuOpened()
        }
    }

    const handleChangeMenuSection = (menuSectionId, menuSectionName) => {
        if (menuSectionId !== activeMenuSectionId && menuSectionName !== activeMenuSectionName) {
            dispatch(setActiveMenuAndSectionInfoApproved({ menuSectionId, menuSectionName, lastRoundOrders }))
            // updateOrderDataOnMenuItems({activeMenuSectionItemsApproved, lastRoundOrders})
        }
        // Acocate to the upper condition to close the drawer only on new selection of a menu section if needed
        if (isMobile) {
            handleIsMenuDrawerOpened()
        }
    }

    const handleBackToBooking = () => {
        history.push(
            booking_reservation_path
            .replace(':restaurantId', restaurantId)
            .replace(':reservationId', reservationId)
            )
    }

    // #endregion


    // #region callbacks

    const updateMenuStepsOnReservationLoad = useCallback(() => {
        if (!!isMenuStepsFeatureEnabled && !isEmpty(uniqueMenuSectionSteps) && !!!queryParam && !isNull(menuSelectedStep)) {
            dispatch(setMenuSelectedStep(uniqueMenuSectionSteps[0]))
        }
    }, [isMenuStepsFeatureEnabled, dispatch, uniqueMenuSectionSteps, queryParam, menuSelectedStep])

    const updateMenuSectionStep = useCallback(() => {
        if (!isEmpty(uniqueMenuSectionSteps) && queryParam) {
            const foundIndex = uniqueMenuSectionSteps.findIndex(step => step.name === queryParam);

            if (foundIndex !== -1) {
                dispatch(setMenuSelectedStep(uniqueMenuSectionSteps[foundIndex]));
            } else {
                dispatch(setMenuSelectedStep(uniqueMenuSectionSteps[0]));
            }
        }

    }, [dispatch, uniqueMenuSectionSteps, queryParam,])


    const updateSectionAndItemsBasedOnStep = useCallback(() => {
        if (menuSelectedStep && !!isMenuStepsFeatureEnabled && selectedMenuAndSectionsByStep) {
            dispatch(setActiveMenuAndSectionInfoApproved({ selectedMenuAndSectionsByStep }))
        }
    }, [dispatch, menuSelectedStep, selectedMenuAndSectionsByStep, isMenuStepsFeatureEnabled])

    // const updateOrdersOnMenuItems = useCallback(() => {
    //     if (onReservation ) {
    //         dispatch(setActiveMenuAndSectionInfoApproved({ lastRoundOrders }));
    //     }
    // }, [dispatch, onReservation, lastRoundOrders])

    const updateIsMenuStepsFeatureEnaled = useCallback(() => {
        const menuStepsFeatureExists = localStorageHelper.exists(MENU_STEP_FEATURE)
        const menuStepFeature = localStorageHelper.getItem(MENU_STEP_FEATURE)
        dispatch(setIsMenuStepsFeatureEnabled(menuStepsFeatureExists ? menuStepFeature : menu_steps))
    }, [dispatch, menu_steps])

    const updateMenuItemRecommendations = useCallback(() => {
        if (onReservation && !isEmpty(menuItemDetails) && lastRoundOrders) {
            dispatch(setMenuItemRecommendations({
                menuItemRecommendations: menuItemDetails?.menu_item_recommended_external_ids,
                lastRoundOrders
            }))
        }
    }, [dispatch, onReservation, menuItemDetails, lastRoundOrders]);

    const updateMenuItemModifiers = useCallback(() => {
        if (isEmpty(menuItemDetails)) {
            if (!isEmpty(modifiersFromDb)) {
                dispatch(setModifiersForMenuItem([]))
            }
            if (!isEmpty(selectedModifiersFromDb)) {
                dispatch(setSelectedModifiersForOrderItem([]))
            }
        }
    }, [dispatch, menuItemDetails, modifiersFromDb, selectedModifiersFromDb])

    // const filterMenuItemsBasedOnSteps = useCallback(() => {
    //     if (isMenuStepsFeatureEnabled && menuSelectedStep) {
    //         // Implement your logic to filter menu items based on steps
    //         // You can use selectedMenuAndSectionsByStep or other relevant data
    //         const filteredMenu = selectedMenuAndSectionsByStep.filter(/* Your filtering logic here */);
    //         setMenuOnSoManyLevels(filteredMenu);

    //         // Calculate and update quantity for each menu based on the active step and sections
    //         const updatedQuantity = {};
    //         filteredMenu.forEach(menu => {
    //             const menuId = menu.menu_id;
    //             const sectionsInStep = /* Logic to get sections for the current step */;
    //             const quantityForSections = sectionsInStep.reduce((total, section) => {
    //                 return total + (menuItemsQuantity[section.menu_item_section_id] || 0);
    //             }, 0);
    //             updatedQuantity[menuId] = quantityForSections;
    //         });
    //         setUpdatedMenuItemsQuantity(updatedQuantity);
    //     } else {
    //         // If the feature is not enabled, use the original menu
    //         setMenuOnSoManyLevels(restaurantMenuApproved);

    //         // Use the original menuItemsQuantity
    //         setUpdatedMenuItemsQuantity(menuItemsQuantity);
    //     }
    // }, [isMenuStepsFeatureEnabled, menuSelectedStep, selectedMenuAndSectionsByStep, restaurantMenuApproved, menuItemsQuantity]);


    // #endregion

    // #region callback effects

    useEffect(() => {
        updateMenuStepsOnReservationLoad()
    }, [updateMenuStepsOnReservationLoad])

    useEffect(() => {
        updateIsMenuStepsFeatureEnaled()
    }, [updateIsMenuStepsFeatureEnaled])


    useEffect(() => {
        updateMenuSectionStep()
    }, [updateMenuSectionStep])

    useEffect(() => {
        updateMenuItemRecommendations();
    }, [updateMenuItemRecommendations]);

    useEffect(() => {
        updateMenuItemModifiers()
    }, [updateMenuItemModifiers])

    useEffect(() => {
        updateSectionAndItemsBasedOnStep()
    }, [updateSectionAndItemsBasedOnStep])

    // useEffect(() => {
    //     updateOrdersOnMenuItems()
    // }, [updateOrdersOnMenuItems])

    useEffect(() => {
        if (refreshDataForOpenedMenuItem.menuItemId && refreshDataForOpenedMenuItem.refresh) {
            if (!isEmpty(recommendedItemDetails)) {
                // Update reservation selected quantity when recommended item is opened
                handleItemQuantityUpdate(refreshDataForOpenedMenuItem.menuItemId, reservationId, recommendedItemDetails, setRecommendedItemDetails)
            } else if (!isEmpty(menuItemDetails)) {
                //  Update reservation selected quantity when single item is opened
                handleItemQuantityUpdate(refreshDataForOpenedMenuItem.menuItemId, reservationId, menuItemDetails, setMenuItemDetails)
            }
        }
    }, [refreshDataForOpenedMenuItem.menuItemId, refreshDataForOpenedMenuItem.refresh])

    // #endregion

    // #region effects
    useEffect(() => {
        if (isMenuStepsFeatureEnabled) {
            const queryParamStepStorage = localStorageHelper.getItem(queryParamsEnum.step);
            if (queryParamStepStorage) {
                setQueryParam(queryParamsEnum.step, queryParamStepStorage)
            }
            else {
                if (uniqueMenuSectionSteps && !queryParam) {
                    setQueryParam(queryParamsEnum.step, uniqueMenuSectionSteps[0]?.name);
                }
            }
        }
    }, [isMenuStepsFeatureEnabled, uniqueMenuSectionSteps, queryParam, setQueryParam]);


    useEffect(() => {
        !onReservation && !isNaN(+restaurantIdParams) ?
            setRestaurantId(+restaurantIdParams) :
            setRestaurantId(+restaurantIdFromReservation)
    }, [onReservation, restaurantIdParams, restaurantIdFromReservation, restaurantId])


    useEffect(() => {
        if (!isEmpty(restaurants) && isEmpty(restaurant) && restaurantId) {
            dispatch(setRestaurantById({ restaurants, restaurantId }))
        }
    }, [dispatch, restaurants, restaurant, restaurantId])

    useEffect(() => {
        if (restaurantId) {
            dispatch(getRestaurantMenuApproved(+restaurantId, +reservationId, onReservation))
            return () => {
                dispatch(setRestaurantMenuApproved([]))
            }
        }
    }, [dispatch, onReservation, restaurantId, reservationId])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (
                activeMenuId &&
                activeMenuName &&
                activeMenuSectionId &&
                activeMenuSectionName &&
                !hasInitialMenuOpened &&
                !isEmpty(user?.first_name)
            ) {
                setIsMenuOpened(true);
                setHasInitialMenuOpened(true);

                if (isMobile) {
                    dispatch(setMenuDrawerOpened(true));
                }
            }
        }, 100);

        // Cleanup function to clear the timeout if the component is unmounted
        return () => {
            clearTimeout(timeoutId);
        };
    }, [dispatch, activeMenuId, activeMenuName, activeMenuSectionId, activeMenuSectionName, hasInitialMenuOpened, user, isMobile]);

    // #endregion

    return (
        <Row className="full-menu-row reservations-row" style={{ position: "relative" }}>

            <Column md='12' lg='8'>
                <Row>
                    <Column md="4" lg="4" style={{padding:"0"}}>
                        {/* {!!activeMenuName && !!activeMenuSectionName ?
                <div style={{ padding: "0", textAlign: "center" }}>
                     <div style={{  display:"flex", justifyContent:"center", margin:"0.5rem 0 1rem" }}>
                    <h4>
                        {activeMenuName + " / " + activeMenuSectionName}
                    </h4>
                </div>
                </div> :
                null
            } */}
                        {
                            <MenuListApproved
                                restaurantMenuApproved={!!isMenuStepsFeatureEnabled ? selectedMenuAndSectionsByStep : restaurantMenuApproved}
                                reservationId={reservationId}
                                restaurantLogo={restaurant?.logo ?? restaurant?.image}
                                isMobile={isMobile}
                                isMenuOpened={isMenuOpened}
                                menuDrawerOpened={menuDrawerOpened}
                                menuOrdersDrawerOpened={menuOrdersDrawerOpened}
                                drawerRef={drawerRef}
                                menuSteps={menu_steps}
                                menuStepsUser={menu_steps_user}
                                isMenuStepsFeatureEnabled={isMenuStepsFeatureEnabled}
                                menuItemsQuantity={menuItemsQuantity}
                                sectionItemsQuantity={sectionItemsQuantity}
                                selectedMenuAndSectionsByStep={selectedMenuAndSectionsByStep}
                                activeMenuId={activeMenuId}
                                activeMenuName={activeMenuName}
                                activeMenuSectionId={activeMenuSectionId}
                                activeMenuSectionName={activeMenuSectionName}
                                onReservation={onReservation}
                                onBooking={onBooking}
                                handleChangeMenu={handleChangeMenu}
                                handleChangeMenuSection={handleChangeMenuSection}
                                handleIsMenuDrawerOpened={handleIsMenuDrawerOpened}
                                handleIsMenuOrdersDrawerOpened={handleIsMenuOrdersDrawerOpened}
                                handleIsMenuStepsFeatureEnabled={handleIsMenuStepsFeatureEnabled}
                                handleBackToBooking={handleBackToBooking}
                                menuItemDetails={menuItemDetails}
                                menuItemRecommendations={menuItemRecommendations}
                                onMenuItemDetailsModal={onMenuItemDetailsModal}
                                onAddRecommendedMenuItemsModal={onAddRecommendedMenuItemsModal}
                                onAddEditDeleteItemModal={onAddEditDeleteItemModal}
                                onFullPageModal={onFullPageModal}
                                history={history}
                            />
                        }
                    </Column>

                    <Column
                        className="scrollable-list m-0 p-0"
                        md="8"
                        lg="8">
                        <ActiveSectionItems
                            items={activeMenuSectionItemsApproved}
                            emptyMofifierGroup={empty_modifier_group}
                            onMenuItemRecommendationsModal={onMenuItemRecommendationsModal}
                            orderQuantities={orderQuantities}
                            onReservation={onReservation}
                            showSpecial
                            lastRoundOrders={lastRoundOrders}
                            handleMenuItemDetails={handleMenuItemDetails}
                        />
                    </Column>

                    {
                        menuItemDetails && !onReservation && onMenuItemDetailsModal &&
                        <MenuItemDetailsModal
                            show={!isEmpty(menuItemDetails)}
                            close={() => {
                                setMenuItemDetails({})
                                setOnMenuItemDetailsModal(false)
                            }}
                            item={menuItemDetails}
                            activeMenuSectionId={activeMenuSectionId}
                        />
                    }
                    {
                        menuItemDetails && onAddEditDeleteItemModal && !onAddRecommendedMenuItemsModal ?
                            <>
                                <AddMenuItemModalNew
                                    show={!isEmpty(menuItemDetails)}
                                    item={
                                        !isEmpty(recommendedItemDetails) ?
                                            recommendedItemDetails :
                                            menuItemDetails
                                    }
                                    menuItemRecommendations={menuItemRecommendations}
                                    recommendedItemDetails={recommendedItemDetails}
                                    handleCloseRecommendedMenuItemModal={handleCloseRecommendedMenuItemModal}
                                    activeMenuSectionId={activeMenuSectionId}
                                    close={!isEmpty(recommendedItemDetails) ?
                                        handleOnAddRecommendedItemModal :
                                        handleOnCloseMenuItemModal}
                                    onAddClickHandler={onAddClickHandler}
                                />
                            </> :
                            null
                    }

                    {
                        menuItemDetails && menuItemRecommendations && !onAddEditDeleteItemModal && onAddRecommendedMenuItemsModal ?
                            <RecommendedMenuItemsModal
                                show={!isEmpty(menuItemRecommendations)}
                                items={menuItemRecommendations}
                                menuItemDetails={menuItemDetails}
                                lastRoundOrders={lastRoundOrders}
                                onMenuItemRecommendationsModal={onMenuItemRecommendationsModal}
                                close={handleCloseMenuItemRecommendationModal}
                                handleMenuItemDetails={handleMenuItemDetails}
                                handleRecommendedItemDetails={handleRecommendedItemDetails}

                            /> :
                            null
                    }
                </Row>
            </Column>
        </Row>
    )
}

export default RestaurantMenuApproved