import { useCallback, useEffect, useMemo, useState } from 'react'
import io from 'socket.io-client'
import {
  JOIN, ORDER_CLOSED,
  ORDER_MODIFIED,
  PAY_FOR_OTHERS,
  PAYMENT_MADE,
  SELECTED_FRIENDS,
  REFRESH_EXPIRATION_TOKEN,
  PAYMENT_EXPIRIED,
  ORDER_EXPIRED,
  ORDER_MODIFIED_WITHOUT_RESETING_IS_PAY,
  MENU_ITEM_QUANTITY_CHANGED,
  GUESTS_HAVE_SELECTES_MORE_QUANTITY_THAN_ITEM_HAS,
  MENU_ITEM_IS_OUT_OF_STOCK,
  START_BOOKED_ORDER,
  USER_INFO_CHANGED,
  GUESTS_PRESENCE_CHANGED
} from '../config/socketEvents'
import { BASE_URL } from '../config/keys'
import useSocketEventHandlers from './useSocketEventHandlers'

const ENDPOINT = BASE_URL || 'http://localhost:5005'

function useSocket({
  reservationId,
  shouldConnect,
 ...handlers
}) {
  const [socketInstance, setSocket] = useState(null)
  const [connectSocket, setConnectSocket] = useState(true)

  const eventHandlers = useSocketEventHandlers({ reservationId, ...handlers });

  const { handleOrderModified, handleOrderModifiedWithoutReset, handlePaymentMade, handlePaymentExpired, handlePayForOthers, handleOrderClosed, handleRefreshExpirationToken, handleOrderExpired, handleMenuItemQuantityChanged, handleGuestsHaveSelectedMoreQuantity, handleMenuItemOutOfStock, handleStartBookedOrder, handleUserInfoChanged, handleGuestsPresenceChanged } = eventHandlers;

  const events = useMemo(() => [
    { event: ORDER_MODIFIED, handler: handleOrderModified },
    { event: ORDER_MODIFIED_WITHOUT_RESETING_IS_PAY, handler: handleOrderModifiedWithoutReset },
    { event: PAYMENT_MADE, handler: handlePaymentMade },
    { event: PAYMENT_EXPIRIED, handler: handlePaymentExpired },
    { event: PAY_FOR_OTHERS, handler: handlePayForOthers },
    { event: ORDER_CLOSED, handler: handleOrderClosed },
    { event: REFRESH_EXPIRATION_TOKEN, handler: handleRefreshExpirationToken },
    { event: ORDER_EXPIRED, handler: handleOrderExpired },
    { event: MENU_ITEM_QUANTITY_CHANGED, handler: handleMenuItemQuantityChanged },
    { event: GUESTS_HAVE_SELECTES_MORE_QUANTITY_THAN_ITEM_HAS, handler: handleGuestsHaveSelectedMoreQuantity },
    { event: MENU_ITEM_IS_OUT_OF_STOCK, handler: handleMenuItemOutOfStock },
    { event: START_BOOKED_ORDER, handler: handleStartBookedOrder },
    { event: USER_INFO_CHANGED, handler: handleUserInfoChanged },
    { event: GUESTS_PRESENCE_CHANGED, handler: handleGuestsPresenceChanged },
  ], [handleGuestsHaveSelectedMoreQuantity, handleMenuItemOutOfStock, handleMenuItemQuantityChanged, handleOrderClosed, handleOrderExpired, handleOrderModified, handleOrderModifiedWithoutReset, handlePayForOthers, handlePaymentExpired, handlePaymentMade, handleRefreshExpirationToken, handleStartBookedOrder, handleUserInfoChanged, handleGuestsPresenceChanged]);

  useEffect(() => {
    const socket = io(ENDPOINT)
    setSocket(socket)

    // Cleanup function to disconnect socket when the component unmounts
    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    setConnectSocket(false)

    let timeoutId

    if (shouldConnect) {
      timeoutId = setTimeout(() => {
        setConnectSocket(true)
      }, 2000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [shouldConnect])

  useEffect(() => {
    if (reservationId && socketInstance && (connectSocket || shouldConnect)) {
      if (!socketInstance.connected) socketInstance.connect()
      socketInstance.emit(JOIN, { reservationId })
    } else if ((!connectSocket || !shouldConnect) && socketInstance) socketInstance.disconnect()
  }, [socketInstance, reservationId, connectSocket, shouldConnect])

  useEffect(() => {
    if (socketInstance) {
      events.forEach(({ event, handler }) => {
        socketInstance.on(event, handler);
      });
    }

    // Clean up socket event listeners when the component unmounts
    return () => {
      if (socketInstance) {
        events.forEach(({ event, handler }) => {
          socketInstance.off(event, handler);
        });
      }
    }
  }, [socketInstance, reservationId, handleOrderModified, handleOrderModifiedWithoutReset, handlePaymentMade, handlePaymentExpired, handlePayForOthers, handleOrderClosed, handleRefreshExpirationToken, handleOrderExpired, handleMenuItemQuantityChanged, handleGuestsHaveSelectedMoreQuantity, handleMenuItemOutOfStock, handleStartBookedOrder, handleUserInfoChanged, handleGuestsPresenceChanged, events])

  const emitFriendSelected = useCallback(
    (reservationId, userIds, payerId) => {
      if (socketInstance) {
        socketInstance.emit(SELECTED_FRIENDS, {
          reservationId,
          userIds,
          payerId
        })
      }
    },
    [socketInstance]
  )

  return { emitFriendSelected }
}

export default useSocket