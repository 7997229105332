import React from 'react'
import Modal from '../modal/Modal'
import CustomButton from './CustomButton'
import '../orders/SentToKitchenModal.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import reconnectSocket from '../../handlers/reconnectSocket'

function IsIdleModal({ show, close }) {
  const {
    booking: { currentReservation },
    auth: {
      user: { id }
    },
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClose = () => {
    reconnectSocket(currentReservation, id, history, dispatch)

    close()
  }

  return (
    <Modal isPopUp className="sent-to-kitchen-modal chef-is-doing-the-magic-modal" show={show} close={handleClose}>
      <div>
        <h3>Are you still here?</h3>
        <p className='message-margin-bottom'>You have been idle for some time</p>
        <CustomButton
          onClick={handleClose}
          text={'Yes'}
          wrapperClassName="sent-to-kitchen-button"
          textClassName="cancel-reservation-btn-text"
        />
      </div>
    </Modal>
  )
}

export default IsIdleModal
