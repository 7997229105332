import React, {useEffect, useState} from 'react'
import GooglePayButton from "@google-pay/button-react";
import {useSelector} from "react-redux";
import useFloatingIslandActions from '../../../../hooks/useFloatingIslandActions';

// TODO: Replace hardcoded data
export function GooglePay({ onLoadPaymentData }) {
    const {
        orders: {
            checkoutInfo: { total }
        }
    } = useSelector(state => state)
    const [totalPrice, setTotalPrice] = useState(null)

    useEffect(() => {
        setTotalPrice(total.replace(/\D/, ''))
    }, [total])

    const paymentRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
            {
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
                    billingAddressRequired: true
                },
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: 'spreedly',
                        gatewayMerchantId: 'QqhRds4bCwhLPtCzbEpWzY4FclD',
                    },
                },
            },
        ],
        merchantInfo: {
            merchantId: 'BCR2DN4TVDB45HTB',
            merchantName: 'Voila Dining',
        },
        transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: totalPrice?.toString(),
            currencyCode: 'USD',
            countryCode: 'US',
        },
    }

    const { createElementPortal, DOMslot1, } = useFloatingIslandActions()

    return totalPrice && (
        <>
            {createElementPortal(
                <GooglePayButton
                    environment="PRODUCTION"
                    buttonType="pay"
                    buttonColor="white"
                    style={{fontFamily:"Lato", fontSize:"1rem"}}
                    paymentRequest={paymentRequest}
                    onLoadPaymentData={paymentRequest => {
                        const { paymentMethodData = {} } = paymentRequest
                        const { tokenizationData = {}, info = {} } = paymentMethodData || {}

                        onLoadPaymentData({ token: tokenizationData.token, cardType: info.cardNetwork, last4Digits: info.cardDetails, zipCode: info.billingAddress?.postalCode })
                    }}
                />, DOMslot1
            )}
        </>
    )
}