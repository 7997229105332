import React, { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { createPaymentMethodForUser } from "../../../pages/order/services/actions";
import SpreedlyLogic from '../../payment/spreedly/components/SpreedlyLogic';
import './SpreedlyCreditCardVault.css';
import useFloatingIslandActions from '../../../hooks/useFloatingIslandActions'

const SpreedlyCreditCardVault = () => {
  const dispatch = useDispatch()

  const onCompleteAction = useCallback((data) => {
    dispatch(createPaymentMethodForUser(data))
  },[dispatch])

  const {
    showCreditCardVault,
    isAddNewMethod,
    isNewCardFormValid,
    onSaveCard,
  } = SpreedlyLogic({ cb: onCompleteAction, isEditMode: true })

  const {
    DOMslot1, DOMslot2, createButtonPortal,
    save, backToOrderShortcut,
  } = useFloatingIslandActions()

  return (
    <>
      {showCreditCardVault()}
      {isAddNewMethod && (
        <div className="submit-button">
          <div className={"submit-btn-primary"}>
            {/* <button onClick={onSaveCard} className="btn btn-primary" disabled={!isNewCardFormValid()}>
              <span>Save card</span>
            </button> */}

            { createButtonPortal(save(onSaveCard, !isNewCardFormValid(), "new card"), DOMslot1) }
            { backToOrderShortcut(DOMslot2) }
          </div>
        </div>
      )}
    </>
  )
}

export default SpreedlyCreditCardVault
