import NavigationSideMenu from '../../components/navigation/NavigationSideMenu'
import ProfileDetails from '../../components/profile/ProfileDetails'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'

export default function ProfilePage() {
  return (
    <Row className="m-4 reservations-row">
    <Column md={10} lg={8}>
      <Row>
        <Column md="6" lg="6">
          <NavigationSideMenu />
        </Column>
        <Column className="scrollable-list" md="6" lg="6">
          <ProfileDetails />
        </Column>
      </Row>
    </Column>
  </Row>
  )
}