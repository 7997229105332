// import isEmpty from 'lodash.isempty'
// import React, { useCallback, useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { useHistory } from 'react-router'
// import { order_page_path, reservations_path } from '../../config/pages_paths'
// import hexColors from '../../constants/hexColors'
// import {
//   completeOrder,
//   getCheckoutInfo,
//   getLastRoundOrders,
//   getOrdersNoRounds,
//   getOrdersRounds,
//   sendToKitchen,
//   setOrderNextRoute
// } from '../../pages/order/services/actions'
// import {
//   getCurrentReservation, setInitReservationType,
//   setOtherTryingToPay,
//   setRefreshCheckout
// } from '../../pages/reservations/services/actions'
// import { setConnectSocketAfterIdleTimeout } from '../../store/actions/feedbackActions'
// import { setIsPay, setUserUpdated } from "../../store/actions/usersActions"
// import { genHexIndex } from '../../utils/genHexIndex'
// import '../reservation/Reservation.css'
// import './OrdersRounds.css'
// import { ARCHIVED_USER_COLOR } from './components/Round'
// import RoundCompletion from './components/RoundCompletion'
// import SendToKitchenModal from './components/SendToKitchenModal'
// import UserOrders from './components/UserOrders'
// import './components/styles/OrdersCheckout.css'
// import './components/styles/Round.css'
// import './components/styles/UserOrderItem.css'
// import './components/styles/UserOrders.css'
// import { allFriendsHaveOrdered } from './helpers/ordersStatus'

// export const DeliveryOptions = {
//   separate: 1,
//   together: 2
// }

// function OrdersRounds({ reservationId, canOrder = true, emitFriendSelected }) {
//   const {
//     auth: {
//       user: { id, is_anonymous }
//     },
//     user: { updateUserReceipt, isPay, isProceededToCheckoutSelection, user, isUpdated },
//     navigation: { processStarted, processStepLabel },
//     orders: { ordersRounds, lastRoundOrders, checkoutInfo, ordersNoRounds },
//     booking: { currentReservation, refreshCheckout, otherTryingToPay, reservationDetails },
//     restaurant: { isPaymentRequired, restaurant },
//     feedback: { connectSocketAfterIdleTimeout },
//     versionConfig: { show_table_number },
//   } = useSelector(state => state)

//   const dispatch = useDispatch()
//   const history = useHistory()

//   const [payForUsers, setPayForUsers] = useState([])
//   const [hasNewOrder, dispatch(setHasNewOrder] = use)State(false)
//   const [preparationTime, setPreparationTime] = useState([])
//   const [sendToKitchenModal, setSendToKitchenModal] = useState(false)
//   const [deliveryOption, setDeliveryOption] = useState(DeliveryOptions.separate)
//   const [orderUserIds, setOrderUserIds] = useState([])
//   const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(false)
//   const [isProceedClicked, setIsProceedClicked] = useState(true)
//   const [showReceiptButton, setShowReceiptButton] = useState(false)
//   const [avatarColors, setAvatarColors] = useState([])
//   const [waitingForAllDataToCome, setWaitingForAllDataToCome] = useState(true)
//   const [countRequest, setCountRequest] = useState({
//     getCheckoutInfo: 0
//   })
//   const [isActiveReservation, setIsActiveReservation] = useState(true)

//   const [isAccordionOpened, setIsAccordionOpened] = useState(false)
//   const [ordersData, setOrdersData] = useState([])

//   if (waitingForAllDataToCome === true) {
//     if (typeof checkoutInfo.totalTip !== "number") {
//       setWaitingForAllDataToCome(false)
//     }
//   }

//   // useEffect(() => {
//   //   ordersNoRounds.map((zapis, idx) => {
//   //     if (zapis.user_id === user.user_id) {
//   //       setIsAccordionOpened(true)
//   //     }
//   //   })
//   // }, [ordersNoRounds, isAccordionOpened])

//   useEffect(() => {
//     setIsCheckBoxClicked(true)
//   }, [])

//   useEffect(() => {
//     reservationDetails.reservation?.is_closed && setIsActiveReservation(false)
//   }, [reservationDetails, isActiveReservation])

//   useEffect(() => {
//     if (reservationId) {
//       dispatch(getOrdersRounds(reservationId))
//       dispatch(getOrdersNoRounds(reservationId))
//     }
//   }, [dispatch, reservationId])

//   useEffect(() => {
//     if (reservationId && !isEmpty(ordersNoRounds)) {
//       setOrdersData(ordersNoRounds)
//     }
//     let colors = []
//     if (Object.values(ordersData).length !== avatarColors.length) {
//       Object.values(ordersData).forEach(() => {
//         let hexIndex = genHexIndex(colors);
//         colors.push(hexColors[hexIndex])
//       })
//       setAvatarColors(colors)
//     }

//     if (ordersData) {
//       ordersData.map((record, idx) => {
//         if (record.user_id === user.user_id) {
//           setIsAccordionOpened(true)
//         }
//       })
//     }

//     return () => setOrdersData([])

//   }, [avatarColors, ordersNoRounds, isAccordionOpened, ordersData, user])

//   const onNextClick = () => {
//     dispatch(setInitReservationType(1))
//     dispatch(
//       setOrderNextRoute({
//         path: reservations_path,
//         tabIndex: 1
//       })
//     )
//     history.push(order_page_path.replace(':reservationId', reservationId))
//   }

//   const onCompleteOrderClick = useCallback(async ({ instance, spreedlyInformation }) => {
//     let nonce
//     if (instance) {
//       ({ nonce } = await instance.requestPaymentMethod())
//     }
//     if (!payForUsers.includes(id)) {
//       payForUsers.push(id)
//     }
//     dispatch(
//       completeOrder({
//         reservationId,
//         users: payForUsers,
//         paymentMethodNonce: nonce,
//         spreedlyInformation,
//         is_anonymous
//       }, history)
//     ).then(() => {
//       onPaymentCheckboxChange({ userId: id, isProceed: true, isEdit: true })
//     })
//     dispatch(setIsPay(false))
//   }, [reservationId, payForUsers, dispatch, is_anonymous, history, id])

//   const updateCheckoutInfo = useCallback(() => {
//     if (isProceedClicked) {
//       setCountRequest({ ...countRequest, getCheckoutInfo: 0 })
//       if (countRequest.getCheckoutInfo < 2) {
//         if (isPay) {
//           dispatch(getCheckoutInfo(reservationId, payForUsers.length ? payForUsers : [id]))
//         } else {
//           dispatch(getCheckoutInfo(reservationId, [id]))
//         }
//         setCountRequest(prevState => ({ ...prevState, getCheckoutInfo: countRequest.getCheckoutInfo + 1 }))
//       }
//     }
//   }, [dispatch, reservationId, payForUsers, isProceedClicked])

//   useEffect(() => {
//     if (payForUsers && isCheckBoxClicked) {
//       if (isPay) {
//         emitFriendSelected(reservationId, payForUsers, id)
//       } else {
//         emitFriendSelected(reservationId, [], id)
//       }
//       setIsCheckBoxClicked(false)
//     } else if (connectSocketAfterIdleTimeout) {
//       emitFriendSelected(reservationId, [], id)
//       dispatch(setConnectSocketAfterIdleTimeout(false))
//     } else {
//       if (!isPay) {
//         emitFriendSelected(reservationId, [], id)
//       }
//     }
//   }, [emitFriendSelected, reservationId, payForUsers, id, isCheckBoxClicked, isPay, connectSocketAfterIdleTimeout])

//   useEffect(() => {
//     if (refreshCheckout) {
//       updateCheckoutInfo()
//       dispatch(setRefreshCheckout(false))
//     }
//   }, [refreshCheckout, updateCheckoutInfo, dispatch])

//   useEffect(() => {
//     setPayForUsers(arr => {
//       let val = [...arr]
//       const currentIdIndex = val.findIndex(el => el === id)
//       if (currentIdIndex < 0) {
//         if (!otherTryingToPay) val.push(id)
//       }
//       if (otherTryingToPay) {
//         val.splice(currentIdIndex, 1)
//       }
//       return val
//     })
//   }, [id, otherTryingToPay])

//   useEffect(() => {
//     updateCheckoutInfo()
//   }, [updateCheckoutInfo])

//   useEffect(() => {
//     let currentRound = ordersRounds.find(round => !round.round)
//     if (currentRound) {
//       setHasNewOrder(true)
//     } else setHasNewOrder(false)
//   }, [payForUsers, ordersRounds, id])

//   useEffect(() => {
//     let currentRound = ordersRounds.find(round => !round.round)
//     if (currentRound) {
//       let currentUser = currentRound.users.find(user => +user.user_id === +id)
//       if (currentUser) {
//         dispatch(setOtherTryingToPay(false))
//       }
//     }
//   }, [ordersRounds, id, dispatch])

//   useEffect(() => {
//     return () => {
//       setHasNewOrder(false)
//     }
//   }, [])

//   useEffect(() => {
//     if (!isEmpty(checkoutInfo.sections)) {
//       setPreparationTime(
//         checkoutInfo.sections.map(section => ({
//           section: section.section,
//           preparationTime: section.initialDelay
//         }))
//       )
//     }
//   }, [checkoutInfo.sections])

//   const onPaymentCheckboxChange = ({ userId, isProceed, isEdit }) => {
//     setIsProceedClicked(isProceed)
//     if (isEdit) {
//       setPayForUsers([])
//       setIsCheckBoxClicked(true)
//       return
//     }
//     if (isProceed) {
//       setIsCheckBoxClicked(true)
//     } else {
//       setPayForUsers(arr => {
//         let val = [...arr]
//         let index = val.findIndex(el => +el === +userId)
//         if (index < 0) val.push(+userId)
//         else val.splice(index, 1)
//         return val
//       })
//     }
//   }

//   const onSendToKitchen = () => {
//     dispatch(
//       sendToKitchen(
//         { reservationId },
//         payForUsers,
//         preparationTime,
//         deliveryOption
//       )
//     )
//     setSendToKitchenModal(false)
//   }

//   const onRefreshVoidedOrders = (tip) => {
//     dispatch(getCheckoutInfo(reservationId, payForUsers, tip))
//   }

//   const onSendToKitchenClickHandler = () => {
//     allFriendsHaveOrdered(ordersRounds, Number(id))
//       ? onSendToKitchen()
//       : setSendToKitchenModal(true)
//   }

//   const onSetPreparationTime = ({ section, preparationTime }) => {
//     setPreparationTime(sections => {
//       const sectionIndex = sections.findIndex(val => val.section === section)
//       if (sectionIndex > -1) {
//         sections[sectionIndex].preparationTime = preparationTime
//       }
//       return sections.slice()
//     })
//   }

//   const showOrderMoreCondition = () => {
//     return (
//       !isEmpty(ordersRounds) &&
//       !hasNewOrder &&
//       !isPaymentRequired &&
//       checkoutInfo.subTotalInt > 0
//     )
//   }

//   useEffect(() => {
//     if (ordersRounds.length > 0) {
//       const userIds = []

//       ordersRounds.forEach(round => {
//         round.users.forEach(user => {
//           const isUserToAdd = user.orders.length === 0 || user.orders.some(order => !order.paid)

//           if (isUserToAdd) {
//             user = {
//               userId: user.user_id,
//               userName: user.user,
//               orders: user.orders
//             }
//           }

//           if (!userIds.some(userId => userId.userId === user.userId) && isUserToAdd)
//             if (user.orders.length !== 0) userIds.push(user)
//         })
//       })
//       setOrderUserIds(userIds.filter(user => user.userId !== id))
//     }

//     if (!currentReservation) dispatch(getCurrentReservation())
//     else if (!lastRoundOrders) dispatch(getLastRoundOrders(currentReservation.reservationId))
//   }, [ordersRounds, id, dispatch, currentReservation])

//   useEffect(() => {
//     // check if any user's order is paid by himself
//     // if there is none, hide the receipt button
//     // all orders have been paid by other users
//     if (currentReservation?.has_receipt) {
//       setShowReceiptButton(true)
//     }
//   }, [ordersRounds, id, showReceiptButton])

//   // useEffect(() => {
//   //   if (reservationId && (!receiptURL || updateUserReceipt)) {
//   //     dispatch(getReceipt(reservationId, setReceiptURL))
//   //     dispatch(setUpdateUserReceipt(false))
//   //   }

//   // }, [dispatch, reservationId, updateUserReceipt, receiptURL])

//   return (
//     <>
//       {!waitingForAllDataToCome && (
//         <>
//           {sendToKitchenModal ? (
//             <SendToKitchenModal
//               show={sendToKitchenModal}
//               close={() => setSendToKitchenModal(false)}
//               onSend={onSendToKitchen}
//             />
//           ) : null}



//           <div className="orders-rounds-wrapper">
//             {show_table_number !== null && show_table_number ?
//               <h4 style={{ textAlign: "center", fontFamily: "Cormorant", marginBottom: "1rem" }}>Orders on table {currentReservation?.table_number}</h4> :
//               null
//             }
//             {/* <h4 style={{ textAlign: 'center' }}>Orders on table {currentReservation?.table_number}</h4> */}
//             <div className='checkout-button' style={{ display: "flex", justifyContent: "center" }}>
//               <button onClick={() => history.push(order_page_path.replace(':reservationId', currentReservation?.reservationId))} style={{ margin: "0.5rem 0 1rem" }} className="btn btn-primary">
//                 Add items from Menu
//               </button>
//             </div>
//             {/* NO ROUNDS! */}

//             {
//               ordersData?.map((user, idx) => {
//                 return (
//                   <UserOrders
//                     key={user.user_id ?? idx}
//                     orderPosition={idx}
//                     is_archived={user.is_archived}
//                     canPay={hasNewOrder}
//                     canOrder={canOrder}
//                     onPaymentCheckboxChange={() =>
//                       onPaymentCheckboxChange({ userId: user.user_id })
//                     }
//                     isPaymentChecked={!!payForUsers.find(userId => +userId === +user.user_id)}
//                     areOwnOrders={id === +user.user_id}
//                     avatarBackground={user.is_archived ? ARCHIVED_USER_COLOR : avatarColors[idx]}
//                     avatarColors={avatarColors}
//                     userDisplayName={id === +user.user_id ? user.user : user.user}
//                     userStatus={user.user_order_status && user.user_order_status}
//                     firstLetter={
//                       user.is_archived
//                         ? '?'
//                         : user.user.charAt(0).toUpperCase()
//                       // user.is_anonymous
//                       //   ? `${user.user.charAt(0).toUpperCase()}${user.user.charAt(user.user.length - 1).toUpperCase()}`
//                       //   : user.user.charAt(0).toUpperCase()
//                     }
//                     reservationId={reservationId}
//                     {...user}
//                     user={user}
//                     isActiveReservation={isActiveReservation}
//                     currentUserId={user.user_id}
//                     isAccordionOpened={isAccordionOpened}
//                     hasTableId={true}
//                   />
//                 )
//               })
//             }
//             <RoundCompletion
//               checkoutInfo={checkoutInfo}
//               setPreparationTime={onSetPreparationTime}
//               onCompleteOrderClick={onCompleteOrderClick}
//               onSendToKitchen={onSendToKitchenClickHandler}
//               setDeliveryOption={setDeliveryOption}
//               otherTryingToPay={otherTryingToPay}
//               payForUsers={payForUsers}
//               hasNewOrder={hasNewOrder}
//               onPaymentCheckboxChange={onPaymentCheckboxChange}
//               orderUserIds={orderUserIds}
//               onRefreshVoidedOrders={onRefreshVoidedOrders}
//               showOrderMoreCondition={showOrderMoreCondition()}
//             />
//           </div>
//         </>
//       )}
//     </>
//   )
// }

// export default OrdersRounds

import { debounce } from 'lodash'
import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import hexColors from '../../constants/hexColors'
import queryParamsEnum from '../../enums/queryParamsEnum'
import {
  completeOrder,
  getCheckoutInfo,
  getLastRoundOrders,
  getOrdersNoRounds,
  getOrdersRounds,
  sendToKitchen,
  setHasNewOrder,
} from '../../pages/order/services/actions'
import {
  getCurrentReservation,
  setOtherTryingToPay,
  setRefreshCheckout
} from '../../pages/reservations/services/actions'
import { setConnectSocketAfterIdleTimeout } from '../../store/actions/feedbackActions'
import { setIsPay, setIsProceededToCheckoutSelection } from "../../store/actions/usersActions"
import { genHexIndex } from '../../utils/genHexIndex'
import getQueryParam from '../../utils/getQueryParam'
import localStorageHelper from '../../utils/localStorageHelper'
import '../reservation/Reservation.css'
import './OrdersRounds.css'
import { ARCHIVED_USER_COLOR } from './components/Round'
import RoundCompletion from './components/RoundCompletion'
import SendToKitchenModal from './components/SendToKitchenModal'
import UserOrders from './components/UserOrders'
import './components/styles/OrdersCheckout.css'
import './components/styles/Round.css'
import './components/styles/UserOrderItem.css'
import './components/styles/UserOrders.css'
import { allFriendsHaveOrdered } from './helpers/ordersStatus'
import { getReservationDetails } from '../../pages/book/services/actions'

function OrdersRounds({ reservationId, canOrder = true, emitFriendSelected }) {
  const {
    auth: {
      user: { id, is_anonymous }
    },
    user: { isPay, user }, // updateUserReceipt
    orders: { ordersRounds, lastRoundOrders, checkoutInfo, ordersNoRounds, hasNewOrder },
    booking: { currentReservation, refreshCheckout, otherTryingToPay, reservationDetails },
    feedback: { connectSocketAfterIdleTimeout },
    versionConfig: { show_table_number },
  } = useSelector(state => state)

  const dispatch = useDispatch()
  const history = useHistory()

  const [payForUsers, setPayForUsers] = useState([])
  const [sendToKitchenModal, setSendToKitchenModal] = useState(false)
  const [orderUserIds, setOrderUserIds] = useState([])
  const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(false)
  const [isProceedClicked, setIsProceedClicked] = useState(true)
  const [showReceiptButton, setShowReceiptButton] = useState(false)
  const [avatarColors, setAvatarColors] = useState([])
  const [waitingForAllDataToCome, setWaitingForAllDataToCome] = useState(true)
  const [isActiveReservation, setIsActiveReservation] = useState(true)

  const [isAccordionOpened, setIsAccordionOpened] = useState(false)
  const [ordersData, setOrdersData] = useState([])
  // const menuStepQueryParam = getQueryParam(queryParamsEnum.step, localStorageHelper.getItem(queryParamsEnum.step))

  if (waitingForAllDataToCome === true) {
    if (typeof checkoutInfo.totalTip !== "number") {
      setWaitingForAllDataToCome(false)
    }
  }

  // useEffect(() => {
  //   ordersNoRounds.map((zapis, idx) => {
  //     if (zapis.user_id === user.user_id) {
  //       setIsAccordionOpened(true)
  //     }
  //   })
  // }, [ordersNoRounds, isAccordionOpened])

  useEffect(() => {
    setIsCheckBoxClicked(true)
  }, [])

  useEffect(() => {
    reservationDetails.reservation?.is_closed && setIsActiveReservation(false)
  }, [reservationDetails, isActiveReservation])

  useEffect(() => {
    if (reservationId) {
      dispatch(getOrdersRounds(reservationId))
      dispatch(getOrdersNoRounds(reservationId))
    }
  }, [dispatch, reservationId])

  useEffect(() => {
    if (reservationId && !isEmpty(ordersNoRounds)) {
      setOrdersData(ordersNoRounds)
    }
    let colors = []
    if (Object.values(ordersData).length !== avatarColors.length) {
      Object.values(ordersData).forEach(() => {
        let hexIndex = genHexIndex(colors);
        colors.push(hexColors[hexIndex])
      })
      setAvatarColors(colors)
    }

    if (ordersData) {
      ordersData.map((record, idx) => {
        if (record.user_id === user.user_id) {
          setIsAccordionOpened(true)
        }
      })
    }

    // return () => setOrdersData([])

  }, [avatarColors, ordersNoRounds, isAccordionOpened, ordersData, user, reservationId])


  const onCompleteOrderClick = useCallback(async ({ instance, spreedlyInformation }) => {
    let nonce
    if (instance) {
      ({ nonce } = await instance.requestPaymentMethod())
    }
    if (!payForUsers.includes(id)) {
      payForUsers.push(id)
    }

    dispatch(
      completeOrder({
        reservationId,
        users: payForUsers,
        paymentMethodNonce: nonce,
        spreedlyInformation,
        is_anonymous
      }, history)
    ).then(() => {
      onPaymentCheckboxChange({ userId: id, isProceed: true, isEdit: true })
      dispatch(setIsPay(false))
      // dispatch(setIsProceededToCheckoutSelection(false))
    })

  }, [reservationId, payForUsers, dispatch, is_anonymous, history, id])


  const updateCheckoutInfo = useCallback(

    debounce(() => {
      if (isProceedClicked) {
        const usersToPayFor = isPay ? (payForUsers.length ? payForUsers : [id]) : [id];
        dispatch(getCheckoutInfo(reservationId, usersToPayFor));
        dispatch(setRefreshCheckout(false))
      }
    }, 1000),

    [dispatch, reservationId, id, isProceedClicked]
  );

  useEffect(() => {
    if (payForUsers && isCheckBoxClicked) {
      if (isPay) {
        emitFriendSelected(reservationId, payForUsers, id)
      } else {
        emitFriendSelected(reservationId, [], id)
      }
      setIsCheckBoxClicked(false)
    } else if (connectSocketAfterIdleTimeout) {
      emitFriendSelected(reservationId, [], id)
      dispatch(setConnectSocketAfterIdleTimeout(false))
    } else {
      if (!isPay) {
        emitFriendSelected(reservationId, [], id)
      }
    }
  }, [dispatch, emitFriendSelected, reservationId, payForUsers, id, isCheckBoxClicked, isPay, connectSocketAfterIdleTimeout])

  useEffect(() => {
    if (refreshCheckout) {
      updateCheckoutInfo()
    }
  }, [refreshCheckout, updateCheckoutInfo, dispatch])

  useEffect(() => {
    setPayForUsers(arr => {
      let val = [...arr]
      const currentIdIndex = val.findIndex(el => el === id)
      if (currentIdIndex < 0) {
        if (!otherTryingToPay) val.push(id)
      }
      if (otherTryingToPay) {
        val.splice(currentIdIndex, 1)
      }
      return val
    })
  }, [id, otherTryingToPay])

  useEffect(() => {
    updateCheckoutInfo()
  }, [])

  useEffect(() => {
    let currentRound = ordersRounds.find(round => !round.round)
    if (currentRound !== undefined) {
      currentRound.users = currentRound.users.filter(user => user.is_present && user.orders.length)
      if (currentRound.users.length) {
        dispatch(setHasNewOrder(true))
      } else {
        dispatch(setHasNewOrder(false))
      }
    } else {
      dispatch(setHasNewOrder(false))
    }
  }, [dispatch, payForUsers, ordersRounds, id])

  useEffect(() => {
    let currentRound = ordersRounds.find(round => !round.round)
    if (currentRound) {
      let currentUser = currentRound.users.find(user => +user.user_id === +id)
      if (currentUser) {
        dispatch(setOtherTryingToPay(false))
      }
    }
  }, [ordersRounds, id, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setHasNewOrder(false))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getReservationDetails(+reservationId, history))
  }, [dispatch, reservationId, history])

  const onPaymentCheckboxChange = ({ userId, isProceed, isEdit }) => {
    setIsProceedClicked(isProceed)
    if (isEdit) {
      setPayForUsers([])
      setIsCheckBoxClicked(true)
      return
    }
    if (isProceed) {
      setIsCheckBoxClicked(true)
    } else {
      setPayForUsers(arr => {
        let val = [...arr]
        let index = val.findIndex(el => +el === +userId)
        if (index < 0) val.push(+userId)
        else val.splice(index, 1)
        return val
      })
    }
  }

  const onSendToKitchen = async () => {
    dispatch(sendToKitchen(reservationId, payForUsers))

    setSendToKitchenModal(false)
  }

  const onRefreshVoidedOrders = (tip) => {
    dispatch(getCheckoutInfo(reservationId, payForUsers, tip))
  }

  const onSendToKitchenClickHandler = () => {
    // allFriendsHaveOrdered(ordersRounds)
    //   ? onSendToKitchen()
    //   :
    setSendToKitchenModal(true)
  }


  useEffect(() => {
    if (ordersRounds.length > 0) {
      const userIds = []

      ordersRounds.forEach(round => {
        round.users.forEach(user => {
          const isUserToAdd = user.orders.length === 0 || user.orders.some(order => !order.paid)

          if (isUserToAdd) {
            user = {
              userId: user.user_id,
              userName: user.user,
              orders: user.orders
            }
          }

          if (!userIds.some(userId => userId.userId === user.userId) && isUserToAdd)
            if (user.orders.length !== 0) userIds.push(user)
        })
      })
      setOrderUserIds(userIds.filter(user => user.userId !== id))
    }

    if (!currentReservation) dispatch(getCurrentReservation())
    else if (!lastRoundOrders) dispatch(getLastRoundOrders(currentReservation.reservationId))
  }, [ordersRounds, id, dispatch, currentReservation?.reservationId])

  useEffect(() => {
    // check if any user's order is paid by himself
    // if there is none, hide the receipt button
    // all orders have been paid by other users
    if (currentReservation?.has_receipt) {
      setShowReceiptButton(true)
    }
  }, [ordersRounds, id, showReceiptButton])

  // useEffect(() => {
  //   if (reservationId && (!receiptURL || updateUserReceipt)) {
  //     dispatch(getReceipt(reservationId, setReceiptURL))
  //     dispatch(setUpdateUserReceipt(false))
  //   }

  // }, [dispatch, reservationId, updateUserReceipt, receiptURL])

  return (
    <>
      {!waitingForAllDataToCome && (
        <>
          {sendToKitchenModal ? (
            <SendToKitchenModal
              show={sendToKitchenModal}
              close={() => setSendToKitchenModal(false)}
              onSend={onSendToKitchen}
            />
          ) : null}

          <div className="orders-rounds-wrapper">
            {show_table_number !== null && show_table_number ?
              <h4 style={{ textAlign: "center", fontFamily: "Cormorant", marginBottom: "1rem" }}>Orders on table {currentReservation?.table_number}</h4> :
              null
            }
            {/* <div className='checkout-button' style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={() => history.push(`${order_page_path.replace(':reservationId', currentReservation?.reservationId)}${menuStepQueryParam}`)} style={{ margin: "0.5rem 0 1rem" }} className="btn btn-primary">
                Add items from Menu
              </button>
            </div> */}

            {/* NO ROUNDS! */}


            {
              ordersData?.map((user, idx) => {
                const userDetails = !isEmpty(reservationDetails.reservationGuests) && Array.isArray(reservationDetails.reservationGuests) ? reservationDetails.reservationGuests.find(guest => guest.user_id === user.user_id) : null;
                const isPresent = userDetails?.is_present ?? true;
                return (
                  <UserOrders
                    key={user.user_id ?? idx}
                    orderPosition={idx}
                    is_archived={user.is_archived}
                    canPay={hasNewOrder}
                    canOrder={canOrder}
                    onPaymentCheckboxChange={() =>
                      onPaymentCheckboxChange({ userId: user.user_id })
                    }
                    isPaymentChecked={!!payForUsers.find(userId => +userId === +user.user_id)}
                    areOwnOrders={id === +user.user_id}
                    avatarBackground={user.is_archived ? ARCHIVED_USER_COLOR : avatarColors[idx]}
                    avatarColors={avatarColors}
                    userDisplayName={id === +user.user_id ?  user.user : user.user}
                    userStatus={user.user_order_status && user.user_order_status}
                    firstLetter={
                      user.is_archived
                        ? '?'
                        : user.user.charAt(0).toUpperCase()
                      // user.is_anonymous
                      //   ? `${user.user.charAt(0).toUpperCase()}${user.user.charAt(user.user.length - 1).toUpperCase()}`
                      //   : user.user.charAt(0).toUpperCase()
                    }
                    reservationId={reservationId}
                    {...user}
                    user={user}
                    isActiveReservation={isActiveReservation}
                    currentUserId={user.user_id}
                    isAccordionOpened={isAccordionOpened}
                    hasTableId={true}
                    isPresent={isPresent}
                  />
                )
              })
            }
            <RoundCompletion
              checkoutInfo={checkoutInfo}
              onCompleteOrderClick={onCompleteOrderClick}
              onSendToKitchen={onSendToKitchenClickHandler}
              otherTryingToPay={otherTryingToPay}
              payForUsers={payForUsers}
              hasNewOrder={hasNewOrder}
              onPaymentCheckboxChange={onPaymentCheckboxChange}
              orderUserIds={orderUserIds}
              onRefreshVoidedOrders={onRefreshVoidedOrders}
            />
          </div>
        </>
      )}
    </>
  )
}

export default OrdersRounds
