import creditCardPlaceholder from "../../../assets/credit-card.png";
import visaPlaceHolder from "../../../assets/visa.svg";
import masterCardPlaceHolder from "../../../assets/mastercard.svg";
import aExpressPlaceHolder from "../../../assets/amex.svg";

export const card_types_enum = {
    undefined: creditCardPlaceholder,
    null: creditCardPlaceholder,
    visa: visaPlaceHolder,
    master: masterCardPlaceHolder,
    american_express: aExpressPlaceHolder
}

export const buttonClassesEnum = {
    true: 'active',
    false: 'inactive'
}

// Position of the buttons is determined by the position of the defined object
// This is defined as object and not as an array, because we want to access object by their keys and by their index as well
// And we can really do both easily in JS if we declare it as an object
export const paymentComponentsEnum = {
    CREDIT_CARD: {
        buttonName: 'Credit card',
        isEnabled: true
    },
    APPLE_PAY: {
        buttonName: 'Apple Pay',
        isEnabled: true
    },
    GOOGLE_PAY: {
        buttonName: 'Google Pay',
        isEnabled: true
    }
}
