import React from 'react'
import classnames from 'classnames'
import { useLocation } from 'react-router'

// const badgeStyle = {
//   width: '20px',
//   height: '20px',
//   borderRadius: '33px',
//   border: 'solid 1px #ffffff',
//   backgroundColor: '#01003b',
//   color: '#ffffff',
//   textAlign: 'center',
//   transform: 'translateY(-30px) translateX(12px)',
//   position: 'absolute',
//   lineHeight: '20px'
// }

function NavItemWithIcon({
  activeIcon,
  inactiveIcon,
  text,
  onClick,
  className,
  path,
  icon,
  handlePopoverEnter,
  handlePopoverLeave,
  badgeNumber = 0,
  isNewNavigationMenu
}) {
  const location = useLocation()
  let isActive = false;

  if (Array.isArray(path)) {
    isActive = path.includes(location.pathname);
  } else {
    isActive = location.pathname === path;
  }

  return (

    <li
      onClick={onClick}
      className={classnames('nav-item my-auto', className, {
        active: isActive
      })}
      // aria-owns={'nav-profile'}
      // aria-haspopup={true}
      // onMouseEnter={handlePopoverEnter && handlePopoverEnter}
      // onMouseLeave={handlePopoverLeave && handlePopoverLeave}
    >
      {/* {isNewNavigationMenu !== null ?
        <img
          className="nav-item-img"
          src={isActive ? activeIcon : inactiveIcon}
          alt="nav-item"
        /> : null
      } */}
      {/* {isActive ? activeIcon && activeIcon : inactiveIcon && inactiveIcon} */}
      {icon}
      {/* {badgeNumber ? (
        <div className="nav-item-badge" style={badgeStyle}>
          <span>{badgeNumber}</span>
        </div>
      ) : null} */}

      {/* <span style={{ marginLeft: '10px' }} className="nav-item-text">
        {text}
      </span> */}
    </li>
  )
}

export default NavItemWithIcon
