import isEmpty from 'lodash.isempty'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import CustomButton from '../../components/common/CustomButton'
import RestaurantHeaderImage from '../../components/restaurant/RestauranHeadertImage'
import RestaurantActions from '../../components/restaurant/RestaurantActions'
import RestaurantDescription from '../../components/restaurant/RestaurantDescription'
import RestaurantMenu from '../../components/restaurant/RestaurantMenu'
import {
  booking_page_path,
  full_menu_path,
  order_page_path,
  table_number_page_path
} from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import { setPageRoutePath } from '../../store/actions/feedbackActions'
import './Restaurant.css'
import { getCurrentReservation } from '../reservations/services/actions'
// import useFloatingIslandActions from '../../hooks/useFloatingIslandActions'

function RestaurantPage() {
  const {
    restaurant: { restaurant, menu, currentPosition },
    feedback: { responseMessage, onFullPageModal },
    booking: { reservations, currentReservation, reservationId, },
    versionConfig: { show_booking_flow },
  } = useSelector(state => state)

  const {
    name,
    about,
    style,
    price_range,
    reservation,
    latitude,
    longitude,
    about_us_link,
    reviews_link,
    address,
    images,
    restaurant_id: restaurantId
  } = restaurant || {}

  const dispatch = useDispatch()
  const history = useHistory()

  // const [reservationOnCurrentRestaurant, setReservationOnCurrentRestaurant] = useState(false)
  // const [reservationTypeOnCurrentRestaurant, setReservationTypeOnCurrentRestaurant] = useState(null)
  // const [currentOngoingReservation, setCurrentOngoingReservation] = useState(null)
  // const [upcomingReservationDates, setUpcomingReservationDates] = useState(null)
  // const [currentUpcomingReservation, setCurrentUpcomingReservation] = useState(null)

  // useEffect(() => {
  //   !isEmpty(reservation)  ? setReservationOnCurrentRestaurant(true) : setReservationOnCurrentRestaurant(false)
  //   reservationTypeOnCurrentRestaurant &&
  //   !isEmpty(reservations?.ongoing) ? setReservationTypeOnCurrentRestaurant(reservationTypeEnum.ongoing) : setReservationTypeOnCurrentRestaurant(reservationTypeEnum.upcoming)
  // }, [reservation, reservationOnCurrentRestaurant, reservationTypeOnCurrentRestaurant])

  const onBookClickHandler = () => {
    // dispatch(setPageRoutePath(
    //   booking_page_path.replace(':restaurantId', restaurantId)
    // ))
    history.push(booking_page_path.replace(':restaurantId', restaurantId))
  }

  const onOrderClickHandler = () => {
    dispatch(setPageRoutePath(
      table_number_page_path.replace(':restaurantId', restaurantId)
    ))
  }

  // #region Floating Island

  // const { backToOrder, bookTable, orderNow } = useFloatingIslandActions()

  // const [actions, setActions] = useState({ options: [
  //   bookTable,
  //   orderNow,
  //   !isEmpty(currentReservation) && backToOrder,
  // ]})

  // #endregion

  useEffect(() => {
    if (reservationId && responseMessage) {
      dispatch(
        setPageRoutePath(
          order_page_path.replace(':reservationId', reservationId)
        )
      )
    }
  }, [reservationId, responseMessage, dispatch])

  const onSeeFullMenuClickHandler = () => {
    history.push(full_menu_path.replace(':restaurantId', restaurantId))
  }

  return (
    <Row className="restaurant-page-row">
      <RestaurantHeaderImage
        images={images?.length ? images : []}
        reservation={reservation}
        reservations={reservations}
        restaurantIdOnCurrentRestaurant={restaurantId}
        ongoing={reservations?.ongoing}
        latestUpcoming={reservations?.latestUpcoming}
        className='restaurant-carousel-images-container'
      // reservationOnCurrentRestaurant={reservationOnCurrentRestaurant}
      // reservationTypeOnCurrentRestaurant={reservationTypeOnCurrentRestaurant}
      />
      <Column md="12" lg="12">
        <Row>
          <Column className="text-center" md="6" lg="5" xl={4}>
            <RestaurantDescription
              about={about}
              name={name}
              style={style}
              price_range={price_range}
              latitude={latitude}
              longitude={longitude}
              address={address}
              currentPosition={currentPosition}
              about_us_link={about_us_link}
              reviews_link={reviews_link}
            />
            {(
              <RestaurantActions
                onBook={onBookClickHandler}
                onOrder={onOrderClickHandler}
                showBookingFlow={show_booking_flow}
                hasOrder={currentReservation}
                onFullPageModal={onFullPageModal}
              />
            )}
          </Column>
          <Column
            justifyContent="start"
            className="text-center "
            md="6"
            lg="5"
            xl={4}
          >
            <div className="align-self-center menu-title-wrapper mx-auto">
              <div className="menu-title-line" />
              Menu
              <div className="menu-title-line" />
            </div>
            <Row justify="start" className="my-4 menu-row">
              <RestaurantMenu menu={menu} />
              <CustomButton
                onClick={onSeeFullMenuClickHandler}
                wrapperClassName="see-full-menu-btn mx-auto"
                textClassName="see-full-menu-btn-text"
                text="See full menu"
              />
            </Row>
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default RestaurantPage
