import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import {
  booking_order_page_path,
  home_path,
  order_page_path,
  past_order_page_path,
} from '../../config/pages_paths'
import useSocket from '../../hooks/useSocket'
import { setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { getReservationDetails } from '../book/services/actions'
import RestaurantMenuApproved from '../menu/RestaurantMenuApproved'
import {
  getCurrentReservation
} from '../reservations/services/actions'
import {
  setModifiersForMenuItem,
  setSelectedModifiersForOrderItem
} from '../restaurant/services/actions'
import './OrderPage.css'
import {
  addItemToOrder,
  getLastRoundOrders
} from './services/actions'
import { orderStatusEnum } from '../../enums/orderStatusEnum'
import ReturnToReservationModal from '../reservation/ReturnToReservationModal'
import isEmpty from 'lodash.isempty'

function OrderPage() {
  const {
    // restaurant: { restaurant, },
    booking: { currentReservation, reservationDetails },
    orders: { lastRoundOrders, },
    feedback: { refreshDataForOpenedMenuItem, toast }
  } = useSelector(state => state)

  const dispatch = useDispatch()
  const [connectSocket, setConnectSocket] = useState(true)
  const { reservationId } = useParams()

  const history = useHistory()
  const location = useLocation()

  // useEffects


  useEffect(() => {
    if (reservationId === 'undefined' || reservationId === undefined) {
      history.push(home_path)
    }
  }, [reservationId, history])

  useEffect(() => {
    // if (reservationId)
    dispatch(getReservationDetails(+reservationId, history))
  }, [dispatch, reservationId, history])


  // useEffect(() => {
  //   if (!isEmpty(reservationDetails?.reservation)) {
  //     dispatch(getFullMenu(reservationDetails.reservation.restaurant_id, reservationDetails.reservation.reservation_id, false, history))
  //   }
  // }, [dispatch, reservationDetails, history])

  useEffect(() => {
    if (reservationId) {
      dispatch(getCurrentReservation())
      // dispatch(getOrders(reservationId))
      // dispatch(getRestaurants())
      // dispatch(getReservations())
    }
  }, [reservationId, dispatch])

  useEffect(() => {
    if (reservationId) {
      dispatch(getLastRoundOrders(reservationId))
    }
  }, [dispatch, reservationId]);

  useEffect(() => {
    if ((currentReservation?.reservation_id === parseInt(reservationId))) {
      setConnectSocket(false)

      setTimeout(() => {
        setConnectSocket(true)
      }, 1000)
    }
  }, [dispatch, currentReservation?.reservation_id, reservationId])

  const onAddClickHandler = async (data) => {
    const addedItem = await dispatch(addItemToOrder(reservationId, data))
    if (addedItem && reservationId) {
      await dispatch(getLastRoundOrders(reservationId))
    }

    // onCloseAddMenuItemHandler()
  }

  const onCloseAddMenuItemHandler = () => {
    dispatch(setSelectedModifiersForOrderItem([]))
    dispatch(setModifiersForMenuItem([]))
    dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: null, refresh: false }))
  }

  // useEffect(() => {
  //   if (!isEmpty(reservationDetails) && !!reservationDetails?.reservation?.is_closed &&
  //     (location.pathname === order_page_path.replace(':reservationId', reservationId))
  //   ) {
  //     history.push(past_order_page_path.replace(':reservationId', reservationId))
  //   }
  //   if (!isEmpty(reservationDetails) && !reservationDetails?.reservation?.is_closed &&
  //     (location.pathname === past_order_page_path.replace(':reservationId', reservationId))
  //   ) {
  //     history.push(order_page_path.replace(':reservationId', reservationId))
  //   }
  // }, [history, location, reservationDetails, reservationId])

  const onRefresh = useCallback((reservation_id) => {
    if (currentReservation?.reservation_id === parseInt(reservationId)) {
      if (refreshDataForOpenedMenuItem.menuItemId) {
        dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: true }))
      } else {
        dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: null, refresh: false }));
      }
    }
  }, [dispatch, currentReservation?.reservation_id, reservationId, refreshDataForOpenedMenuItem.menuItemId])

  useSocket({
    reservationId: (currentReservation || {}).reservation_id,
    shouldConnect: connectSocket,
    onRefresh
  })

 useEffect(() => {
  if((location.pathname === order_page_path.replace(":reservationId", reservationId) ||
  location.pathname === booking_order_page_path.replace(":reservationId", reservationId))) {
    if(toast.message === "You are not authorized" || toast.message === "Reservation is cancelled") {
      history.push(home_path)
    }
    else if(reservationDetails.reservation.is_closed ) {
      history.push(past_order_page_path.replace(":reservationId", reservationId))
    }
  }
 },[location, reservationDetails, reservationId, toast, history])

  return (
    <div className=''>
      {location.pathname === order_page_path.replace(":reservationId", reservationId) ||
        location.pathname === booking_order_page_path.replace(":reservationId", reservationId) ?
        <>
          <RestaurantMenuApproved
            onReservation
            onBooking={!reservationDetails.reservation?.is_walk_in && reservationDetails.reservation?.order_status === orderStatusEnum.booked}
            restaurantIdFromReservation={reservationDetails.reservation?.restaurant_id}
            reservationId={reservationId}
            lastRoundOrders={lastRoundOrders}
            onAddClickHandler={onAddClickHandler}
            onCloseAddMenuItemHandler={onCloseAddMenuItemHandler}
          // onMenuItemClickHandler={onMenuItemClickHandler}
          />

          {/* <ReturnToReservationModal
            show={!((location.pathname === order_page_path.replace(":reservationId", reservationId) ||
              location.pathname === booking_order_page_path.replace(":reservationId", reservationId)) && (isEmpty(reservationDetails.reservation || reservationDetails.reservation.is_closed)))}
            close={() => history.push(past_order_page_path.replace(":reservationId", reservationId))}
            title={"Invalid session"}
            description={'The reservation is finished'}
          /> */}
        </>
        : null
      }
    </div>
  )
}

export default OrderPage
