import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';

const BookingStatus = ({
  isCurrentUserHost,
  numberOfGuestsOnReservation,
  numberOfGuestsWithAcceptedInvitations,
  numberOfGuestsWithOrders,
  canStartReservation,
  isBookingConfirmed,
}) => {

  const checkItOut =
    <CheckIcon
      fontSize={"medium"}
      style={{
        backgroundColor: "green",
        color: "white",
        borderRadius: "50%",
        padding: "0.1rem"
      }}
    />

  const warnItOut =
    <WarningIcon
      fontSize={"medium"}
      style={{
        backgroundColor: "orange",
        color: "white",
        borderRadius: "50%",
        padding: "0.1rem"
      }}
    />

  const closeItOut =
    <CloseIcon
      fontSize={"medium"}
      style={{
        backgroundColor: "red",
        color: "white",
        borderRadius: "50%",
        padding: "0.1rem"
      }}
    />

  const guestsCheck = numberOfGuestsOnReservation === numberOfGuestsWithAcceptedInvitations;
  const ordersCheck = numberOfGuestsOnReservation === numberOfGuestsWithOrders

  const bookingMessages = [
    {
      label:
        !!guestsCheck ?
          "Everyone has accepted the invitation" :
          `${numberOfGuestsWithAcceptedInvitations}
          out of
          ${numberOfGuestsOnReservation} people
          have joined the booking`,
      icon: !!guestsCheck ?
        checkItOut :
        warnItOut,
      show: true
    },
    {
      label: !!ordersCheck ?
        "All guests have placed an order" :
        `${numberOfGuestsWithOrders}
        out of
        ${numberOfGuestsOnReservation}
          people have placed an order`,

      icon: !!ordersCheck ?
        checkItOut :
        warnItOut,
      show: true
    },
    {
      label: canStartReservation
        ? "You can now start to your dining experience"
        : "It is not yet time to start your dining experience",
      icon: canStartReservation ?
        checkItOut :
        closeItOut,
      show: true
    },
    {
      label: isBookingConfirmed
        ? "The hostess has confirmed your presence."
        : "The hostess has not confirmed your presence.",
      icon: isBookingConfirmed ?
        checkItOut :
        closeItOut,
      show: true
    },
  ]

  return (

    <div style={{ margin: "1rem ", }}>
      {bookingMessages.map((message, idx) => {
        return (
          message.show ?
            <div key={idx} style={{
              display: "flex",
              alignItems: "center",
              // justifyContent:"center",
              gap: "1rem",
              margin: "0.5rem 0",
              textAlign: "left"
            }}>
              {message.icon}
              <p style={{ fontFamily: "Lato", fontSize: "16px", textAlign: "left" }}>
                {message.label}
              </p>
            </div> :
            null
        )
      })}
    </div>
  )
}

export default BookingStatus