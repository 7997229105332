import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EmptyReservationImage from '../../assets/empty-plate.png'
import { getOrdersNoRounds } from '../../pages/order/services/actions'
import Image from '../common/Image'
import UserOrderItem from './components/UserOrderItem'
import SeparationLine from '../common/SeparationLine'

const MenuOrders = ({ restaurantLogo, reservationId }) => {
    const {
        // auth: {
        //     user: { id }
        // },
        orders: { lastRoundOrders, ordersNoRounds },
    } = useSelector(state => state)

    const dispatch = useDispatch()
    const [dispatchedOrders, setDispatchedOrders] = useState([])
    const [undispatchedOrders, setUndispatchedOrders] = useState([])

    const prevLastRoundOrdersRef = useRef(lastRoundOrders);

    useEffect(() => {
        if (prevLastRoundOrdersRef.current !== lastRoundOrders) {
            dispatch(getOrdersNoRounds(reservationId));
            prevLastRoundOrdersRef.current = lastRoundOrders;
        }
    }, [dispatch, reservationId, lastRoundOrders]);

    useEffect(() => {
        if (!isEmpty(ordersNoRounds)) {
            const dispatched = []
            const undispatched = []
            ordersNoRounds.forEach(user => {
                user.orders.forEach(order => {
                    if (order.round === null) {
                        undispatched.push(order)
                    } else {
                        dispatched.push(order)
                    }
                })
            })
            setDispatchedOrders(dispatched)
            setUndispatchedOrders(undispatched)
        }
    }, [ordersNoRounds])

    return (
        <>
            {restaurantLogo ?
                <div className="restaurant-logo-wrapper" style={{ margin: "0.5rem 0 1rem" }}>
                    <Image width={"240px"} height={"160px"} restaurant src={restaurantLogo} alt="restaurant-logo" />
                </div> :
                null
            }
            <div>
                <div className='user-orders-drawer-container'>
                    {isEmpty(dispatchedOrders) && isEmpty(undispatchedOrders) ?
                        <div className='empty-user-orders-wrapper'>
                            <img src={EmptyReservationImage} alt="empty-reservation" />
                            <h2 style={{ margin: "1rem 0 0" }}>Nothing selected yet.</h2>
                        </div>
                        : null
                    }
                    {!isEmpty(undispatchedOrders) ?
                        <div style={{ margin: "0 0 2rem", textAlign: "center" }}>
                            <h4>Currently selected</h4>
                            <SeparationLine style={{ margin: "auto" }} />
                            {undispatchedOrders.map((order, idx) => (
                                <UserOrderItem
                                    key={idx}
                                    isOwnItem={false}
                                    canOrder={false}
                                    orderItem={order}
                                    lastItem={undispatchedOrders.length === idx + 1}
                                    canPreview={false}
                                />
                            ))}
                        </div>
                        : null
                    }
                    {!isEmpty(dispatchedOrders) ?
                        <div style={{ textAlign: "center" }}>
                            <h4>Previously ordered</h4>
                            <SeparationLine style={{ margin: "auto" }} />
                            {dispatchedOrders.map((order, idx) => (
                                <UserOrderItem
                                    key={idx}
                                    isOwnItem={false}
                                    canOrder={false}
                                    orderItem={order}
                                    lastItem={dispatchedOrders.length === idx + 1}
                                    canPreview={false}
                                />
                            ))}
                        </div>
                        : null
                    }
                </div>
            </div>
        </>
    )
}

export default MenuOrders
