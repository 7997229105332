import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { createDummyReservationWithOrder } from './services/actions'

const CreateOrder = () => {
    const {
        orders: {  newOrderDetails },
    } = useSelector(state => state)
    const history = useHistory()
    const dispatch = useDispatch()
    const { restaurantId, tableNumber, reservationId } = useParams()

    // const location = useLocation()

    useEffect(() => {
        if (!reservationId) {
            if (restaurantId && tableNumber) {
                dispatch(createDummyReservationWithOrder({ restaurantId, tableNumber }, null, history))
            } else {
                dispatch(createDummyReservationWithOrder(newOrderDetails, null, history))
            }
        }
    }, [dispatch, history, reservationId, newOrderDetails, tableNumber, restaurantId])
    return null;
}

export default CreateOrder