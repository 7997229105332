import {
  SET_RESERVATIONS,
  SET_RESERVATIONS_MANUALLY,
  SET_RESERVATION_DETAILS,
  SET_INVITATION_TOKEN,
  SET_INVITATION_DETAILS,
  SET_RESERVATION_ID,
  SET_INVITE_MESSAGE,
  SET_TIMES_AVAILABLE,
  SET_CURRENT_RESERVATION,
  SET_INIT_RESERVATION_TYPE,
  SET_BOOKING_DATA,
  SET_REFRESH_CHECKOUT,
  SET_OTHER_TRYING_TO_PAY,
  SET_USER_IDS_SOMEONE_PAYS_FOR,
  SET_SEND_TO_KITCHEN_MODE,
  SET_PAYING_FOR_OTHERS,
  SET_BOOKING_SUCCESS,
  SET_RESERVATION_GUESTS,
  SET_FEEDBACK,
  SET_TIMER_EXPIRATION_TIME,
  CLOSE_CURRENT_RESERVATION,
  SET_SWITCH_TO_INPUT_COMPONENT,
  SET_HAS_BOOKED_RESERVATION,
  SET_BOOKING_STATUS_DATA
} from '../../../config/actionTypes'

import { orderStatusEnum } from '../../../enums/orderStatusEnum'

export const initialReservationDetails = {
  reservation: {},
  reservationGuests: {},
  reservationOrders: {}
}

const initialState = {
  reservationId: '',
  reservations: {
    upcoming: [],
    latestUpcoming: null,
    ongoing: null,
    past: []
  },
  currentReservation: null,
  bookingData: {},
  reservationDetails: initialReservationDetails,
  invitationToken: '',
  invitationDetails: {},
  timesAvailable: [],
  inviteMessage: '',
  initReservationType: null,
  refreshCheckout: false,
  guests: [],
  otherTryingToPay: false,
  idsSomeonePaysFor: [],
  isSendToKitchenMode: false,
  payingForOthersState: [],
  switchToInputComponent: [],
  hasBookedReservation: null,
  bookingStatusData: {
    isCurrentUserHost: null,
    numberOfGuestsOnReservation: null,
    numberOfGuestsWithAcceptedInvitations: null,
    numberOfGuestsWithOrders: null,
    canStartReservation: null,
    isBookingConfirmed: null,
  },
  bookingSuccess: null,
}

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESERVATION_ID:
      return { ...state, reservationId: action.payload }
    case SET_RESERVATIONS:
      const reservations = {
        latestUpcoming: {},
        upcoming: [],
        ongoing: null,
        past: [],
        closed: []
      }

      action.payload && action.payload.forEach(reservation => {
        const {
          restaurant_id,
          reservation_id,
          restaurant_name,
          restaurant_address,
          date_string,
          booking_date,
          date,
          size_of_party,
          restaurant_image,
          dummy,
          is_walk_in,
          table_number,
          order_status,
          manage_reservation_url,
          user_sub_total,
          restaurant_logo,
        } = reservation

        reservation = {
          restaurant_id,
          reservation_id,
          name: restaurant_name,
          address: restaurant_address,
          date: date_string,
          dateInSeconds: date,
          booking_date,
          sizeOfParty: size_of_party,
          img: restaurant_image,
          logo: restaurant_logo,
          dummy,
          is_walk_in,
          table_number,
          order_status,
          manage_reservation_url,
          user_sub_total,
        }

        switch (reservation.order_status) {
          case orderStatusEnum.booked:
            reservations.upcoming.push(reservation)
            if (reservations.upcoming.length > 0) {
              reservations.latestUpcoming = reservations.upcoming.reduce((lowest, current) => {
                return current.dateInSeconds < lowest.dateInSeconds ? current : lowest;
              }, reservations.upcoming[0]);
            }
            break
          case orderStatusEnum.orderStarted:
          case orderStatusEnum.orderSent:
            reservations.ongoing = { ...reservation, isComplete: !!action.payload }
            break
          case orderStatusEnum.orderPaid:
          case orderStatusEnum.orderPaidPOS:
          case orderStatusEnum.cancelled:
            reservations.past.push(reservation)
            break
          case orderStatusEnum.autoClosed:
          case orderStatusEnum.noshow:
          case orderStatusEnum.orderManuallyClosed:
          case orderStatusEnum.orderExpired:
            reservations.closed.push(reservation)
            break
          default:
          // console.error("invalid reservation status: ", reservation.order_status)
        }
      })

      return { ...state, reservations }
    case SET_RESERVATIONS_MANUALLY:
      return { ...state, reservations: action.payload }
    case SET_RESERVATION_DETAILS:
      return { ...state, reservationDetails: action.payload }
    case SET_RESERVATION_GUESTS:
      return { ...state, guests: action.payload }
    case SET_INVITATION_TOKEN:
      return { ...state, invitationToken: action.payload }
    case SET_INVITATION_DETAILS:
      return { ...state, invitationDetails: action.payload }
    case SET_INVITE_MESSAGE:
      return { ...state, inviteMessage: action.payload }
    case SET_TIMES_AVAILABLE:
      return { ...state, timesAvailable: action.payload }
    case SET_CURRENT_RESERVATION:
      return { ...state, currentReservation: action.payload !== null ? action.payload : {} }
    case SET_FEEDBACK:
      return {
        ...state,
        currentReservation: {
          ...state.currentReservation,
          tip: action.payload.tip,
          feedback: action.payload.feedback
        }
      }
    case SET_INIT_RESERVATION_TYPE:
      return { ...state, initReservationType: action.payload }
    case SET_BOOKING_DATA:
      return { ...state, bookingData: action.payload }
    case SET_REFRESH_CHECKOUT:
      return { ...state, refreshCheckout: action.payload }
    case SET_OTHER_TRYING_TO_PAY:
      return { ...state, otherTryingToPay: action.payload }
    case SET_USER_IDS_SOMEONE_PAYS_FOR:
      return { ...state, idsSomeonePaysFor: action.payload }
    case SET_SEND_TO_KITCHEN_MODE:
      return { ...state, isSendToKitchenMode: action.payload }
    case SET_PAYING_FOR_OTHERS:
      return { ...state, payingForOthersState: action.payload }
    case SET_BOOKING_SUCCESS:
      return { ...state, bookingSuccess: action.payload }
    case SET_TIMER_EXPIRATION_TIME:
      return {
        ...state,
        currentReservation: { ...state.currentReservation, expiration_time: action.payload },
      }
    case CLOSE_CURRENT_RESERVATION:
      return {
        ...state,
        currentReservation: {
          ...state.currentReservation,
          is_closed: true
        },
        reservations: {
          ...state.reservations,
          ongoing: null
        }
      }
    case SET_SWITCH_TO_INPUT_COMPONENT:
      return {
        ...state,
        switchToInputComponent: [...state.switchToInputComponent, action.payload]
      };
    case SET_HAS_BOOKED_RESERVATION:
      return { ...state, hasBookedReservation: action.payload }
    case SET_BOOKING_STATUS_DATA: {
      const {
        user,
        reservationDetails: {
          reservation,
          reservationGuests,
          reservationOrders,
        } } = action.payload

      const [initialRound] = reservationOrders
      const numberOfGuestsOnReservation = reservation?.size_of_party
      const isBookingConfirmed = reservation.is_booking_confirmed
      const numberOfGuestsWithAcceptedInvitations = reservationGuests?.length
      const numberOfGuestsWithOrders = initialRound?.users.filter(user => user?.orders.length > 0)?.length ?? 0;
      const isCurrentUserHost = reservationGuests?.find(
        (guest) =>
          guest.user_id === user?.id &&
          guest?.user_type === "HOST"
      )

      const canStartReservation = reservation?.isWithinEligibleTimeRange
      return {
        ...state,
        bookingStatusData: {
          isCurrentUserHost: !!isCurrentUserHost,
          numberOfGuestsOnReservation: numberOfGuestsOnReservation,
          numberOfGuestsWithAcceptedInvitations: numberOfGuestsWithAcceptedInvitations,
          numberOfGuestsWithOrders: numberOfGuestsWithOrders,
          canStartReservation: canStartReservation,
          isBookingConfirmed: isBookingConfirmed
        }
      }
    }
    default:
      return state
  }
}

export default bookingReducer;