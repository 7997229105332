import React from 'react'
import ReservationListItem from '../../components/reservations/ReservationListItem'
import { isEmpty } from 'lodash'
import EmptyReservation from '../../components/reservations/EmptyReservation'
import { past_order_page_path } from '../../config/pages_paths'

const PastReservationsPage = ({
  pastReservations,
  history,
}) => {
  const handleReservationHavigation = (reservationId) => {
   history && history.push(past_order_page_path.replace(':reservationId', reservationId))
  }

  return (
    <ul className="reservations-list">
      {!isEmpty(pastReservations) ?
        pastReservations.map((props, idx) => (
          <ReservationListItem key={idx} onClick={() => handleReservationHavigation(props.reservation_id)} {...props} />
        )) :
        <EmptyReservation
          text="No booking or orders yet."
          paragraph="Ready when you are."
        />
      }
    </ul>
  )
}

export default PastReservationsPage