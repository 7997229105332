export const SET_RESTAURANTS = 'SET_RESTAURANTS'
export const SET_RESTAURANT = 'SET_RESTAURANT'
export const SET_RESTAURANT_BY_ID = 'SET_RESTAURANT_BY_ID'
export const SET_MENU = 'SET_MENU'
export const SET_USER = 'SET_USER'
export const SET_NEW_USER = 'SET_NEW_USER'
export const SET_NEW_USER_MAIL = 'SET_NEW_USER_MAIL'
export const SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE'
export const SET_RESERVATIONS = 'SET_RESERVATIONS'
export const SET_RESERVATIONS_MANUALLY = 'SET_RESERVATIONS_MANUALLY'
export const SET_MODAL = 'SET_MODAL'
export const SET_PAGE_ROUTE_PATH = 'SET_PAGE_ROUTE_PATH'
export const SET_FULL_MENU = 'SET_FULL_MENU'
export const SET_RESERVATION_DETAILS = 'SET_RESERVATION_DETAILS'
export const SET_RESERVATION_GUESTS = 'SET_RESERVATION_GUESTS'
export const SET_ORDERS = 'SET_ORDERS'
export const SET_FEEDBACK = 'SET_FEEDBACK'
export const SET_INVITATION_TOKEN = 'SET_INVITATION_TOKEN'
export const SET_RESERVATION_ID = 'SET_RESERVATION_ID'
export const SET_INVITATION_DETAILS = 'SET_INVITATION_DETAILS'
export const SET_INVITE_MESSAGE = 'SET_INVITE_MESSAGE'
export const SET_ERRORS = 'SET_ERRORS'
export const SET_PASSWORD_RESET_TOKEN = 'SET_PASSWORD_RESET_TOKEN'
export const SET_TIMES_AVAILABLE = 'SET_TIMES_AVAILABLE'
export const SET_ORDERS_ROUNDS = 'SET_ORDERS_ROUNDS'
export const SET_ORDERS_NO_ROUNDS = 'SET_ORDERS_NO_ROUNDS'
export const SET_LAST_ROUND_ORDERS = 'SET_LAST_ROUND_ORDERS'
export const SET_CURRENT_RESERVATION = 'SET_CURRENT_RESERVATION'
export const SET_INIT_RESERVATION_TYPE = 'SET_INIT_RESERVATION_TYPE'
export const SET_ORDER_NEXT_ROUTE = 'SET_ORDER_NEXT_ROUTE'
export const SET_REGISTER_REDIRECT_RESPONSE = 'SET_REGISTER_REDIRECT_RESPONSE'
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_UPDATE_USER_RECEIPT = 'SET_UPDATE_USER_RECEIPT'
export const SET_NUM_OF_LOADING_SPINNER_CALLS = 'SET_NUM_OF_LOADING_SPINNER_CALLS'
export const SET_CHECKOUT_INFO = 'SET_CHECKOUT_INFO'
export const SET_BOOKING_DATA = 'SET_BOOKING_DATA'
export const SET_NEW_ORDER_DETAILS = 'SET_NEW_ORDER_DETAILS'
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION'
export const SET_REFRESH_CHECKOUT = 'SET_REFRESH_CHECKOUT'
export const SET_ORDER_PROCESSING = 'SET_ORDER_PROCESSING'
export const SET_OTHER_TRYING_TO_PAY = 'SET_OTHER_TRYING_TO_PAY'
export const SET_USER_IDS_SOMEONE_PAYS_FOR = 'SET_USER_IDS_SOMEONE_PAYS_FOR'
export const SET_SEND_TO_KITCHEN_MODE = 'SET_SEND_TO_KITCHEN_MODE'
export const SET_PAYMENT_METHODS_FOR_USER = 'SET_PAYMENT_METHODS_FOR_USER'
export const SET_MODIFIERS_FOR_MENU_ITEM = 'SET_MODIFIERS_FOR_MENU_ITEM'
export const SET_SELECTED_MODIFIERS_FOR_ORDER_ITEM = 'SET_SELECTED_MODIFIERS_FOR_MENU_ITEM'
export const SET_IS_TABLE_VALID = 'SET_IS_TABLE_VALID'
export const SET_CURRENT_ACCORDION = 'SET_CURRENT_ACCORDION'
export const SET_CURRENT_ACCORDION_ORDER = 'SET_CURRENT_ACCORDION_ORDER'
export const SET_PAYING_FOR_OTHERS = 'SET_PAYING_FOR_OTHERS'
export const SET_IS_PAY = 'SET_IS_PAY'
export const SET_IS_PROCEEDED_TO_CHECKOUT_SELECTION = 'SET_IS_PROCEEDED_TO_CHECKOUT_SELECTION'
export const SET_TIMER_EXPIRATION_TIME = 'SET_TIMER_EXPIRATION_TIME'
export const SET_EXPIRATION_TIMER_MODAL = 'SET_EXPIRATION_TIMER_MODAL'
export const SET_TIMER_EXPIRED_MESSAGE_MODAL = 'SET_TIMER_EXPIRED_MESSAGE_MODAL'
export const SET_MENU_ITEM_QUANTITY_CHANGED_MODAL = 'SET_MENU_ITEM_QUANTITY_CHANGED_MODAL'
export const SET_IS_FOR_WHOM_TO_PAY_CONFIRMED = 'SET_IS_FOR_WHOM_TO_PAY_CONFIRMED'
export const CLEAR_RESTAURANT_RESERVATION = 'CLEAR_RESTAURANT_RESERVATION'
export const SET_MENU_ACTIVE_NAME = 'SET_MENU_ACTIVE_NAME'
export const SET_MENU_ACTIVE_SUB_NAME = 'SET_MENU_ACTIVE_SUB_NAME'
export const SET_ITEMS_QUANTITY = 'SET_ITEMS_QUANTITY'
export const SET_USER_RATING = 'SET_USER_RATING'
export const CLOSE_CURRENT_RESERVATION = 'CLOSE_CURRENT_RESERVATION'
export const SET_ANCHOR_MENU = 'SET_ANCHOR_MENU'
export const SET_IS_USER_UPDATED = 'SET_IS_USER_UPDATED'
export const SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT = 'SET_CONNECT_SOCKET_AFTER_IDLE_TIMEOUT'
export const SET_REDIRECT_TO_HOME = 'SET_REDIRECT_TO_HOME'
export const SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM = 'SET_REFRESH_DATA_FOR_OPENED_MENU_ITEM'
export const SET_VERSION_CONFIG = 'SET_VERSION_CONFIG'
export const SET_CODE_LIST_VALUES = 'SET_CODE_LIST_VALUES'
export const SET_SWITCH_TO_INPUT_COMPONENT = 'SET_SWITCH_TO_INPUT_COMPONENT'
export const SET_BOOKED_RESERVATION_RETURN = 'SET_BOOKED_RESERVATION_RETURN'
export const SET_HAS_BOOKED_RESERVATION = 'SET_HAS_BOOKED_RESERVATION'
export const SET_HAS_NEW_ORDER = 'SET_HAS_NEW_ORDER'
export const SET_RESTAURANT_MENU_APPROVED = 'SET_RESTAURANT_MENU_APPROVED'
export const SET_RESTAURANT_FILTERED_MENU_BY_STEPS = 'SET_RESTAURANT_FILTERED_MENU_BY_STEPS'
export const SET_MENU_SELECTED_STEP = 'SET_MENU_SELECTED_STEP'
export const SET_TABLE_NUMBER = 'SET_TABLE_NUMBER'
export const SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED = 'SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED'
export const SET_ACTIVE_MENU_SECTION_ITEMS_WITH_ROUND_FOR_USER_APPROVED = 'SET_ACTIVE_MENU_SECTION_ITEMS_WITH_ROUND_FOR_USER_APPROVED'
export const SET_MENU_ITEM_RECOMMENDATIONS = 'SET_MENU_ITEM_RECOMMENDATIONS'
export const SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL = 'SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL'
export const SET_CLEAR_RESERVATION_ORDERS = 'SET_CLEAR_RESERVATION_ORDERS'
export const SET_MENU_DRAWER_OPENED = 'SET_MENU_DRAWER_OPENED'
export const SET_MENU_ORDERS_DRAWER_OPENED = 'SET_MENU_ORDERS_DRAWER_OPENED'
export const SET_HAS_INITIAL_MENU_OPENED = 'SET_HAS_INITIAL_MENU_OPENED'
export const SET_BOOKING_STATUS_DATA = 'SET_BOOKING_STATUS_DATA'
export const SET_BOOKING_SUCCESS = 'SET_BOOKING_SUCCESS'
export const SET_SHOW_ADD_ITEM_MODAL = 'SET_SHOW_ADD_ITEM_MODAL'
export const SET_ON_FULL_PAGE_MODAL = 'SET_ON_FULL_PAGE_MODAL'
export const SET_IS_MENU_STEPS_FEATURE_ENABLED = 'SET_IS_MENU_STEPS_FEATURE_ENABLED'