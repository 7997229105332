import React from 'react'
import OrdersRounds from '../../components/ordersRounds/OrdersRounds'
import EmptyReservation from '../../components/reservations/EmptyReservation'

const CurrentReservationPage = ({
  emitFriendSelected,
  reservationId,
  showOrdersRoundsComponent,
}) => {
  // console.log(reservationId)
  return (
    !!reservationId && showOrdersRoundsComponent ?
      <OrdersRounds
        emitFriendSelected={emitFriendSelected}
        reservationId={reservationId}
      /> :
      <EmptyReservation />
  )
}

export default CurrentReservationPage