import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getItemInfo } from '../pages/order/services/actions';
import { setRefreshDataForOpenedMenuItem } from '../store/actions/feedbackActions';

const useQuantityActions = () => {
  const dispatch = useDispatch()
  const {
    orders: { lastRoundOrders },
  } = useSelector(state => state)

  const handleItemQuantityUpdate = useCallback((menuItemId, reservationId, menuItemDetails, setMenuItemDetails) => {
    if (menuItemId && reservationId) {
      dispatch(getItemInfo(menuItemId, reservationId)).then((data) => {
        let tmpOrderItem;
        let existingItemInCurrentRoundFound = false;

        for (let i = 0; i < lastRoundOrders.length; i++) { 
          if (lastRoundOrders[i].menu_item_id === menuItemDetails.menu_item_id) {
            existingItemInCurrentRoundFound = true;
            tmpOrderItem = lastRoundOrders[i];
            break;
          }
        }

        if (!existingItemInCurrentRoundFound) {
          tmpOrderItem = { ...menuItemDetails };
          delete tmpOrderItem.order_item_id;
          delete tmpOrderItem.quantity;
          delete tmpOrderItem.description;
        }

        tmpOrderItem = {
          ...tmpOrderItem,
          inventory_status: data.inventory_status,
          remaining_quantity: data.remaining_quantity,
          reservation_selected_quantity: data?.reservation_selected_quantity || null,
          users: data?.users || [],
        };

        setMenuItemDetails(tmpOrderItem);
      });

      dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: menuItemId, refresh: false }));
    }
  }, [dispatch, lastRoundOrders])
  
  const handleGetItemInfo = (item, setItem, reservationId) => {
    if (item.menu_item_id && reservationId) {
      dispatch(getItemInfo(item.menu_item_id, reservationId)).then((data) => {
        item = {
          ...item,
          inventory_status: data.inventory_status,
          remaining_quantity: data.remaining_quantity,
          reservation_selected_quantity: data?.reservation_selected_quantity || null,
          users: data?.users || []
        }

        setItem(item)
      })

      dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: item.menu_item_id, refresh: false }))
    } else {
      setItem(item)
    }
  }

  return { handleItemQuantityUpdate, handleGetItemInfo }
}

export default useQuantityActions