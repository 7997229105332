import React from 'react'
import { useHistory } from 'react-router'
import { booking_reservation_path } from '../../config/pages_paths'
import './ManageReservation.css'

const ManageReservation2 = ({
    date,
    sizeOfParty,
    restaurant_id: restaurantId,
    reservation_id: reservationId,
}) => {
    const history = useHistory()

    const handleManageReservation = () => {
        history.push(booking_reservation_path
            .replace(':restaurantId', restaurantId)
            .replace(':reservationId', reservationId))
    }


return (
    <div className="manage-reservation-container">
        <div>
            <p>
                <strong id="reservation-pill-label">
                    Reservation:
                </strong>
                {' '}
                {date}
                {' '}
                |
                {' '}
                {sizeOfParty}
                {' '}
                {sizeOfParty > 1 ? "people" : "person"}
            </p>
        </div>


            <div
                onClick={handleManageReservation}
                className="reservation-modify-btn menage-reservation-btn">
                Manage
            </div>

    </div>
)
}

export default ManageReservation2