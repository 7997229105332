import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import BookingStatus from '../../components/book/components/BookingStatus'
import Image from '../../components/common/Image'
import SeparationLine from '../../components/common/SeparationLine'
import errorMessages from '../../components/messages/errorMessages'
import ReservationOrders from '../../components/ordersRounds/ReservationOrders'
import ReservationEditButtons from '../../components/reservation/ReservationEditButtons'
import ReservationInfoLabel from '../../components/reservation/ReservationInfoLabel'
import ReservationTableNumber from '../../components/reservation/ReservationTableNumber'
import '../../components/reservation/buttons/Buttons.css'
import { cancel_reservation_modal } from '../../config/modal_paths'
import {
  booking_order_page_path,
  booking_page_path,
  booking_reservation_path,
  reservations_path_now
} from '../../config/pages_paths'
import Column from '../../containers/layout/Column'
import Row from '../../containers/layout/Row'
import { orderStatusEnum } from '../../enums/orderStatusEnum'
import useFloatingIslandActions from '../../hooks/useFloatingIslandActions'
import useSocket from '../../hooks/useSocket'
import { setActiveModal, setMenuItemQuantityChangedModal, setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { copyToClipboard } from '../../utils/copyToClipboard'
import { BookingSuccess } from '../book/BookingSuccessPage'
import {
  getInviteMessage,
  getReservationDetails,
  setBookingStatusData,
  setBookingSuccess,
  setInviteMessage,
  setReservationDetails,
  setReservationId,
  startBookedOrder,
} from '../book/services/actions'
import { getLastRoundOrders, getOrders, getOrdersNoRounds, getOrdersRounds } from '../order/services/actions'
import {
  getCurrentReservation,
  getReceipt
} from '../reservations/services/actions'
import Modal from '../../components/modal/Modal'
import ReturnToReservationModal from './ReturnToReservationModal'
import { initialReservationDetails } from '../book/services/reducers'
// import './ReservationPage.css'

function ReservationPage({ reservationIdProp }) {
  const {
    booking: {
      reservationDetails: {
        reservation,
        reservationOrders,
        userPayments,
        reservationGuests },
      reservations,
      // initReservationType,
      switchToInputComponent,
      bookingStatusData: {
        isCurrentUserHost,
        numberOfGuestsOnReservation,
        numberOfGuestsWithAcceptedInvitations,
        numberOfGuestsWithOrders,
        canStartReservation,
      }
    },
    auth: { user },
    feedback: { connectSocketAfterIdleTimeout, refreshDataForOpenedMenuItem },
    restaurant: { restaurant },
    booking: { inviteMessage, bookingSuccess },
    versionConfig: { show_booking_flow }
  } = useSelector(state => state)



  const dispatch = useDispatch()
  const { reservationId } = useParams()
  const history = useHistory()
  const location = useLocation()

  const [isInviteCopied, setInviteCopied] = useState(false)
  const [anyOrderPaidByLoggedInUser, setAnyOrderPaidByLoggedInUser] = useState(false)
  // const [reservationType, setReservationType] = useState(initReservationType)
  const [receiptURL, setReceiptURL] = useState('')
  const [isActiveReservation, setIsActiveReservation] = useState(false)
  const [isBookingManagePath, setIsBookingManagePath] = useState(false)


  const onModifyClickHandler = useCallback(() => {
    if (reservation?.reservation_id === +reservationId && reservation?.manage_reservation_url) {
      const modifyWindow = window.open(reservation?.manage_reservation_url, '_blank', 'noopener,noreferrer')
      modifyWindow && modifyWindow.focus()
    }
  }, [reservation, reservationId])

  useEffect(() => {
    const bookingManagePath = location.pathname.split('/')[1] === booking_reservation_path.split('/')[1]
    setIsBookingManagePath(bookingManagePath)
  },[location])

  useEffect(() => {
    if (reservationId) {
      dispatch(getReservationDetails(+reservationId, history))
    }

    return () => {
      dispatch(setReservationDetails(initialReservationDetails))
    }
  }, [dispatch, reservationId, history])

  useEffect(() => {
    // check if any user's order is paid by himself
    // if there is none, hide the receipt button
    // all orders have been paid by other users
    if (reservation.has_receipt) {
      setAnyOrderPaidByLoggedInUser(true)
    }
  }, [reservationOrders, reservation.has_receipt])

  useEffect(() => {
    if (!isEmpty(reservationId) && !isEmpty(reservation.order_status) &&
      reservation?.order_status === orderStatusEnum.booked &&
      !reservation.is_closed && reservation?.size_of_party > 1) {
      dispatch(getInviteMessage(reservationId))
    }
  }, [dispatch, reservation, reservationId])

  useEffect(() => {
    //Return to this


    if (isBookingManagePath) {
      const userRoleInReservation = reservationGuests.find(guest => guest.user_id === user.id)
      if (userRoleInReservation?.user_type === 'HOST' && reservation?.order_status === orderStatusEnum.booked && (reservation?.size_of_party < reservationGuests?.length)) {
        const modalMessage = {
          heading: "Modify the reservation!",
          message: <>
            <span style={{ marginBottom: "1rem" }}>{errorMessages.moreGuestsThanBookedSizeParty}</span>
          </>
        }

        const extraButton = {
          name: "Modify",
          functionality: () => {
            onModifyClickHandler()
          }
        }

        dispatch(setMenuItemQuantityChangedModal({ show: true, message: modalMessage, extraButton: extraButton }))
      }
    }
  }, [dispatch, reservation, reservationGuests, user.id, location.pathname, onModifyClickHandler,])

  const onInviteClickHandler = () => {
    copyToClipboard(inviteMessage)
    setInviteCopied(true)
  }

  const onCancelInvitationClickHandler = () => {
    dispatch(setReservationId(reservationId))
    dispatch(setActiveModal(cancel_reservation_modal))
  }



  const onRebookClick = () => {
    history.push(
      booking_page_path.replace(':restaurantId', reservation.restaurant_id)
    )
  }

  const onReceiptClick = () => {
    dispatch(getReceipt(reservationId))
  }

  const onMenuNavigationClickHandler = () => {
    history.push(booking_order_page_path.replace(':reservationId', reservation.reservation_id).replace(':reservationId', reservation.reservation_id))
    // console.log(reservation)
  }

  const onStartOrderClickHandler = (tableNumber) => {
    dispatch(startBookedOrder(tableNumber, reservationId, reservations, history))
  }

  // useEffect(() => {
  //   if (responseMessage) history.push(reservations_path_now)
  // }, [history, responseMessage])

  useEffect(() => {
    return () => {
      setInviteCopied(false)
      dispatch(setInviteMessage(''))
    }
  }, [dispatch])

  useEffect(() => {
    if (reservationId && reservation.is_closed) {
      dispatch(getReceipt(reservationId, setReceiptURL))
    }
  }, [reservationId, dispatch, reservation])

  // const [dateString, time] = reservation.date_string
  //   ? reservation.date_string.split('T')
  //   : []

  const onStartBookedOrder = useCallback((reservationId) => {
    history.push(reservations_path_now.replace(':reservationId', reservationId))
  }, [history])

  const onRefresh = useCallback(
    reservation_id => {
      if (reservation_id) {
        dispatch(getCurrentReservation())
        dispatch(getOrders(reservation_id))
        dispatch(getOrdersRounds(reservation_id))
        dispatch(getOrdersNoRounds(reservation_id))
        dispatch(getLastRoundOrders(reservation_id))
        dispatch(getReservationDetails(+reservation_id, history))

        if (refreshDataForOpenedMenuItem.menuItemId) {
          dispatch(setRefreshDataForOpenedMenuItem({ menuItemId: refreshDataForOpenedMenuItem.menuItemId, refresh: true }))
        }
      }
    },
    [dispatch, refreshDataForOpenedMenuItem.menuItemId, history]
  )

  const onGuestsPresenceChanged = useCallback((reservationId) => {
    dispatch(getReservationDetails(+reservationId, history))
  }, [dispatch, history])


  const [showInputField, setShowInputField] = useState(false);

  // useEffect(() => {
  //   if (switchToInputComponent?.reservationId === +reservationId) {
  //     setShowInputField(switchToInputComponent?.value);
  //   }
  //   return (
  //     setShowInputField(false)
  //   )
  // }, [dispatch, switchToInputComponent, reservationId]);

  useEffect(() => {
    const matchingReservation = switchToInputComponent.find(item => item?.reservationId === +reservationId);

    if (matchingReservation) {
      setShowInputField(matchingReservation?.value);
    }

    return () => {
      setShowInputField(false);
    };
  }, [switchToInputComponent, reservationId]);

  useEffect(() => {

    if (user && reservation && isBookingManagePath) {
      dispatch(setBookingStatusData({
        user,
        reservationDetails: {
          reservation,
          reservationGuests,
          reservationOrders,
        }
      }))
    }
  }, [dispatch, user, reservation, reservationGuests, reservationOrders, location])

  useEffect(() => {
    return () => dispatch(setBookingSuccess(false))
  }, [dispatch])

  useSocket({
    reservationId: reservationId,
    shouldConnect: true && !connectSocketAfterIdleTimeout,
    onStartBookedOrder,
    onGuestsPresenceChanged,
    onRefresh
  })

  return (
    <Row>
      <Column className="text-center" md={6} lg={4}>
        <div className='reservation-restaurant-image-container'>
          <Image
            src={reservation.restaurant_image}
            className="restaurant-image"
            restaurant
            style={{ borderRadius: "1rem", objectFit: "cover" }}
          />
        </div>
        <div className="booking-restaurant-heading mx-auto">
          {reservation.name}
        </div>
        <div className="booking-restaurant-address mx-auto mb-1">
          {reservation.address}
        </div>
        <div className="booking-reservation-info mb-4">
          <div className="reservation-info-container">
            <div className="reservation-info">
              {reservation.is_walk_in ? reservation.date_string : reservation.booking_date}  {reservation.size_of_party ? `| ${reservation.size_of_party}${' person'}` : null}{reservation.size_of_party > 1 ? 's' : ''}
              {reservation.is_closed ? `| Table: ${reservation.table_number}` : ''}
            </div>
          </div>
        </div>
        {!reservation.is_closed ?
          <SeparationLine /> :
          null
        }
        {
          !!bookingSuccess && reservation.order_status === orderStatusEnum.booked && !reservation.is_closed ?
            <>
              <BookingSuccess
                // onInviteClick={onInviteClick}
                // onNextClick={onNextClick}
                isInviteCopied={isInviteCopied}
                sizeOfParty={reservation.size_of_party}
              />
              <SeparationLine />
            </> :
            null
        }
        <Row>
          <Column col={12}>
            {reservation.order_status === orderStatusEnum.booked && (
              <ReservationInfoLabel
                // label="Do not scan the QR code on the table, please enter it manually"
                className="reservation-buttons-container"
                info={
                  <ReservationEditButtons
                    isInviteCopied={isInviteCopied}
                    onInviteClick={onInviteClickHandler}
                    onCancelInvitationClick={onCancelInvitationClickHandler}
                    onMenuNavigationClick={onMenuNavigationClickHandler}
                    onModifyClick={onModifyClickHandler}
                    sizeOfParty={reservation.size_of_party}
                    reservationGuest={reservationGuests.find(guest => guest.user_id === user.id)}
                  />
                }
                bookingStatus={
                  <BookingStatus
                    isCurrentUserHost={isCurrentUserHost}
                    numberOfGuestsOnReservation={numberOfGuestsOnReservation}
                    numberOfGuestsWithAcceptedInvitations={numberOfGuestsWithAcceptedInvitations}
                    numberOfGuestsWithOrders={numberOfGuestsWithOrders}
                    canStartReservation={canStartReservation}
                    isBookingConfirmed={reservation.is_booking_confirmed}
                  />}
                remainder={'On arrival please inform your hostess that you booked through Voila'}
              // table={
              //   <ReservationTableNumber
              //     reservation={reservation}
              //     cb={onStartOrderClickHandler}
              //     showInputField={showInputField}
              //     hasOpenTableReservationId={!isEmpty(reservation?.opentable_reservation_id)}
              //   />
              // }
              />
            )}
            {(reservation.order_status === orderStatusEnum.noshow || reservation.order_status === orderStatusEnum.autoClosed) && (
              <div style={{ margin: "1rem 0" }}>
                {errorMessages.reservationNoShow}
                <SeparationLine />
              </div>
            )}

            {reservation.order_status === orderStatusEnum.cancelled &&
              (
                <div style={{ margin: "1rem 0" }}>
                  {reservation?.closed_by_user_id === user.id ?
                    errorMessages.reservationCancelledByGuest :
                    errorMessages.reservationCancelled
                  }
                  <SeparationLine />
                </div>
              )
            }

            {reservation.order_status === orderStatusEnum.orderManuallyClosed && (
              <div style={{ margin: "1rem 0" }}>
                {errorMessages.reservationManuallyClosed}
                <SeparationLine />
              </div>
            )}

            {reservation.order_status === orderStatusEnum.orderExpired && (
              <div style={{ margin: "1rem 0" }}>
                {errorMessages.reservationExpired}
                <SeparationLine />
              </div>
            )}

            {(reservation.order_status === orderStatusEnum.orderPaid || reservation.order_status === orderStatusEnum.orderPaidPOS) && (
              <PastReservationButtons
                isInviteCopied={isInviteCopied}
                onRebookClick={onRebookClick}
                onReceiptClick={onReceiptClick}
                receiptURL={receiptURL}
                showReceiptButton={anyOrderPaidByLoggedInUser}
                reservationDate={new Date(+reservation.date).toLocaleString({ hour12: true })}
                restaurantName={restaurant.name}
                isWalkIn={reservation.is_walk_in}
                showBookingFLow={show_booking_flow}
              />
            )}
          </Column>
        </Row>
      </Column>

      <Column md={6} lg={4}>
        <ReservationOrders
          reservation_id={reservationId}
          canOrder={!reservation.is_closed}
        />
        {
          !isEmpty(userPayments) && (
            <div style={{ maxWidth: '450px', margin: '0 auto 6rem', padding: '0 0.5rem' }}>
              {
                userPayments.discount_percentage && (
                  <>
                    <div className='d-flex justify-content-between'>
                      <p> {userPayments.discount_percentage}% check discount: </p>
                      <p> &#8722; ${userPayments.discount_amount_sum} </p>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p> Pre-discount subtotal: </p>
                      <p> ${userPayments.sub_total_sum} </p>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p> Discount total: </p>
                      <p> &#8722; ${userPayments.discount_amount_sum} </p>
                    </div>
                  </>
                )
              }

              <div className='d-flex justify-content-between'>
                <p> Subtotal: </p>
                {userPayments.discount_percentage
                  ? <p> ${(userPayments.sub_total_sum - parseFloat((userPayments.sub_total_sum * userPayments.discount_percentage / 100).toFixed(2))).toFixed(2)} </p>
                  : <p> ${userPayments.sub_total_sum} </p>
                }
              </div>

              <div className='d-flex justify-content-between'>
                <p> Platform charge: </p>
                <p> ${userPayments.platform_fee_sum} </p>
              </div>

              {reservation.reservation_is_tip_enabled && (
                <div className='d-flex justify-content-between'>
                  <p> Tip: </p>
                  <p> ${userPayments.tip_sum} </p>
                </div>
              )}

              <div className='d-flex justify-content-between'>
                <p> Tax: </p>
                <p> ${userPayments.tax_sum} </p>
              </div>

              <div className='d-flex justify-content-between'>
                <p><b> Total: </b></p>
                <p><b> ${userPayments.total_sum} </b></p>
              </div>
            </div>
          )
        }
        {
          reservation &&
          reservation.order_status === orderStatusEnum.booked &&
          !reservation.is_closed &&
          isBookingManagePath &&
          <>
            <SeparationLine width={'100%'} />
            <ReservationTableNumber
              reservation={reservation}
              cb={onStartOrderClickHandler}
              showInputField={showInputField}
              hasOpenTableReservationId={!isEmpty(reservation?.opentable_reservation_id)}
            />
          </>
        }

        {!isEmpty(reservation) && !reservation.is_closed &&
          (reservation.order_status === orderStatusEnum.orderSent ||
            reservation.order_status === orderStatusEnum.orderStarted) &&
            isBookingManagePath &&
          <ReturnToReservationModal
            show={!reservation.is_closed &&
              (reservation.order_status === orderStatusEnum.orderSent ||
                reservation.order_status === orderStatusEnum.orderStarted)}
            close={() => history.push(reservations_path_now)}
            title={'Invalid session'}
            description={'Your booking is already started.'}
          />
        }
      </Column>
    </Row>
  )
}

function PastReservationButtons({ onRebookClick, onReceiptClick, showReceiptButton, receiptURL, reservationDate, restaurantName, isWalkIn, showBookingFLow }) {
  const {
    createButtonPortal, createDownloadPortal,
    rebook, receipt,
    DOMslot1, DOMslot2,
  } = useFloatingIslandActions()

  return (
    <>
      <div className="actions-btns-mask" />
      <div
        className="restaurant-actions-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {showBookingFLow && !isWalkIn && (
          <>
            {/* <button
              style={{ marginRight: '14px' }}
              className="btn btn-primary big-round-btn"
              onClick={onRebookClick}
            >
              <span>Re-book</span>
            </button> */}

            {createButtonPortal(rebook(onRebookClick), DOMslot2)}
          </>
        )

        }
        {showReceiptButton && (
          <>
            {/* <button
              className="btn btn-primary big-round-btn"
              disabled={!receiptURL}
            >
              {receiptURL ? <a href={receiptURL} download={`Voila-${restaurantName}-${reservationDate}.pdf`}>Receipt</a> : <span>Loading</span>}
            </button> */}

            {createDownloadPortal(receipt(receiptURL, `Voila-${restaurantName}-${reservationDate}`), DOMslot1)}
          </>
        )}
      </div>
    </>
  )
}

export default ReservationPage
