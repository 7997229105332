import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import NewCardForm from "./NewCardForm";
import PresentCardsInVault from "./PresentCardsInVault";
import { card_types_enum } from "../enums";
import { getPaymentMethodsForUser, updatePaymentMethodForUser } from "../../../../pages/order/services/actions";
import { autoScrollTo } from "../../../ordersRounds/helpers/autoSrollTo";

const SpreedlyLogic = ({ cb, onPayment, isEditMode = false }) => {
  const dispatch = useDispatch()

  const {
    user: { user: userDetails, },
    orders: { paymentMethods },
    // auth: { user: { is_anonymous: isAnonymous } },
    errors
  } = useSelector(state => state)

  const [spreedlyToken, setSpreedlyToken] = useState('')
  const [cardType, setCardType] = useState('undefined')
  const [cardTypeForBackend, setCardTypeForBackend] = useState('')
  const [last4Digits, setLast4Digits] = useState('')
  const [expDate, setExpDate] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [isCcNumberValid, setIsCcNumberValid] = useState(false)
  const [isCvvValid, setIsCvvValid] = useState(false)
  const [isExpDateValid, setIsExpDateValid] = useState(false)
  const [isZipCodeValid, setIsZipCodeValid] = useState(false)
  const [isCcNumberTouched, setIsCcNumberTouched] = useState(false)
  const [isCvvTouched, setIsCvvTouched] = useState(false)
  const [isExpDateTouched, setIsExpDateTouched] = useState(false)
  const [isZipCodeTouched, setIsZipCodeTouched] = useState(false)
  const [isCardFirstTime, setIsCardFirstTime] = useState(false)
  const [isAddNewMethod, setIsAddNewMethod] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [showAddNewCard, setShowAddNewCard] = useState(false)
  const [editMode, setEditMode] = useState(isEditMode)
  const [selectedCard, setSelectedCard] = useState(0)
  const [activePaymentMethodIndex, setActivePaymentMethodIndex] = useState(0)
  const cancelEditingCardRef = useRef(null)

  useEffect(() => {
    dispatch(getPaymentMethodsForUser())
  }, [dispatch])

  useEffect(() => {
    if (isAddNewMethod) {
      // TODO: Replace hard-coded data
      window.Spreedly.init('QqhRds4bCwhLPtCzbEpWzY4FclD', {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv'
      })
    }
  }, [isAddNewMethod])

  window.Spreedly.on('ready', () => {
    // Set style
    const inputFormStyle = 'height: 35px; font-size: 16px; width: 100%; margin-left: 10px;'

    window.Spreedly.setStyle('number', inputFormStyle)
    window.Spreedly.setStyle('cvv', inputFormStyle)
    window.Spreedly.setPlaceholder('number', '•••• •••• •••• ••••')
    window.Spreedly.setPlaceholder('cvv', '•••')
    window.Spreedly.setFieldType('number', 'tel')
    window.Spreedly.setNumberFormat('prettyFormat')
  })

  useEffect(() => {
    window.Spreedly.on(
      'paymentMethod',
      (token, { card_type, last_four_digits }) => {
        setIsCardFirstTime(true)
        if (isEmpty(spreedlyToken)) setSpreedlyToken(token)
        setCardTypeForBackend(card_type)
        setLast4Digits(last_four_digits)
        setCanContinue(true)
      }
    )
  }, [spreedlyToken])

  useEffect(() => {
    if (
      !isEmpty(spreedlyToken) &&
      !isEmpty(cardTypeForBackend) &&
      !isEmpty(last4Digits) &&
      canContinue
    ) {
      cb({
        spreedlyInformation: {
          token: spreedlyToken,
          cardType: cardTypeForBackend,
          last4Digits: last4Digits,
          isFirstTime: isCardFirstTime,
          isSelected: true,
        }
      })
    }
  }, [spreedlyToken, cardTypeForBackend, last4Digits, isCardFirstTime, canContinue, zipCode, cb])

  useEffect(() => {
    window.Spreedly.on('fieldEvent', function (name, type, activeEl, { cardType, validNumber, validCvv }) {
      if (activeEl === 'number' && name === 'number') {
        setIsCcNumberTouched(true)
        if (validNumber !== undefined) {
          setIsCcNumberValid(validNumber)
          setCardType(cardType)
        }
      }
      if (activeEl === 'cvv' && name === 'cvv') {
        setIsCvvTouched(true)
        if (validCvv !== undefined) {
          setIsCvvValid(validCvv)
        }
      }
    })
  }, [])

  useEffect(() => {
    window.Spreedly.on('validation', function (inputProperties) {
      setIsCcNumberValid(inputProperties["validNumber"])
      setIsCvvValid(inputProperties["validCvv"])
      expDate.split('/').length < 2 ? setIsExpDateValid(false) : setIsExpDateValid(true)
      setIsZipCodeValid(checkIfZipCodeValid(zipCode))
    });
  }, [expDate, zipCode])

  useEffect(() => {
    if (errors.payment) {
      setCanContinue(false)
    }
  }, [errors])

  useEffect(() => {
    paymentMethods.length === 0 ? setIsAddNewMethod(true) : setIsAddNewMethod(false)
    setActivePaymentMethodIndex(paymentMethods?.findIndex(method => method.isSelected))
  }, [paymentMethods])

  useEffect(() => {
    if (isAddNewMethod) {
      setZipCode('')
    }
  }, [isAddNewMethod])

  const submitForm = () => {
    const month = expDate?.split('/')[0]
    const year = expDate?.split('/')[1]
    const requiredFields = {
      full_name: `${userDetails.first_name || 'firstNamePlaceholder'} ${userDetails.last_name || 'lastNamePlaceholder'}`,
      month: month,
      year: transformToFourDigitYear(year)
    }

    const optionalFields = {
      zip: transformZipCode(zipCode)
    }

    window.Spreedly.tokenizeCreditCard({ ...requiredFields, ...optionalFields })
  }

  const transformToFourDigitYear = (year) => {
    return +(`20${year}`)
  }

  const transformZipCode = (zipCode) => {
    return zipCode.length === 4 ? `${zipCode}0` : zipCode
  }

  const onExpDateChange = e => {
    e.preventDefault()
    setIsExpDateTouched(true)
    const month = +e.target.value.split('/')[0]
    const year = transformToFourDigitYear(e.target.value.split('/')[1])

    if (
      (month &&
        month >= 0 &&
        typeof month === 'number' &&
        year &&
        year > 0 &&
        typeof year === 'number') ||
      +e.target.value >= 0 || +e.target.value.length !== 2
    ) {
      setExpDate(e.target.value)
    }
    onkeydown = event => {
      if (event.target.id === 'exp-date') {
        if (+event.target.value.length === 2) {
          setExpDate(`${event.target.value}/`);
        }

        if (event.keyCode === 8 && event.target.value === expDate) {
          let val = event.target.value.slice(0, -1)
          event.target.value = val;
          setExpDate(`${event.target.value}`);
        }
      }
    }

    // set Errors
    const currentYear = new Date().getFullYear()

    // Can be simplified, use the condition in if statement to set state
    if (
      (!isNaN(month) && (month < 1 || month > 12)) ||
      (!isNaN(year) && (year < currentYear || year > 2030)) ||
      month.length < 2 ||
      year.length < 4
    ) {
      setIsExpDateValid(false)
    } else {
      setIsExpDateValid(true)
    }
  }

  const onZipCodeChange = (e) => {
    setIsZipCodeTouched(true)
    setZipCode(e.target.value)
    setIsZipCodeValid(checkIfZipCodeValid(e.target.value))
  }

  const checkIfZipCodeValid = (zipCode) => {
    return zipCode.length >= 4
  }

  const isNewCardFormValid = () => {
    if (!isAddNewMethod) return true
    return isCvvValid && isExpDateValid && isCcNumberValid && isZipCodeValid
  }

  const onSaveActiveCard = (successMsg) => {
    paymentMethods[selectedCard] &&
    !paymentMethods[selectedCard]?.isSelected &&
    dispatch(updatePaymentMethodForUser(paymentMethods[selectedCard].token, successMsg))
  }

  const onSaveCard = e => {
    if (isAddNewMethod) window.Spreedly.validate();
    isNewCardFormValid() && (isAddNewMethod && !canContinue ? submitForm(e) : setCanContinue(true))
    onSaveActiveCard(false)
  }

  const isPaymentVaultEmpty = () => {
    return !(paymentMethods.length > 0)
  }

  const onEdit = useCallback(() => {
    setShowAddNewCard(false)
    setIsAddNewMethod(false)
    setEditMode(prevState => !prevState)
    autoScrollTo(cancelEditingCardRef)
  }, [setShowAddNewCard, setIsAddNewMethod, setEditMode])

  const onAddNewPaymentMethod = () => {
    setShowAddNewCard(true)
    setIsAddNewMethod(true)
  }

  const showNewCardInformation = () => {
    return (
      <NewCardForm
        cardType={cardType}
        onExpDateChange={onExpDateChange}
        expDate={expDate}
        card_types_enum={card_types_enum}
        isCcNumberValid={isCcNumberValid}
        isCvvValid={isCvvValid}
        isExpDateValid={isExpDateValid}
        zipCode={zipCode}
        isZipCodeValid={isZipCodeValid}
        onZipCodeChange={onZipCodeChange}
        isCcNumberTouched={isCcNumberTouched}
        isCvvTouched={isCvvTouched}
        isExpDateTouched={isExpDateTouched}
        isZipCodeTouched={isZipCodeTouched}

      />
    )
  }

  const showCreditCardVault = () => (
    <div className="spreedly-checkout-vault-container">
      {!isPaymentVaultEmpty() ? (
        <PresentCardsInVault
          paymentMethods={paymentMethods}
          setCardTypeForBackend={setCardTypeForBackend}
          setSpreedlyToken={setSpreedlyToken}
          setLast4Digits={setLast4Digits}
          setIsAddNewMethod={setIsAddNewMethod}
          setShowAddNewCard={setShowAddNewCard}
          editMode={editMode}
          isAddNewMethod={isAddNewMethod}
          onEdit={onEdit}
          onAddNewPaymentMethod={onAddNewPaymentMethod}
          activePaymentMethodIndex={activePaymentMethodIndex}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          onSaveActiveCard={onSaveActiveCard}
        />
      ) : (
        showNewCardInformation()
      )}
      {showAddNewCard ? (
        showNewCardInformation()
      ) : null}
    </div>
  )

  return {
    showNewCardInformation,
    showCreditCardVault,
    setIsAddNewMethod,
    isAddNewMethod,
    isNewCardFormValid,
    isPaymentVaultEmpty,
    onSaveCard,
    onEdit,
    editMode,
    cancelEditingCardRef
  }
}

export default SpreedlyLogic
