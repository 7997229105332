import { useCallback } from "react";

const useEventHandler = (handler) => useCallback(
    (...args) => handler && handler(...args),
    [handler]
);
  
const useSocketEventHandlers = ({
onRefresh,
refreshExpirationToken,
onRefreshWithoutResetingIsPay,
onOtherPaid,
onPaymentExpired,
onOtherTryingToPay,
onOrderClosed,
refreshReservations,
orderExpired,
onMenuItemQuantityChanged,
onGuestsHaveSelectedMoreQuantityThanItemHas,
onMenuItemIsOutOfStock,
onStartBookedOrder,
onUserInfoChanged,
onGuestsPresenceChanged,
reservationId
}) => {
    const handleOrderModified = useEventHandler(() => {
        onRefresh && onRefresh(reservationId);
        refreshExpirationToken && refreshExpirationToken(reservationId);
    });

    const handleOrderModifiedWithoutReset = useEventHandler(() => {
        onRefreshWithoutResetingIsPay && onRefreshWithoutResetingIsPay(reservationId)
    });

    const handlePaymentMade = useEventHandler((data) => {
        onOtherPaid && onOtherPaid(data)
        onRefreshWithoutResetingIsPay && onRefreshWithoutResetingIsPay(reservationId)
    });

    const handlePaymentExpired = useEventHandler((data) => {
        onPaymentExpired && onPaymentExpired(data)
    });

    const handlePayForOthers = useEventHandler((data) => {
        onOtherTryingToPay && onOtherTryingToPay(data);
    });

    const handleOrderClosed = useEventHandler(() => {
        onOrderClosed && onOrderClosed(reservationId);
        onRefresh && onRefresh(reservationId);
    });

    const handleRefreshExpirationToken = useEventHandler(() => {
        refreshExpirationToken && refreshExpirationToken(reservationId);
    });

    const handleOrderExpired = useEventHandler(() => {
        refreshReservations && refreshReservations();
        orderExpired && orderExpired();
    });

    const handleMenuItemQuantityChanged = useEventHandler((data) => {
        onMenuItemQuantityChanged && onMenuItemQuantityChanged(data);
        onRefresh && onRefresh(reservationId);
    });

    const handleGuestsHaveSelectedMoreQuantity = useEventHandler((data) => {
        onGuestsHaveSelectedMoreQuantityThanItemHas && onGuestsHaveSelectedMoreQuantityThanItemHas(data);
    });

    const handleMenuItemOutOfStock = useEventHandler((itemsNames) => {
        onMenuItemIsOutOfStock && onMenuItemIsOutOfStock(itemsNames);
        onRefresh && onRefresh(reservationId);
    });

    const handleStartBookedOrder = useEventHandler(() => {
        onStartBookedOrder && onStartBookedOrder(reservationId);
    });

    const handleUserInfoChanged = useEventHandler((data) => {
        onUserInfoChanged && onUserInfoChanged(data);
    });

    const handleGuestsPresenceChanged = useEventHandler(() => {
        onGuestsPresenceChanged && onGuestsPresenceChanged(reservationId);
    });

    return {
        handleOrderModified,
        handleOrderModifiedWithoutReset,
        handlePaymentMade,
        handlePaymentExpired,
        handlePayForOthers,
        handleOrderClosed,
        handleRefreshExpirationToken,
        handleOrderExpired,
        handleMenuItemQuantityChanged,
        handleGuestsHaveSelectedMoreQuantity,
        handleMenuItemOutOfStock,
        handleStartBookedOrder,
        handleUserInfoChanged,
        handleGuestsPresenceChanged
    };
};
  
export default useSocketEventHandlers;