import {
  SET_MENU_ACTIVE_NAME,
  SET_MENU_ACTIVE_SUB_NAME,
  SET_REDIRECT_TO_HOME
} from '../../../config/actionTypes'

const initialState = {
  menuActiveName: '',
  menuSectionActiveName: '',
  redirectToHome: false,
}

const navigationReducer =  (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_ACTIVE_NAME:
      return { ...state, menuActiveName: action.payload }
    case SET_MENU_ACTIVE_SUB_NAME:
      return { ...state, menuSectionActiveName: action.payload }
    case SET_REDIRECT_TO_HOME:
      return { ...state, redirectToHome: action.payload }
    default:
      return state
  }
}

export default navigationReducer;
