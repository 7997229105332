import React from 'react';
import { useSelector } from 'react-redux';
import UserOrders from './UserOrders';

// const orderFromMenuBtn = {
//   width: '190px',
//   cursor: 'pointer'
// }

// const orderFromMenuBtnText = {
//   width: '150px',
//   cursor: 'pointer'
// }

function Round({
  tableNumber,
  roundLabel,
  users,
  round,
  onPaymentCheckboxChange,
  payForUsers = [],
  canPay,
  canOrder,
  onOrderFromMenuClick,
  reservation_id,
  avatarColors,
  roundPosition,
  showAddItemsFromMenu,
}) {
  const {
    auth: {
      user: { id }
    }
  } = useSelector(state => state)
  return (
    <div className="round-wrapper">
      {/* <h4>
        {roundLabel} {tableNumber !== -1 && !isEmpty(tableNumber) && `on table ${tableNumber}`}
      </h4> */}
      {/* {(!round && canOrder) || (showAddItemsFromMenu && roundPosition === 0) ? (
        <CustomButton
          style={orderFromMenuBtn}
          textStyle={orderFromMenuBtnText}
          wrapperClassName=" mx-auto reservation-btn-wrapper"
          textClassName="reservation-edit-btn-text"
          text="Add item from menu"
          onClick={onOrderFromMenuClick}
        />
      ) : null} */}
      <ul>
        {users.map((user, idx) => {
          return (
            <UserOrders
              key={idx}
              orderPosition={idx}
              roundPosition={roundPosition}
              is_archived={user.is_archived}
              round={round}
              canPay={canPay}
              canOrder={canOrder}
              onPaymentCheckboxChange={() =>
                onPaymentCheckboxChange({userId: user.user_id})
              }
              isPaymentChecked={
                !!payForUsers.find(userId => +userId === +user.user_id)
              }
              areOwnOrders={id === user.user_id}
              avatarBackground={
                user.is_archived ? ARCHIVED_USER_COLOR : avatarColors[idx]
              }
              {...user}
              userDisplayName={id === +user.user_id && user.user}
              firstLetter={
                user.is_archived
                  ? '?'
                  // : user.is_anonymous
                  // ? `${user.user.charAt(0).toUpperCase()}${user.user.charAt(user.user.length - 1).toUpperCase()}`
                  : user.user.charAt(0).toUpperCase()
              }
              reservation_id={reservation_id}
              currentUserId={user.user_id}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default Round

export const ARCHIVED_USER_COLOR = 'rgb(128,128,128, 0.6)'
