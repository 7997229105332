import Row from '../../containers/layout/Row'
import Column from '../../containers/layout/Column'
import ContactForm from '../../components/common/ContactForm'

function ContactPage() {
  return (
    <Row>
      <Column className="terms-column text-left" md={12} lg={12}>
        <section>
          <div className="text-center ">
            <h3>Support</h3>
            <p>
              For any problem regarding the quality of the food or the dining
              experience in our listed restaurant partners, please discuss with
              the restaurant staff.
            </p>
            <p>For any problem with the app feel free to reach out to us on:</p>
            <p>
              <strong>support@voila.nyc</strong>
            </p>
          </div>
          <ContactForm />
        </section>
      </Column>
    </Row>
  )
}

export default ContactPage
