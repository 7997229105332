import { SET_USER_DETAILS, SET_UPDATE_USER_RECEIPT, SET_IS_PAY, SET_IS_USER_UPDATED,SET_IS_PROCEEDED_TO_CHECKOUT_SELECTION, SET_NEW_USER, SET_NEW_USER_MAIL } from '../../config/actionTypes'

const initialState = {
  user: {},
  updateUserReceipt: false,
  isPay: false,
  isProceededToCheckoutSelection: false,
  isUpdated: false,
  newUser: {},
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return { ...state, user: action.payload }
    case SET_UPDATE_USER_RECEIPT:
      return { ...state, updateUserReceipt: action.payload }
    case SET_IS_PAY:
      return { ...state, isPay: action.payload }
    case SET_IS_USER_UPDATED:
      return { ...state, isUpdated: action.payload }
    case SET_IS_PROCEEDED_TO_CHECKOUT_SELECTION:
      return { ...state, isProceededToCheckoutSelection: action.payload }
    case SET_NEW_USER:
      return {...state, newUser: action.payload}
    case SET_NEW_USER_MAIL:
      return {...state, newUser: {
        ...state.newUser,
        email: action.payload
      }}
    default:
      return state
  }
}

export default usersReducer