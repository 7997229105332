import isEmpty from 'lodash.isempty'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import OrderingNotAvailableModal from '../../components/orders/OrderingNotAvailableModal'
import {
    booking_order_page_path,
    home_path,
    order_page_path,
    past_order_page_path
} from '../../config/pages_paths'
import useSocket from '../../hooks/useSocket'
import { setRefreshDataForOpenedMenuItem } from '../../store/actions/feedbackActions'
import { getReservationDetails } from '../book/services/actions'
import RestaurantMenuApproved from '../menu/RestaurantMenuApproved'
import ReservationPage from '../reservation/ManageBookingPage'
import BookingPageWrapper from '../reservation/BookingPageWrapper'
import {
    getCurrentReservation
} from '../reservations/services/actions'
import {
    setModifiersForMenuItem,
    setSelectedModifiersForOrderItem
} from '../restaurant/services/actions'
import './OrderPage.css'
import {
    addItemToOrder,
    getLastRoundOrders
} from './services/actions'

const PastOrderPage = () => {
    const dispatch = useDispatch()
    const [connectSocket, setConnectSocket] = useState(true)
    const [showAddItemModal, setShowItemModal] = useState(false)
    const { reservationId } = useParams()

    const history = useHistory()
    const location = useLocation()

    // useEffect(() => {
    //     if (reservationId === 'undefined' || reservationId === undefined) {
    //         history.push(home_path)
    //     }
    // }, [reservationId, history])

    useEffect(() => {
        // if (reservationId)
        dispatch(getReservationDetails(+reservationId, history))
    }, [dispatch, reservationId, history])


    // useEffect(() => {
    //   if (!isEmpty(reservationDetails?.reservation)) {
    //     dispatch(getFullMenu(reservationDetails.reservation.restaurant_id, reservationDetails.reservation.reservation_id, false, history))
    //   }
    // }, [dispatch, reservationDetails, history])

    useEffect(() => {
        if (reservationId) {
            dispatch(getCurrentReservation())
            // dispatch(getOrders(reservationId))
            // dispatch(getRestaurants())
            // dispatch(getReservations())
        }
    }, [reservationId, dispatch])

    return (
        <div>
            <ReservationPage />
        </div>
    )
}

export default PastOrderPage