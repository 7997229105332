import axios from 'axios'
import { setRedirectToHome } from '../../../components/navigation/services/actions'
import {
  SET_BOOKING_DATA,
  SET_BOOKING_STATUS_DATA,
  SET_BOOKING_SUCCESS,
  SET_HAS_BOOKED_RESERVATION,
  SET_INVITATION_DETAILS,
  SET_INVITATION_TOKEN,
  SET_INVITE_MESSAGE,
  SET_PAYING_FOR_OTHERS,
  SET_RESERVATION_DETAILS,
  SET_RESERVATION_ID,
  SET_SWITCH_TO_INPUT_COMPONENT,
  SET_TIMES_AVAILABLE,
} from '../../../config/actionTypes'
import {
  accept_invitation_api,
  book_dummmy_table_api,
  book_table_api,
  cancel_reservation_api,
  decline_invitation_api,
  get_available_times_api,
  get_invitation_details,
  get_invitation_message_api,
  get_reservation_details_api,
  has_booked_reservation,
  start_booked_order_api,
  update_reservation_users_presence_api
} from '../../../config/api_endpoints'
import {
  accept_invitation_modal,
  accept_invitation_success_modal,
  available_times_modal,
  update_user_modal
} from '../../../config/modal_paths'
import { home_path } from '../../../config/pages_paths'
import { createFormattedDateString } from '../../../config/timeDate'
import { orderStatusEnum } from '../../../enums/orderStatusEnum'
import { setErrors } from '../../../store/actions/errorsActions'
import {
  setActiveModal,
  setLoader,
  setResponseMessage,
  setToastMessage
} from '../../../store/actions/feedbackActions'
import { getRestaurants } from '../../../store/actions/restaurantsActions'
import { getReservations, setInitReservationType, setReservationsManually } from '../../reservations/services/actions'
import reservationTypeEnum from '../../../enums/reservationTypeEnum'
import localStorageHelper from '../../../utils/localStorageHelper'
import { BOOKING_DATA } from '../../../constants'

export const startBookedOrder = (tableNumber, reservationId, reservations, history) => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.post(start_booked_order_api, { reservationId, tableNumber })
    dispatch(setInitReservationType(reservationTypeEnum.ongoing))
    dispatch(manuallyStartReservation(reservationId, reservations))
    // history.push(reservations_path)
    dispatch(setSwitchToInputComponent(true))
  } catch (err) {
    dispatch(setErrors(err, () => startBookedOrder(reservationId, reservations)))
    if (err.response.data?.message?.switchToInputComponent) {
      dispatch(setSwitchToInputComponent({ value: err.response.data?.message?.switchToInputComponent, reservationId: err.response.data?.message?.reservationId }))
    }
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const setSwitchToInputComponent = payload => dispatch => {
  dispatch({ type: SET_SWITCH_TO_INPUT_COMPONENT, payload })
}

export const manuallyStartReservation = (reservationId, reservations) => async dispatch => {
  try {
    let startedBooking
    const updatedUpcomingReservations = reservations.upcoming.map(reservation => {
      if (reservation.reservation_id === reservationId) {
        reservation.order_status = orderStatusEnum.orderStarted
        startedBooking = reservation
      }
      return reservation
    })
    dispatch(setReservationsManually({
      upcoming: updatedUpcomingReservations,
      ongoing: startedBooking,
      past: reservations.past,
    }))
  } catch (err) {
    dispatch(setErrors(err))
  }
}

export const bookTable = (data, restaurantId) => async dispatch => {
  try {
    dispatch(setLoader(true))
    const {
      data: {
        reservation: { reservation_id }
      }
    } = await axios.post(
      book_table_api.replace(':restaurantId', restaurantId),
      data
    )
    dispatch(setReservationId(reservation_id))
    dispatch(setResponseMessage('Table booked'))
    dispatch(getRestaurants())
    dispatch(getReservations())
  } catch (err) {
    dispatch(setErrors(err, () => bookTable(data, restaurantId)))
    dispatch(setRedirectToHome(true))
  } finally {
    dispatch(setLoader(false))
    dispatch(setActiveModal(''))
  }
}

export const getReservationDetails = (reservationId, history) => async dispatch => {
  try {
    if (reservationId === null || reservationId === undefined || isNaN(+reservationId)) {
      // console.log(reservationId === null)
      // console.log(reservationId === undefined)
      // console.log(isNaN(+reservationId))
      // dispatch(setToastMessage({
      //   message:"Reservation does not exist",
      //   severity: "error"
      // }))
      // history.push(home_path)
      return
    }
    dispatch(setLoader(true))
    const result = await axios.get(
      get_reservation_details_api.replace(':reservationId', reservationId)
    )
    dispatch(setReservationDetails(result.data))

    // if (result.data.reservation.order_status === orderStatusEnum.orderExpired) {
    //   history.push(home_path)
    // }
  } catch (err) {
    dispatch(setErrors(err, () => getReservationDetails(reservationId, history)))
    // history.push(home_path)
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const setReservationDetails = payload => dispatch => {
  dispatch({ type: SET_RESERVATION_DETAILS, payload })
}

export const setReservationId = id => dispatch => {
  dispatch({ type: SET_RESERVATION_ID, payload: id })
}

export const setInvitationToken = token => dispatch => {
  dispatch({ type: SET_INVITATION_TOKEN, payload: token })
}

export const getInvitationDetails = (
  reservationToken,
  showPopup = false
) => async dispatch => {
  try {
    dispatch(setLoader(true))
    const result = await axios.get(
      get_invitation_details.replace(':reservationToken', reservationToken)
    )
    dispatch(setInvitationDetails(result.data))
    if (showPopup) dispatch(setActiveModal(accept_invitation_modal))
  } catch (err) {
    dispatch(
      setErrors(err, () => getInvitationDetails(reservationToken, showPopup))
    )
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const setInvitationDetails = details => dispatch => {
  dispatch({ type: SET_INVITATION_DETAILS, payload: details })
}

export const handleReservationInvite = token => dispatch => {
  dispatch(getInvitationDetails(token, true))
}

export const acceptInvitation = reservation => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.post(accept_invitation_api, { reservation })
    dispatch(setActiveModal(accept_invitation_success_modal))
  } catch (err) {
    dispatch(setErrors(err, () => acceptInvitation(reservation)))
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const declineInvitation = reservation => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.post(decline_invitation_api, { reservation })
  } catch (err) {
    dispatch(setErrors(err, () => acceptInvitation(reservation)))
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const getInviteMessage = reservationId => async dispatch => {
  try {
    dispatch(setLoader(true))
    const result = await axios.get(
      get_invitation_message_api.replace(':reservationId', reservationId)
    )
    dispatch(setInviteMessage(result.data.invitation))
  } catch (err) {
    if (err.response.status === 400)
      dispatch(
        setToastMessage({
          message: err.response.data.message ? err.response.data.message.toString() : "",
          severity: "error",
        })
      )
    dispatch(setErrors(err, () => getInviteMessage(reservationId)))
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const setInviteMessage = message => dispatch => {
  dispatch({ type: SET_INVITE_MESSAGE, payload: message })
}

// NOT USED
export const createDummyReservation = (
  restaurantId,
  tableNumber = 1
) => async dispatch => {
  try {
    dispatch(setLoader(true))
    const {
      data: {
        reservation: { reservation_id }
      }
    } = await axios.post(
      book_dummmy_table_api.replace(':restaurantId', restaurantId),
      {
        date: createFormattedDateString(Date.now()),
        sizeOfParty: 1,
        tableNumber
      }
    )
    dispatch(setReservationId(reservation_id))
    dispatch(getRestaurants())
    dispatch(setResponseMessage('Table booked'))
  } catch (err) {
    if (err.response.status === 400) {
      dispatch(setToastMessage({
        message: err.response.data.short_message,
        severity: "error",
      }))
    }
    dispatch(setErrors(err, () => createDummyReservation(restaurantId, tableNumber)))
    dispatch(setRedirectToHome(true))
  } finally {
    dispatch(setLoader(false))
  }
}

export const cancelReservation = reservationId => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.get(
      cancel_reservation_api.replace(':reservationId', reservationId)
    )
    await dispatch(getReservations())
    dispatch(getRestaurants())
    dispatch(setResponseMessage('Reservation cancelled'))
  } catch (err) {
    if (err.response.status === 400) {
      dispatch(setActiveModal(''))
      dispatch(
        setToastMessage({
          message: err.response.data.short_message,
          severity: "error",
        })
      )
    }
    dispatch(setErrors(err, () => cancelReservation(reservationId)))
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const setTimesAvailable = payload => dispatch => {
  dispatch({ type: SET_TIMES_AVAILABLE, payload })
}

export const setBookingData = data => ({
  type: SET_BOOKING_DATA,
  payload: data
})



export const searchFreeTables = (data, isAuthenticated) => async dispatch => {
  try {
    dispatch(setLoader(true))
    const {
      data: { timesAvailable }
    } = await axios.post(
      get_available_times_api.replace(':restaurantId', data.restaurantId),
      data
    )
    dispatch(setTimesAvailable(timesAvailable))

    if (!!isAuthenticated && !data?.user.is_anonymous) {
      dispatch(setActiveModal(available_times_modal))
    }
    else {
      if (!!data?.user.is_anonymous || !data?.user.phone || !data?.user.first_name) {
        localStorageHelper.saveItem(BOOKING_DATA, data)
        dispatch(setActiveModal(update_user_modal))
      }
    }

  } catch (err) {
    dispatch(setErrors(err, () => searchFreeTables(data)))
  }
  finally {
    dispatch(setLoader(false))
  }
}

export const setPayingForOthers = payload => dispatch => {
  dispatch({
    type: SET_PAYING_FOR_OTHERS,
    payload
  })
}

export const getHasBookedReservation = (restaurantId, tableNumber, cb) => async dispatch => {
  try {
    const { data } = await axios.post(has_booked_reservation, { restaurantId, tableNumber })
    if (cb) cb(data?.result || false)
    dispatch(setHasBookedReservation(data?.result || false))
  } catch (err) {
    console.log(err)
    dispatch(setHasBookedReservation(false))
  }
}

export const setHasBookedReservation = payload => dispatch => {
  dispatch({
    type: SET_HAS_BOOKED_RESERVATION,
    payload
  })
}

export const setBookingStatusData = payload => dispatch => {
  dispatch({
    type: SET_BOOKING_STATUS_DATA,
    payload
  })
}
export const setBookingSuccess = payload => dispatch => {
  dispatch({
    type: SET_BOOKING_SUCCESS,
    payload
  })
}

export const updateReservationUsersPresence = (reservationId, users) => async dispatch => {
  try {
    const { data } = await axios.patch(update_reservation_users_presence_api, { reservationId, users, })

    return true

  } catch (err) {
    dispatch(setToastMessage({
      message: err.response.data.message ?? "Failed to update reservation guests presence",
      severity: "error"
    }))
    return false
  }
}
