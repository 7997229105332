import { colorsEnum } from "../../../enums/colorsEnum";

const FloatingIslandDownload = ({ label, loading = "Loading", downloadURL, fileName, show, className = "floating-island-button", leftIcon, rightIcon, }) => (
  <>
    {
      show && <button
        type={'button'}
        disabled={!downloadURL}
        className={className}
      >
        { downloadURL ? <>
          { leftIcon && (<span>{ leftIcon }</span>)}
          <a href={downloadURL} download={`${fileName}.pdf`} style={{color: `${colorsEnum.navyBlue}`}}>{ label }</a>
          { rightIcon && (<span>{ rightIcon }</span>)}
        </> : <>
          <span style={{color: 'white'}}>{ loading }</span>
        </> }
      </button>
    }
  </>
);

export default FloatingIslandDownload
