import { setErrors } from '../../../store/actions/errorsActions'
import axios from 'axios'
import {
  setLoader,
  setToastMessage
} from '../../../store/actions/feedbackActions'
import { send_client_info_email_api } from '../../../config/api_endpoints'

export const sendClientInfoEmail = data => async dispatch => {
  try {
    dispatch(setLoader(true))
    await axios.post(send_client_info_email_api, data)
    dispatch(setToastMessage({ message: 'Email sent!', severity: "info"}))
  } catch (err) {
    dispatch(setErrors(err, () => sendClientInfoEmail(data)))
  }
  finally {
    dispatch(setLoader(false))
  }
}
