import classnames from 'classnames'
import React, { useState } from 'react'
import errorMessages from '../../components/messages/errorMessages'
import InputField from '../common/InputField'
import YouSureItemModal from '../orders/YouSureItemModal'
import useFloatingIslandActions from '../../hooks/useFloatingIslandActions'

function ReservationTableNumber({
    style,
    className,
    reservation,
    guests,
    cb,
    showInputField,
    hasOpenTableReservationId,
    handleReservationUserPresence,
    hostBookingPresence
}) {
    const [tableNumber, setTableNumber] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [onConfirmationModal, setOnConfirmationModal] = useState(false)


    const handleTableNumberChange = e => {
        +e.target.value < 0
            ? setErrorMessage('Please enter a valid table number')
            : (() => {
                setTableNumber(e.target.value)
                setErrorMessage('')
            })()
    }

    const handleActiveReservation = async (tableNumber) => {
        await cb(tableNumber)
    }

    const handleConfirmationModal = () => {
        setOnConfirmationModal(prevState => !prevState)
    }

    const {
        createButtonPortal, createMessagePortal,
        proceed, goToBookingMenu,
        DOMslot1, DOMslot2, DOMslot3,
    } = useFloatingIslandActions()

    return (
        <>
            {/* { reservation.isWithinEligibleTimeRange && reservation.is_booking_confirmed ? */}
             {true ?
                (
                    <div
                        style={style}
                        className={classnames('reservation-info-container', className)}
                    >
                        {/* <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}> */}

                        {createMessagePortal("Start your dining experience", DOMslot2)}
                        {createButtonPortal(goToBookingMenu, DOMslot3)}

                        {
                            !hasOpenTableReservationId || showInputField ? (
                                <>
                                    <div>
                                        <div className="booking-restaurant-heading">Please enter your table number manually instead of scanning the QR Code</div>

                                        <InputField
                                            // style={{maxWidth:"16rem"}}
                                            formGroup={false}
                                            type='text'
                                            onChange={handleTableNumberChange}
                                            value={tableNumber}
                                            min={1}
                                            error={errorMessage}
                                            errorClassName='text-left'
                                            inputClassName='table-number'
                                            placeholder='Input the table number'
                                        />
                                        {/* <div style={{ margin: "1.5rem auto auto", display: "flex", justifyContent: "center" }}>
                                            <button
                                                disabled={!tableNumber}
                                                className='btn btn-primary big-round-btn-2'
                                                onClick={handleConfirmationModal}>
                                                Proceed
                                            </button>
                                        </div> */}
                                    </div>

                                    {createButtonPortal(proceed(handleConfirmationModal, !tableNumber), DOMslot1)}
                                </>
                            ) : (
                                <>
                                    {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <div className="booking-restaurant-heading">Submit your orders now</div>
                                        <button
                                            className='btn btn-primary big-round-btn m-auto'
                                            onClick={handleConfirmationModal}>
                                            Proceed
                                        </button>
                                    </div> */}

                                    {createButtonPortal(proceed(handleConfirmationModal), DOMslot1)}
                                </>
                            )
                        }
                        {/* </div> */}
                    </div>
                ) : (
                    <>
                        {/* <div className="booking-restaurant-heading">
                            {errorMessages.reservationNotEligibleToStartBookedOrder}
                        </div> */}

                        {createButtonPortal(goToBookingMenu, DOMslot3)}
                        {createMessagePortal(errorMessages.reservationNotEligibleToStartBookedOrder, DOMslot1)}
                    </>
                )
            }
            {
                onConfirmationModal ?
                    <YouSureItemModal
                        show={onConfirmationModal}
                        message={showInputField ?
                            "Has the hostess confirmed the table number you are attempting to use?" :
                            "Proceed to your dining experience?"
                        }
                        guests={guests}
                        handleReservationUserPresence={handleReservationUserPresence}
                        hostBookingPresence={hostBookingPresence}
                        action={() => {
                            handleActiveReservation(tableNumber)
                            handleConfirmationModal()
                        }}
                        close={handleConfirmationModal}
                    /> :
                    null
            }
        </>
    )
}

export default ReservationTableNumber
