import Validator from 'validator'
import isEmpty from 'lodash.isempty'

function validateRegister({ email, phone, new_password, confirm_password }) {
  const errors = {}
  if (!Validator.isEmail(email)) errors.email = 'Invalid email address'

  if (isEmpty(new_password)) errors.new_password = 'Password cannot be empty'
  else if (!Validator.isLength(new_password, { min: 8 }))
    errors.new_password = 'Password must contain at least 8 characters';
  else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])/.test(new_password))
    errors.new_password = 'Password must contain at least one lowercase and one uppercase letter, one number, and one special character (!@#$%^&*()_+)';

  if (isEmpty(confirm_password) || (new_password !== confirm_password)) errors.confirm_password = "Passwords don't match"

  return { errors, isValid: isEmpty(errors) }
}

export default validateRegister
