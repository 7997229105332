
import React from "react";
import "./ApplePayButton.css";
import ApplePayIcon from "./ApplePayIcon";

const ApplePayButton = ({style, title, theme, ariaLabel, children, ...extraProps}) => {
  return (
    <button
      className={`apple-pay-button apple-pay-button-${theme}`}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...extraProps}
      aria-label={ariaLabel}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span
          style={{
            paddingRight: 6,
            color: theme === "light" ? "black" : "white",
          }}
        >
          {children}
        </span>
        <ApplePayIcon
          style={{
            width: '2.5rem',
            height: '1rem',
          }}
          fill={theme === "light" ? "black" : "white"}
        />
      </div>
    </button>
  );
};

export default ApplePayButton;
