import {
  SET_MENU_ACTIVE_NAME,
  SET_MENU_ACTIVE_SUB_NAME,
  SET_REDIRECT_TO_HOME,
} from '../../../config/actionTypes'

export const setMenuActiveName = payload => dispatch => {
  dispatch({ type: SET_MENU_ACTIVE_NAME, payload })
}

export const setmenuSectionActiveName = payload => dispatch => {
  dispatch({ type: SET_MENU_ACTIVE_SUB_NAME, payload })
}

export const setRedirectToHome = payload => dispatch => {
  dispatch({ type: SET_REDIRECT_TO_HOME, payload })
}