import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import React, { useEffect, useRef, useState } from 'react';
import { useAnimate, useAnimateGroup } from "react-simple-animate";
import './FloatingIsland.css';

const FloatingIsland = () => {
    const DOMslot1Ref = useRef()
    const DOMslot2Ref = useRef()
    const DOMslot3Ref = useRef()
    const DOMslot4Ref = useRef()
    const DOMslot5Ref = useRef()
    // const DOMslot6Ref = useRef()
    // const DOMslot7Ref = useRef()
    // const DOMslot8Ref = useRef()

    const hasSlot1ChildNodes = DOMslot1Ref && DOMslot1Ref.current && DOMslot1Ref.current.hasChildNodes()
    const hasSlot2ChildNodes = DOMslot2Ref && DOMslot2Ref.current && DOMslot2Ref.current.hasChildNodes()
    const hasSlot3ChildNodes = DOMslot3Ref && DOMslot3Ref.current && DOMslot3Ref.current.hasChildNodes()
    const hasSlot4ChildNodes = DOMslot4Ref && DOMslot4Ref.current && DOMslot4Ref.current.hasChildNodes()
    const hasSlot5ChildNodes = DOMslot5Ref && DOMslot5Ref.current && DOMslot5Ref.current.hasChildNodes()


    // Dependency on either slot clears out the ref so there is no empty space
    useEffect(() => {
        if(hasSlot2ChildNodes) {
            const domSlot2 = document.getElementById("floating-island-slot-2")
            if(domSlot2.innerHTML !== null) {
                // domSlot2.innerHTML = ""
            }
        }
    },[hasSlot1ChildNodes, hasSlot2ChildNodes])

    // useEffect(() => {
    //     console.log(hasSlot2ChildNodes)
    // },[hasSlot2ChildNodes])

    // const hasSlot6ChildNodes = DOMslot6Ref && DOMslot6Ref.current && DOMslot6Ref.current.hasChildNodes()
    // const hasSlot7ChildNodes = DOMslot7Ref && DOMslot7Ref.current && DOMslot7Ref.current.hasChildNodes()
    // const hasSlot8ChildNodes = DOMslot8Ref && DOMslot8Ref.current && DOMslot8Ref.current.hasChildNodes()
    // const [hasHadChild2Nodes, setHasHadChild2Nodes] = useState()
    const isVisible = hasSlot1ChildNodes || hasSlot2ChildNodes
    const hasTop = hasSlot3ChildNodes || hasSlot4ChildNodes || hasSlot5ChildNodes // || hasSlot6ChildNodes || hasSlot7ChildNodes || hasSlot8ChildNodes
    const [isTopOpen, setIsTopOpen] = useState(true);
    // const [isVisible, setIsVisible] = useState(false);
    const [shouldSlideOut, setShouldSlideOut] = useState(false);
    // const [shouldSlideOutTop, setShouldSlideOutTop] = useState(false)

    const { play: buttonPlay, style: buttonStyle, isPlaying: buttonIsPlaying } = useAnimate({
        start: { width: "50%" },
        end: { width: "100%" },
        duration: 0.1,
    });

    const { styles, play, isPlaying } = useAnimateGroup({
        sequences: [DOMslot1Ref, DOMslot2Ref, DOMslot3Ref, DOMslot4Ref, DOMslot5Ref].map(() => ({
            start: { opacity: 1, transform: "translateY(0)" },
            end: { opacity: 0, transform: "translateY(0.75rem)" },
            duration: 0.1
        })),
    })

    // const DOMslot1 = document.getElementById('floating-island-slot-1');
    // if (DOMslot1) {
    //     console.log("DOMslot1", DOMslot1.hasChildNodes())
    //     console.log("innerHTML", DOMslot1.innerHTML)
    //     console.log("outerHTML", DOMslot1.outerHTML)
    // }

    //#region right, left, top logic

    //   const { options: [primary, secondary, ...more], msg } = action.payload
    // let right, left, top

    //   //Add logic to handle empty top...
    //   if (primary) {
    //     right = primary;
    //     if (msg) {
    //       left = msg;
    //       if (secondary && more) {
    //         top = [secondary, ...more];
    //       }
    //       else if (secondary && !more) {
    //         top = [secondary]
    //       }
    //       else if(more && !secondary) {
    //         top = [...more]
    //       }
    //     }
    //     else if (secondary && !msg) {
    //       left = secondary; top = more;
    //     }
    //     else if (isEmpty(more)) {
    //       top = null
    //     }
    //     else {
    //       top = more;
    //     }
    //   }
    //   return { ...state, floatingIslandElements: { right, left, top, } }

    //#endregion
    // const [prevRight, setPrevRight] = useState(null);
    // const [prevTop, setPrevTop] = useState(null)
    // const [animateTopButtons, setAnimateTopButtons] = useState(false);
    // const [isVisibleTop, setIsVisibleTop] = useState(false);
    // let timeoutId;

    const toggleOptionsHandler = () => {
        // !isTopOpen && setIsTopOpen(true)
        // isTopOpen && setShouldSlideOutTop(true)
        // setTimeout(() => {
        //     isTopOpen && setIsTopOpen(false)
        // }, 400)
        if (isTopOpen) {
            play(!isPlaying)
        }
        else {
            setTimeout(() => {
                play(!isPlaying)
            }, 10)
        }
        setIsTopOpen(p => !p)
    };

    const [bothSlotsFilled, setBothSlotsFilled] = useState(hasSlot2ChildNodes)
    const [prevBothSlotsFilled, setPrevBothSlotsFilled] = useState(false)

    useEffect(() => {
        // Check if both slots are filled
        const areBothSlotsFilledNow = hasSlot1ChildNodes && hasSlot2ChildNodes;
        setBothSlotsFilled(areBothSlotsFilledNow);

        // Trigger animation when both slots were filled and one becomes empty
        if (prevBothSlotsFilled && !areBothSlotsFilledNow) {
            // Reverse animation for slot1
            buttonPlay(!buttonIsPlaying);
        }

        // Update the previous state
        setPrevBothSlotsFilled(areBothSlotsFilledNow);

    }, [hasSlot1ChildNodes, hasSlot2ChildNodes, prevBothSlotsFilled, buttonPlay, buttonIsPlaying]);


    // useEffect(() => {
    //     // Trigger slideIn animation when right becomes not null and the component is not visible
    //     if (right !== null && right !== undefined && !isVisible) {
    //         setIsVisible(true);
    //     }

    //     // Trigger slideOut animation when right becomes null
    //     if ((right === null || right === undefined) && isVisible) {
    //         setShouldSlideOut(true);
    //     }

    //     // Trigger slideIn animation when right changes its value
    //     // if (right !== prevRight && prevRight !== null) {
    //     //     setShouldSlideOut(true);
    //     //     setIsVisible(true);
    //     // }
    //     // setPrevRight(right)
    // }, [right, isVisible, ]);
    // }, [right, isVisible, prevRight]);

    // useEffect(() => {
    //     console.log(shouldSlideOutTop)
    // }, [shouldSlideOutTop])

    // useEffect(() => {
    //     return () => {
    //         setIsTopOpen(false)
    //     }
    // }, [])

    const onAnimationEnd = () => {
        // Reset shouldSlideOut after the slideOut animation is complete
        if (shouldSlideOut) {
            setShouldSlideOut(false);
            // setIsVisible(false);
        }
    };

    // const onAnimationEndTop = () => {
    //     if (shouldSlideOutTop) {
    //         // Clear the timeout to prevent setting the state after animation end
    //         clearTimeout(timeoutId);
    //         // Set the timeout to update the state after the animation completes
    //         timeoutId = setTimeout(() => {
    //             setShouldSlideOutTop(false);
    //         }, 500); // Adjust the delay as needed based on your animation duration
    //     }
    // }

    // const handleButtonSlide = () => {
    //     playSlot1(!isPlayingSlot1)
    // }

    // ${shouldSlideOut ? 'slideOut' : 'slideIn'}


    // useEffect(() => {
    //     setHasHadChild2Nodes(!!hasSlot2ChildNodes)
    //     if(hasSlot1ChildNodes && hasHadChild2Nodes && !isPlaying) {
    //         play(!isPlaying)
    //         setHasHadChild2Nodes(false)
    //     }

    // },[hasSlot1ChildNodes ,hasSlot2ChildNodes, hasHadChild2Nodes, isPlaying, play])


    useEffect(() => {
        // Set display to none after a delay of 0.5s
        if (!isTopOpen) {
            const timeoutId = setTimeout(() => {
                // Set the display property for each DOM element if needed
                // DOMslot6Ref.current.style.display = 'none';
                DOMslot3Ref.current.style.display = 'none';
                DOMslot4Ref.current.style.display = 'none';
                DOMslot5Ref.current.style.display = 'none';
            }, 500);

            // Clear the timeout to avoid memory leaks
            return () => clearTimeout(timeoutId);
        }
        else {
            DOMslot3Ref.current.style.display = 'block';
            DOMslot4Ref.current.style.display = 'block';
            DOMslot5Ref.current.style.display = 'block';
        }
    }, [isTopOpen]);

    return (
        <>
            <div
                className={`floating-island-container ${!isVisible ? 'floating-island-empty-slot' : ""}`}
                onAnimationEnd={onAnimationEnd}
            >
                {
                    <div
                        className={hasTop ? `floating-island-more-options-wrapper` : 'floating-island-empty-slot'}
                    // style={!isTopOpen? { gap:"0", margin: "0" } : {}}
                    // onAnimationEnd={onAnimationEndTop}
                    >
                        {/* {top.map((el, index) => (
                        <div
                        style={{ animationDelay: `${(0.2 + index) / 10}s` }}
                        key={index} className={`floating-island-button-wrapper-2 ${shouldSlideOutTop ? 'slideOutTop' : 'slideInTop'}`}>
                        <FloatingIslandElement className="floating-island-button-top" element={el} />
                        </div>
                    ))} */}

                        {/* <div id={"floating-island-slot-8"} ref={DOMslot8Ref} className={hasSlot8ChildNodes && isTopOpen ? '' : 'floating-island-empty-slot'} />
                <div id={"floating-island-slot-7"} ref={DOMslot7Ref} className={hasSlot7ChildNodes && isTopOpen ? '' : 'floating-island-empty-slot'} />
            <div id={"floating-island-slot-6"} ref={DOMslot6Ref} className={hasSlot6ChildNodes && isTopOpen ? '' : 'floating-island-empty-slot'} /> */}
                        <div id={"floating-island-slot-5"} style={styles[2]} ref={DOMslot5Ref} />
                        <div id={"floating-island-slot-4"} style={styles[3]} ref={DOMslot4Ref} />
                        <div id={"floating-island-slot-3"} style={styles[4]} ref={DOMslot3Ref} />
                    </div>
                }

                {hasTop &&
                    <div onClick={toggleOptionsHandler} className='floating-island-more-options-container'>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            userSelect: "none",
                        }}>
                            {isTopOpen ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                            <span style={{ display: "flex", width: "100%", marginLeft: ".4rem" }}>{isTopOpen ? "Less" : "More"} Options</span>
                        </div>
                    </div>
                }

                <div className='floating-island-wrapper'>
                    <div
                        id={"floating-island-slot-2"}
                        ref={DOMslot2Ref}
                        className={
                            hasSlot2ChildNodes ?
                                'floating-island-button-wrapper-2' :
                                'floating-island-empty-slot'
                        }
                    />
                    <div
                        // style={!!prevBothSlotsFilled ? style : {}}
                        id={"floating-island-slot-1"}
                        ref={DOMslot1Ref}
                        // style={bothSlotsFilled ? {} : buttonStyle}
                        className={
                            hasSlot1ChildNodes ?
                                hasSlot2ChildNodes ?
                                    `floating-island-button-wrapper ` :
                                    `floating-island-button-wrapper-full` :
                                'floating-island-empty-slot'
                        }
                    />
                </div>
                {/* <button onClick={() => play(!isPlaying)}>Play</button> */}
            </div>
        </>
    );
};

// export default FloatingIsland;


// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux'
// import FloatingIslandElement from './FloatingIslandElement'
// import './FloatingIsland.css'
// import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
// import { isEmpty } from 'lodash';
// import { colorsEnum } from '../../../enums/colorsEnum';
// import { fontFamiliesEnum } from '../../../enums/fontFamiliesEnum';

// const FloatingIsland = () => {
//     const [isTopOpen, setIsTopOpen] = useState(false)
//     const [animateTopButtons, setAnimateTopButtons] = useState(false);
//     const {
//         feedback: { floatingIslandElements: { right, left, top } }
//     } = useSelector((state) => state);

//     // const [animateMoreOptions, setAnimateMoreOptions] = React.useState(false);
//     // const [onSmallVisibleButtons, setOnSmallVisibleButtons] = React.useState(false)

//     const toggleOptionsHandler = () => {
//         setIsTopOpen(prevState => !prevState)
//         setAnimateTopButtons(true);
//     }

//     useEffect(() => {
//         if (isTopOpen) {
//             setAnimateTopButtons(true);
//         }
//     }, [isTopOpen]);

//     // console.log("FloatingIslandElements right = ", right, " left = ", left, " top = ", top)

//     return (
//         right &&
//         <div
//             className={`floating-island-container ${right ? 'animate' : ''}`}
//         >

//             {top && isTopOpen && (
//                 <div className='floating-island-more-options-wrapper'>
//                     {top.map((el, index) => (
//                         <div key={index}
//                             // style={{animationDelay:`calc()`}}
//                             className='floating-island-button-wrapper'>
//                             <FloatingIslandElement element={el} />
//                         </div>
//                     ))}
//                 </div>
//             )}
//             <div
//                 onClick={toggleOptionsHandler}
//                 className='floating-island-more-options-container'>
//                 {isTopOpen ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
//                 <span>{isTopOpen ? "Less" : "More"} Options</span>
//             </div>

//             <div className='floating-island-wrapper'>

//                 {left && (
//                     <div className='floating-island-button-wrapper'>
//                         <FloatingIslandElement element={left} />
//                     </div>
//                 )}

//                 {
//                     <div className='floating-island-button-wrapper'>
//                         <FloatingIslandElement element={right} />
//                     </div>
//                 }
//                 {/* <div>{ right }</div> */}
//             </div>
//         </div>
//     )

// return (
//     !isEmpty(floatingIslandData) && !onOpenedMenuDrawerSteps
//         ?
//         <div style={{
//             // margin:'2rem 0 2rme 0',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             position: 'fixed',
//             bottom: '-1rem',
//             zIndex: '9999',
//             objectFit: 'contain',
//             width: '100%',
//             maxWidth: '28rem',
//             left: '50%',
//             transform: 'translate(-50%,0)',
//             padding: '0.8rem 0 1.5rem',
//             borderRadius: '1rem',
//             backgroundColor: `transparent`
//             // backgroundColor: `${colorsEnum.white}`
//         }}>
//             {/* <button className="btn btn-primary big-round-btn-auto-width" style={{ width: "80%", maxWidth: "20rem", display: "flex", justifyContent: "space-around", alignItems: "center" }}
//           >
//             Lolcina
//           </button> */}
//             {/* {
//               !isEmpty(moreButtons) ?
//                   <div
//                       onClick={handleToggleMoreOptions}
//                       style={{
//                           display: 'flex',
//                           gap: '0.5rem',
//                           justifyContent: 'center',
//                           alignItems: 'center',
//                           backgroundColor: `${colorsEnum.navyBlue}`,
//                           height: '2.5rem',
//                           width: '50%',
//                           borderRadius: '2rem',
//                           margin: '0 0 -0.75rem 0',
//                           fontFamily: `${fontFamiliesEnum.cormorant}`,
//                           fontSize: '1.25rem',
//                           paddingBottom: '0.25rem',
//                           color: `${colorsEnum.white}`
//                       }}>
//                       {openMoreOptions ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
//                       <span
//                           style={{
//                               paddingBottom: '0.1rem'
//                           }}>
//                           {!openMoreOptions ? 'More' : 'Less'} options
//                       </span>
//                   </div> :
//                   null
//           }
//             {!!openMoreOptions && !isEmpty(moreButtons) ?
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     gap: '0.5rem',
//                     margin: '1rem 0',
//                     flexDirection: 'column',
//                     width: '100%',
//                     // opacity: openMoreOptions ? 1 : 0,
//                     // transition: '2s ease-in-out',
//                     backgroundColor:`${colorsEnum.navyBlue}`
//                 }}>
//                     {
//                         moreButtons.map((button) => {
//                             return (
//                                 <button
//                                     key={button.label}
//                                     style={{
//                                         width: '80%',
//                                         minWidth: '9rem',
//                                         height: '54px',
//                                         borderRadius: '1.5rem',
//                                         // boxShadow:'0 2px 2px 0 rgba(0,0,0,.3)',
//                                         backgroundColor: `${colorsEnum.white}`,
//                                         fontFamily: `${fontFamiliesEnum.cormorant}`,
//                                         fontSize: '1.25rem',
//                                         textAlign: 'center',
//                                         color: `${colorsEnum.navyBlue}`,
//                                         border: `1px solid ${colorsEnum.navyBlue}`,
//                                     }}
//                                     onClick={button.action}>
//                                     {button.label}
//                                 </button>
//                             )
//                         })
//                     }
//                 </div>
//                 : null
//             } */}
//             {
//                 !!openMoreOptions && !isEmpty(moreButtons) ?
//                     // moreButtons ?
//                     <div style={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         gap: '0.5rem',
//                         margin: '1rem 0',
//                         flexDirection: 'column',
//                         width: '100%',
//                         opacity: openMoreOptions ? 1 : 0,
//                         transition: animateMoreOptions ? 'opacity 0.35s ease-in-out' : 'none',
//                     }}>
//                         {
//                             moreButtons.map((button) => {
//                                 return (
//                                     <button
//                                         key={button.label}
//                                         style={{
//                                             width: '80%',
//                                             minWidth: '9rem',
//                                             height: '54px',
//                                             borderRadius: '2rem',
//                                             // boxShadow:'0 2px 2px 0 rgba(0,0,0,.3)',
//                                             backgroundColor: `${colorsEnum.white}`,
//                                             fontFamily: `${fontFamiliesEnum.cormorant}`,
//                                             fontSize: '1.25rem',
//                                             textAlign: 'center',
//                                             color: `${colorsEnum.navyBlue}`,
//                                             border: `1px solid ${colorsEnum.navyBlue}`,
//                                             cursor: 'pointer'
//                                         }}
//                                         onClick={button.action}>
//                                         {button.label}
//                                     </button>
//                                 )
//                             })
//                         }
//                     </div>
//                     : null
//             }
//             {
//                 !isEmpty(moreButtons) ?
//                     <div
//                         onClick={handleToggleMoreOptions}
//                         style={{
//                             display: 'flex',
//                             gap: '0.5rem',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             backgroundColor: `${colorsEnum.navyBlue}`,
//                             height: '2.5rem',
//                             width: '50%',
//                             borderRadius: '2rem',
//                             margin: '0 0 -0.75rem 0',
//                             fontFamily: `${fontFamiliesEnum.cormorant}`,
//                             fontSize: '1.25rem',
//                             paddingBottom: '0.25rem',
//                             color: `${colorsEnum.white}`,
//                             cursor: 'pointer'
//                         }}>
//                         {openMoreOptions ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
//                         <span
//                             style={{
//                                 paddingBottom: '0.1rem'
//                             }}>
//                             {!openMoreOptions ? 'More' : 'Less'} options
//                         </span>
//                     </div> :
//                     null
//             }


//             <div style={{
//                 backgroundColor: `${colorsEnum.navyBlue}`,
//                 width: '95%',
//                 display: 'flex',
//                 flexWrap: 'wrap',
//                 height:'5rem',
//                 justifyContent: !isEmpty(secondaryButton) || primaryButton.message ? 'space-between' : 'flex-end',
//                 padding: '0.5rem',
//                 borderRadius: '1.5rem',

//             }}>
//                 {/* {!!openMoreOptions && !isEmpty(moreButtons) ?
//                     <div style={{
//                         display: 'flex',
//                         justifyContent: 'space-between',
//                         flexWrap: 'wrap',
//                         alignItems: 'center',
//                         gap: '0.5rem',
//                         margin: '1rem 0',
//                         flexDirection: 'row',
//                         width: '100%',
//                         opacity: openMoreOptions ? 1 : 0,
//                         transition: '2s ease-in-out',
//                     }}>
//                         {moreButtons.map((button, index) => {
//                             const isLastItem = index === moreButtons.length - 1;
//                             return (
//                                 <button
//                                     key={button.label}
//                                     style={{
//                                         minWidth: '10rem',
//                                         height: '3.5rem',
//                                         borderRadius: '1.5rem',
//                                         backgroundColor: `${colorsEnum.white}`,
//                                         fontFamily: `${fontFamiliesEnum.cormorant}`,
//                                         fontSize: '1.25rem',
//                                         textAlign: 'center',
//                                         color: `${colorsEnum.navyBlue}`,
//                                         border: `1px solid ${colorsEnum.navyBlue}`,
//                                         justifySelf: isLastItem ? 'flex-end' : 'auto',
//                                         alignSelf: isLastItem ? 'flex-end' : 'auto', // Align last item to flex-end
//                                         marginBottom: isLastItem && moreButtons.length % 2 !== 0 ? '0.5rem' : '0', // Add margin-bottom for the last item in odd rows
//                                     }}
//                                     onClick={button.action}
//                                 >
//                                     {button.label}
//                                 </button>
//                             );
//                         })}
//                     </div>
//                     : null
//                 } */}

//                 {primaryButton && !isEmpty(primaryButton.message) ?
//                     <div style={{
//                         display: 'flex',
//                         color: colorsEnum.white,
//                         alignItems: 'center',
//                         justifyContent: 'center'
//                     }}>
//                         {primaryButton.message}
//                     </div>
//                     :
//                     !isEmpty(secondaryButton)
//                         ?
//                         <div style={{
//                             display: 'flex',
//                             justifyContent: 'center',
//                             alignItems: 'center'
//                         }}>
//                             <button
//                                 onClick={secondaryButton.action}
//                                 style={{
//                                     // width:'80%',
//                                     display: 'flex',
//                                     justifyContent: secondaryButton?.icon ? 'flex-start' : 'center',
//                                     alignItems: 'center',
//                                     minWidth: onSmallVisibleButtons ? '6rem' : '10rem',
//                                     gap: '0.5rem',
//                                     height: '3.5rem',
//                                     borderRadius: '2rem',
//                                     // boxShadow:'0 2px 2px 0 rgba(0,0,0,.3)',
//                                     backgroundColor: `${colorsEnum.white}`,
//                                     fontFamily: `${fontFamiliesEnum.cormorant}`,
//                                     fontSize: '1.25rem',
//                                     textAlign: 'center',
//                                     color: `${colorsEnum.navyBlue}`,
//                                     border: 'none',
//                                     cursor: 'pointer'
//                                 }}
//                             >
//                                 {secondaryButton?.icon ? secondaryButton.icon : null}
//                                 <span
//                                     style={{
//                                         margin: secondaryButton?.icon ? '0 0 0.25rem 0' : 'auto'
//                                     }}
//                                 >
//                                     {secondaryButton.label}
//                                 </span>
//                             </button>
//                         </div>
//                         :
//                         null
//                 }


//                 {primaryButton ?
//                     <div style={{
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         justifySelf: 'flex-end'
//                     }}>
//                         <button
//                             onClick={primaryButton.action}
//                             style={{
//                                 // width:'80%',
//                                 display: 'flex',
//                                 justifyContent: primaryButton?.icon ? 'flex-end' : 'center',
//                                 alignItems: 'center',

//                                 minWidth: onSmallVisibleButtons ? '6rem' : '10rem',
//                                 maxWidth: '10rem',
//                                 height: '3.5rem',
//                                 gap: '0.5rem',
//                                 lineHeight: '1.5rem',
//                                 borderRadius: '2rem',
//                                 // boxShadow:'0 2px 2px 0 rgba(0,0,0,.3)',
//                                 backgroundColor: `${colorsEnum.white}`,
//                                 fontFamily: `${fontFamiliesEnum.cormorant}`,
//                                 fontSize: '1.25rem',
//                                 textAlign: 'center',
//                                 color: `${colorsEnum.navyBlue}`,
//                                 border: 'none',
//                                 cursor: 'pointer'
//                             }}
//                         >
//                             <span
//                                 style={{
//                                     margin: primaryButton?.icon ? '0 0 0.25rem 0' : 'auto'
//                                 }}
//                             >
//                                 {primaryButton.label}
//                             </span>
//                             {primaryButton?.icon ? primaryButton.icon : null}
//                         </button>
//                     </div> :
//                     null
//                 }
//             </div>
//         </div> :
//         onSmallVisibleButtons && onOpenedMenuDrawerSteps ?
//             <MenuStepperIsland
//                 primaryButton={primaryButton}
//                 secondaryButton={secondaryButton}
//             /> :
//             null
// )
// }

export default FloatingIsland
