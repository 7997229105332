import React from 'react'
import './FloatingIsland.css'

const FloatingIslandMessage = ({element}) => {
  return (
    <div className='floating-island-message'>
        {element}
    </div>
  )
}

export default FloatingIslandMessage