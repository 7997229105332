import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AddIcon, RemoveIcon } from '../../assets/svgs-google/GoogleIcons';
import SeparationLine from '../../components/common/SeparationLine';
import Column from '../../containers/layout/Column';
import inventoryStatusEnum from '../../enums/inventoryStatusEnum.js';
import useFloatingIslandActions from '../../hooks/useFloatingIslandActions';
import { editOrderItem, removeOrderItem } from "../../pages/order/services/actions";
import { setOnFullPageModal } from '../../store/actions/feedbackActions.js';
import InputFieldGroup from '../common/InputFieldGroup';
import Modal from '../modal/Modal.js';
import MenuItemDetails, { CloseButton } from '../restaurant/MenuItemDetails';
import './OrdersComponents.css';
import './buttons/OrderButtons.css';
import FreeModifiers from './modifiers/FreeModifier';

function AddMenuItemModal({ show, close, item, onAddClickHandler }) {
  const [quantity, setQuantity] = useState(item?.quantity || 1)
  const [selectableQuantity, setSelectableQuantity] = useState(0)
  const [oldestQuantity, setOldestQuantity] = useState(null)
  const [description, setDescription] = useState(item?.description || '')
  const [modifier, setModifier] = useState('')
  const [modifierMeta, setModifierMeta] = useState({})
  const [areAllRequiredGroupsSelected, setAreAllRequiredGroupsSelected] = useState(true)
  const {
    booking: { currentReservation },
  } = useSelector(state => state)
  const dispatch = useDispatch();

  useEffect(() => {
    if (oldestQuantity === null) {
      setOldestQuantity(quantity)
    }
  }, [quantity])

  useEffect(() => {
    if (item?.inventory_status === inventoryStatusEnum.QUANTITY) {
      if (item?.reservation_selected_quantity && item?.remaining_quantity) {
        const quantityAvailable = item.remaining_quantity - item?.reservation_selected_quantity

        const selectableQuantityCalculation = (item?.reservation_selected_quantity + quantityAvailable) >= item.remaining_quantity ?
        item.quantity === undefined ? (item.remaining_quantity - item?.reservation_selected_quantity) : oldestQuantity + (item.remaining_quantity - item?.reservation_selected_quantity ) : item?.reservation_selected_quantity + quantityAvailable

        setSelectableQuantity(selectableQuantityCalculation)

        if (!item?.quantity && quantity > selectableQuantityCalculation && !item?.order_item_id) {
          setQuantity(selectableQuantityCalculation <= 0 ? 1 : selectableQuantityCalculation)
        }
      } else {
        setSelectableQuantity(item?.remaining_quantity)
      }
    }
  }, [oldestQuantity, item?.remaining_quantity, item?.reservation_selected_quantity, item?.order_item_id, item?.quantity, quantity, item?.inventory_status])

  useEffect(() => {
    if (item?.quantity) {
      setQuantity(item?.quantity)
    } else if (!item?.quantity && !item?.order_item_id) {
      setQuantity(1)
    }
  }, [item?.quantity, item?.order_item_id])

  const onIncrease = () => {
    switch (item?.inventory_status) {
      case inventoryStatusEnum.IN_STOCK:
        setQuantity(val => val + 1)
        break
      case inventoryStatusEnum.QUANTITY:
        if (quantity < selectableQuantity) {
          setQuantity(val => val + 1)
        }
        break
      default:
    }
  }

  const onDecrease = () => {
    setQuantity(val => val - 1 > 0 ? val - 1 : 1)
  }

  const onDescriptionChange = e => {
    e.persist()
    setDescription(e.target.value)
  }

  const onAddOrderItemEventHandler = () => {
    // TODO change the querries for item.section_id || item.menu_section_id
    // both to be with or without the "menu_" prefix
    onAddClickHandler({
      menu_item_id: item.menu_item_id,
      menu_section_id: item.section_id || item.menu_section_id,
      quantity,
      description,
      modifier,
      modifierMeta
    })
    close()
  }

  const onEditOrderItemEventHandler = async () => {
    const reservationId = (currentReservation && currentReservation.reservation_id) || item.reservation_id
    await dispatch(editOrderItem(item.order_item_id, reservationId, {
      quantity, description, modifierGroups: modifierMeta.modifier,
      menuItemId: item.menu_item_id
    }))
    close()
  }

  const onDeleteOrderItemEventHandler = () => {
    const reservationId = (currentReservation && currentReservation.reservation_id) || item.reservation_id
    dispatch(removeOrderItem(item.order_item_id, reservationId))
    close()
  }

  //#region FloatingIsland

  useEffect(() => {
    dispatch(setOnFullPageModal(true))
    return () => dispatch(setOnFullPageModal(false))
  }, [dispatch])

  const { createButtonPortal, add, edit, remove, DOMslot1, DOMslot2, } = useFloatingIslandActions()

  //#endregion

  return (
    <div className={'add-menu-item-wrapper'}>
      <Modal className="add-menu-item-modal" show={show} close={close}>
      <CloseButton onClick={close} />
        <MenuItemDetails reduceDetailSize={false} {...item} close={close} />
        {item?.has_modifier ? null : (
          <FreeModifiers
            setModifier={item => setModifier(`${item.name}: ${item.modifier}`)}
            setModifierMeta={setModifierMeta}
            setAreAllRequiredGroupsSelected={setAreAllRequiredGroupsSelected}
          />
        )}
        <SeparationLine />
        <Column className="text-center" col={12}>
          <div className="add-menu-item-options-title mx-auto">
            <h3>Quantity</h3>
            {item?.inventory_status === inventoryStatusEnum.QUANTITY && selectableQuantity === 0 && !item?.order_item_id && item?.remaining_quantity > 0 && (
              <p style={{color: "red"}}>
                {item?.users?.map((name, i, { length }) => <span>{length - 1 === i ? " " + name : " " + name + ","}</span>)} {item?.users?.length > 1 ? "have" : "has"} already selected maximum quantity for ordering.
              </p>
            )}

            {item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || (item?.inventory_status === inventoryStatusEnum.QUANTITY && item?.remaining_quantity === 0) ? (
              <p style={{ color: "red", fontSize:"12px" }}>The item is out of stock</p>
            ) : null}
          </div>
          <div className="add-item-btns text-center">
            <div onClick={onDecrease}>{RemoveIcon()}</div>
            {/* <i className="fas fa-solid fa-minus fa-icon" onClick={onDecrease} /> */}
            <div className="item-quantity">
              <span>{quantity}</span>
            </div>
            <div onClick={onIncrease}>{AddIcon()}</div>
            {/* <i className="fas fa-solid fa-plus fa-icon" onClick={onIncrease} /> */}
          </div>
        </Column>
        <SeparationLine />
        <Column col={12} className="p-0">
          <div className="special-requirements-title mx-auto">
            <h4>Special requirements</h4>
          </div>
          <InputFieldGroup
            onChange={onDescriptionChange}
            value={description}
            wrapperClassName="special-requirements-input-wrapper"
            className="special-requirements-input"
            placeholder="Any remarks?"
            name="description"
          />
        </Column>
        { !item?.order_item_id && (
          <>
            {/* <AddItemButton onClick={onAddOrderItemEventHandler} disabled={item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || !areAllRequiredGroupsSelected || (item?.inventory_status === inventoryStatusEnum.QUANTITY && (item?.remaining_quantity === 0 || selectableQuantity === 0))} /> */}

            { createButtonPortal(add(onAddOrderItemEventHandler, item?.inventory_status === inventoryStatusEnum.OUT_OF_STOCK || !areAllRequiredGroupsSelected || (item?.inventory_status === inventoryStatusEnum.QUANTITY && (item?.remaining_quantity === 0 || selectableQuantity === 0)), "item"), DOMslot1) }
          </>
        )}
        <div className='edit-remove-wrapper'>
          {item?.order_item_id && (
            <>
              {/* <EditItemButton onClick={onEditOrderItemEventHandler} disabled={!areAllRequiredGroupsSelected} />
              <RemoveItemButton onClick={onDeleteOrderItemEventHandler} disabled={false} /> */}

              { createButtonPortal(edit(onEditOrderItemEventHandler, !areAllRequiredGroupsSelected, "item"), DOMslot1) }
              { createButtonPortal(remove(onDeleteOrderItemEventHandler, false, "item"), DOMslot2) }
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default AddMenuItemModal
