import React from 'react'
import CustomButton from '../common/CustomButton'
import './Reservation.css'
// import EditIcon from '../../assets/ico-edit.svg'
// import CancelIcon from '../../assets/ico-deactivate.svg'
// import InviteIcon from '../../assets/ico-share.svg'
// import MenuIcon from '../../assets/hamburger-menu.svg'
import { DoNotDesturbIcon, EditSquareIcon, IosShareIcon, MenuIcon } from '../../assets/svgs-google/GoogleIcons'

const btnStyle = {
  cursor: 'pointer'
}

// const invitationCopiedBtn = {
//   width: '170px',
//   marginLeft: '20px',
//   ...btnStyle
// }

// const invitationCopiedBtnText = {
//   width: '96px',
//   ...btnStyle
// }

const guestButtonStyle = {
  display: "flex",
  justifyContent: "space-evenly",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "400px"
}

function ReservationEditButtons({
  onInviteClick,
  onCancelInvitationClick,
  isInviteCopied,
  onMenuNavigationClick,
  onModifyClick,
  sizeOfParty,
  reservationGuest
}) {
  return (
    <div className="reservation-info" style={reservationGuest?.user_type === 'GUEST' ? guestButtonStyle : {}}>
      {reservationGuest?.user_type === 'HOST' && (
        <>
          <CustomButton
          style={btnStyle}
          wrapperClassName="reservation-btn-wrapper"
          textClassName="reservation-edit-btn-text"
          text={'Modify Reservation'}
          icon2={EditSquareIcon()}
          iconClassName="btn-icon"
          onClick={onModifyClick}
          />
          <CustomButton
          style={btnStyle}
          wrapperClassName="reservation-btn-wrapper"
          textClassName="reservation-edit-btn-text"
          text={'Cancel Reservation'}
          icon2={DoNotDesturbIcon()}
          iconClassName="btn-icon"
          onClick={onCancelInvitationClick}
          />
        </>
      )}
      {+sizeOfParty > 1 &&
        <CustomButton
          style={btnStyle}
          // style={isInviteCopied ? invitationCopiedBtn : btnStyle}
          // textStyle={isInviteCopied ? invitationCopiedBtnText : {}}
          wrapperClassName="reservation-btn-wrapper"
          textClassName="reservation-edit-btn-text"
          text={isInviteCopied ? 'Invitation link copied' : 'Invite Your Friends'}
          icon2={IosShareIcon()}
          iconClassName="invite-btn-icon"
          onClick={onInviteClick}
        />
      }
      {/* <CustomButton
        style={btnStyle}
        wrapperClassName="reservation-btn-wrapper"
        textClassName="reservation-edit-btn-text"
        text={'Add items from Menu'}
        icon2={MenuIcon()}
        iconClassName="btn-icon"
        onClick={onMenuNavigationClick}
      /> */}
    </div>
  )
}

export default ReservationEditButtons
