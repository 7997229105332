import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, useMediaQuery } from '@mui/material'
import classnames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import LandingLogo from '../../assets/logo.svg'
import BlueLogo from '../../assets/logo_2.svg'
import { aboutus_page_path, credit_card_information_path, help_page_path, home_path, landing_path, order_page_path, password_change_path, privacy_page_path, profile_page_path, qr_order_landing_path, reservations_path_now, reservations_path_past, reservations_path_upcoming, terms_page_path } from '../../config/pages_paths'
import useScroll from '../../hooks/useScroll'
import { setActiveModal, setOnFullPageModal, setPageRoutePath } from '../../store/actions/feedbackActions'
import { navigateToParentUrl } from '../../utils/navigateToParentUrl'
import Button from '../common/Button'
import './Navigation.css'
import { navItems } from './config'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import GavelIcon from '@mui/icons-material/Gavel'
import HelpIcon from '@mui/icons-material/Help'
import HistoryIcon from '@mui/icons-material/History'
import InfoIcon from "@mui/icons-material/Info"
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import { isEmpty } from 'lodash'
import { login_modal } from '../../config/modal_paths'
import { fontFamiliesEnum } from '../../enums/fontFamiliesEnum'
import { orderStatusEnum } from '../../enums/orderStatusEnum'
import reservationTypeEnum from '../../enums/reservationTypeEnum'
import { logoutUser } from '../../pages/auth/services/actions'
import { setInitReservationType } from '../../pages/reservations/services/actions'
import Image from '../common/Image'
import { CustomDrawer, CustomPopover } from '../common/previewComponents'

// function BrandItem({ pathname, isAuthenticated, history, isDown, routeName }) {





//   return pathname === landing_path || pathname === home_path ? (
//     <Link className="navbar-brand logo-wrapper" to="/">
//       <img
//         src={
//           pathname === landing_path && !isAuthenticated
//             ? isDown
//               ? BlueLogo
//               : LandingLogo
//             : BlueLogo
//         }
//         alt="logo"
//         className={
//           pathname === landing_path && !isAuthenticated ? 'logo' : 'blue-logo'
//         }
//       />
//     </Link>
//   ) : (
//     <div className="nav-item-page">
//       <img alt="back" src={BackIcon} onClick={() => history.goBack()} />{' '}

//       <span>{routeName}</span>


//     </div>
//   )
// }

export function GetTheAppButton({ className, renderConditionally, pathname }) {
  // TODO move links on backend and improve platform detection
  const onClick = () => {
    let url = 'https://play.google.com/store/apps/details?id=nyc.voila'
    if (
      new RegExp(
        /(iPhone|iPod|iPad|Macintosh|MacIntel|MacPPC|Mac68K|Pike|ios)/gim
      ).test(window.navigator.platform)
    ) {
      url = 'https://apps.apple.com/ug/app/voila-dining/id1553458462'
    }
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.click()
  }

  const renderButton = () => {
    return (
      <Button
        text={<span>Get the app</span>}
        onClick={onClick}
        className={className || 'nav-item m-2 round-btn get-the-app-btn'}
      />
    )
  }

  return renderConditionally
    ? pathname === home_path
      ? renderButton()
      : null
    : renderButton()
}

function Navigation() {
  const location = useLocation()
  const history = useHistory()
  const headerRef = useRef()
  const dispatch = useDispatch()
  const {
    auth: { user, isAuthenticated },
    booking: { currentReservation,
      reservationDetails: { reservation },
      // reservations: {
      //   ongoing
      // }
    },
    // feedback: { numOfLoadingSpinnerCalls },
    // navigation: { routeName, processStarted },
  } = useSelector(state => state)
  const [isDown] = useScroll(headerRef)
  const [showNavigationArrow, setShowNavigationArrow] = useState(false)

  const pathsToHideArrow = useMemo(() => [
    landing_path,
    home_path,
    profile_page_path,
    password_change_path,
    aboutus_page_path,
    credit_card_information_path,
    terms_page_path,
    help_page_path,
    privacy_page_path,
    qr_order_landing_path,
    reservations_path_past,
    reservations_path_now,
    reservations_path_upcoming,
  ], []);

  useEffect(() => {
    setShowNavigationArrow(!pathsToHideArrow.includes(location.pathname));
  }, [location, pathsToHideArrow]);

  // const [numOfReservations, setNumOfReservations] = useState(0)

  // const isSmallViewPort = useMediaQuery((theme) => theme.breakpoints.down('md'))
  // const anchor = !isSmallViewPort ? 'top' : 'bottom'
  // const [anchorEl, setAnchorEl] = useState(null)
  // const [numOfReservations, setNumOfReservations] = useState(0)

  const isSmallViewPort = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const anchor = !isSmallViewPort ? 'top' : 'top'
  const [anchorEl, setAnchorEl] = useState(null)

  // const useStyles = makeStyles((theme) => ({
  //   drawerPaper: {
  //     top: "0%",
  //     right: "20%",
  //     minHeight: "fit-content",
  //     [theme.breakpoints.up('md')]: {
  //       position: "absolute!important",
  //       top: "10%!important",
  //       // top:"0",
  //       left: "unset!important",
  //       right: "100px",
  //     }
  //   },
  //   popoverPaper: {
  //     minWidth: "310px!important",
  //     marginTop: "1rem",
  //   }
  // }))
  // const classes = useStyles()

  const onLogout = () => {
    try {
      dispatch(logoutUser())
      signOut()
      if (window.FB) window.FB.logout()
    } catch (err) { }
    history.push(home_path)
  }

  const onLogoutSuccess = () => {
    console.log('Successfully logout from google')
  }

  const onFailure = err => {
    console.log('google logout failure', err)
  }

  const { signOut } = useGoogleLogout({
    onFailure,
    clientId:
      '55700200557-8un2ipb96sqmhpuuf8ilh9riv062st5j.apps.googleusercontent.com',
    onLogoutSuccess
  })

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverRef = useRef(null)
  const timeoutDuration = 200
  const popoverId = openedPopover ? 'nav-profile' : undefined
  // const popoverAnchor = Boolean(anchorEl)

  const closePopover = () => {
    return popoverRef.current?.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "Escape",
        bubbles: true,
        cancelable: true
      })
    )
  }

  const toggleMenuContent = (e) => {
    if (!isSmallViewPort) {
      setOpenedPopover(prevState => !prevState)
      if (anchorEl) {
        setAnchorEl(null)
      }
      else {
        setAnchorEl(e.currentTarget.parentNode)
      }
    }
    else {
      setOpenDrawer(prevState => !prevState)
    }
  }
  const handlePopoverEnter = ({ currentTarget }) => {
    setOpenedPopover(true)
  };

  const hadnlePopoverLeave = ({ currentTarget }) => {
    setOpenedPopover(false)
  };

  const onMouseEnter = (open) => {
    if (open) return;
    return popoverRef.current?.click()
  }
  const onMouseLeave = (open) => {
    if (!open) return
    setTimeout(() => closePopover(), timeoutDuration)
  }

  const handleNavigation = (path) => {
    toggleMenuContent()
    history.push(path)
  }
  const handleReservationsNavigation = (path, type) => {
    handleReservationsType(type)
    handleNavigation(path)
  }

  const handleReservationsType = (type) => {
    dispatch(setInitReservationType(type))
  }

  const handleAuthentication = (auth, path) => {
    toggleMenuContent()
    if (auth) {
      onLogout()
    }
    else {
      // onAuthProtectedClick(profile_page_path)
      dispatch(setOnFullPageModal(true))
      dispatch(setActiveModal(login_modal))
    }
    if (path) {
      history.push(path)
    }
  }

  const listItems = [
    isAuthenticated && !isEmpty(currentReservation) && currentReservation?.order_status !== orderStatusEnum.booked && { text: "Current Order", onClick: () => handleReservationsNavigation(reservations_path_now, reservationTypeEnum.ongoing), path: reservations_path_now, icon: <RestaurantIcon sx={{ color: "#020083!important" }} />, hasSeparator: true, section: "Your Bookings & Orders" },
    isAuthenticated && !isEmpty(currentReservation) && currentReservation?.order_status !== orderStatusEnum.booked && { text: "Current Menu", onClick: () => handleNavigation(order_page_path.replace(':reservationId', currentReservation.reservation_id)), path: order_page_path, icon: <MenuBookIcon sx={{ color: "#020083!important", }} /> },
    isAuthenticated && !user?.is_anonymous && { text: "Upcoming Bookings and Orders", onClick: () => handleReservationsNavigation(reservations_path_upcoming, reservationTypeEnum.upcoming), path: reservations_path_upcoming, icon: <AccessTimeIcon sx={{ color: "#020083!important" }} />, hasSeparator: !(!isEmpty(currentReservation)), section: !(!isEmpty(currentReservation) && currentReservation?.order_status !== orderStatusEnum.booked) ? "Your Bookings & Orders" : null },
    isAuthenticated && !user?.is_anonymous && { text: "Your History", onClick: () => handleReservationsNavigation(reservations_path_past, reservationTypeEnum.past), path: reservations_path_past, icon: <HistoryIcon sx={{ color: "#020083!important" }} />, },
    { text: "About us", onClick: () => handleNavigation(aboutus_page_path), path: aboutus_page_path, icon: <InfoIcon sx={{ color: "#020083!important" }} />, hasSeparator: true, section: "About Voila" },
    { text: "Help", onClick: () => handleNavigation(help_page_path), path: help_page_path, icon: <HelpIcon sx={{ color: "#020083!important" }} /> },
    { text: "Terms and conditions", onClick: () => handleNavigation(terms_page_path), path: terms_page_path, icon: <GavelIcon sx={{ color: "#020083!important" }} /> },
    { text: "Privacy policy", onClick: () => handleNavigation(privacy_page_path), path: privacy_page_path, icon: <PrivacyTipIcon sx={{ color: "#020083!important" }} />, },

    isAuthenticated && !user?.is_anonymous && { text: "Your profile", onClick: () => handleNavigation(profile_page_path), path: profile_page_path, icon: <AccountCircleIcon sx={{ color: "#020083!important" }} />, hasSeparator: true, section: "Your Account" },
    isAuthenticated && !user?.is_anonymous && { text: "Your password", onClick: () => handleNavigation(password_change_path), path: password_change_path, icon: <VpnKeyIcon sx={{ color: "#020083!important" }} /> },
    isAuthenticated && !user?.is_anonymous && { text: "Credit card information", onClick: () => handleNavigation(credit_card_information_path), path: credit_card_information_path, icon: <CreditCardIcon sx={{ color: "#020083!important" }} /> },
    isAuthenticated && !user?.is_anonymous ?
      { text: "Log out", onClick: () => handleAuthentication(true, home_path), icon: <LoginIcon sx={{ color: "#020083!important" }} /> } :
      { text: "Log in or register", onClick: () => handleAuthentication(false), icon: <LogoutIcon sx={{ color: "#020083!important" }} />, hasSeparator: true, section: "Your Account" },

  ]

  const voilaLogoImage =
    <Image
      alt="logo"
      src={location.pathname === landing_path && !isAuthenticated
        ? isDown
          ? BlueLogo
          : LandingLogo
        : BlueLogo
      }

      className={
        location.pathname === landing_path &&
          !isAuthenticated ?
          'logo' :
          'blue-logo'}
    />

  const voilaLogoImageLink =
    (isAuthenticated && location.pathname === home_path) || location.pathname === landing_path ?
      voilaLogoImage :
      <Link to={home_path} className="logo-wrapper">
        {voilaLogoImage}
      </Link>


  const drawerContent =
    <>
      {isSmallViewPort ?
        <Grid container sx={{ display: "flex", flexDirection: "row", padding: "1.25rem 1.25rem 0.75rem", minWidth:'20rem' }}>
          <Grid item xs={6} sx={{ display: "flex", }}>
            <div onClick={toggleMenuContent}>
              {voilaLogoImageLink}
            </div>
          </Grid>
          <Grid item xs={6} display={"grid"} >
            <Box display={'grid'} justifySelf={'end'} alignSelf={'center'} sx={{ width: "1.5rem", height: "1.5rem" }} onClick={toggleMenuContent}>
              <CloseIcon sx={{ color: "#020083!important", }} fontSize="medium" />
            </Box>
          </Grid>
        </Grid> : null
      }
      <List sx={isSmallViewPort ? { padding: "0" } : {}}>
        {listItems.filter(item => !isEmpty(item)).map((item, idx) => {
          return (
            <Box key={idx}>
              {item?.hasSeparator ?
                <div className="section-name2" style={item && idx === 0 ? { padding: "0.25rem 1.5rem 0.5rem" } : {}}>
                  <div className="name-separator2" />{' '}
                  <span style={{ fontSize: "18px!important" }}>{item?.section}</span>
                  <div className="name-separator2" />
                </div>
                :
                null
              }
              <ListItem sx={{ padding: "0 0.5rem 0.5rem" }}>
                <ListItemButton sx={{padding: "0.25rem 0.5rem"}} onClick={item.onClick}>
                  <ListItemIcon sx={{ minWidth: "2.5rem" }}>
                    {item?.icon}
                  </ListItemIcon>
                  {/* <ListItemText sx={{
                  // fontFamily: "Cormorant!important",
                  // fontSize: "1.25rem!imporant"
                  fontFamily:'Cormorant-bold'
                }} primary={item.text + 0} /> */}
                  <p style={{ fontFamily: fontFamiliesEnum.lato, fontSize: "1rem", }}>
                    {item.text}
                  </p>
                </ListItemButton>
              </ListItem>
            </Box>
          )
        })}
      </List>
    </>


  const onNavItemClick = path => {
    history.push(path)
  }

  const onAuthProtectedClick = path => {
    dispatch(setPageRoutePath(path))
  }

  const openModalHandler = modal => {
    dispatch(setActiveModal(modal))
  }

  // const handleNumOfReservations = useCallback(() => {
  //   let numReservations = 0;
  //   if (!isEmpty(reservations?.ongoing)) {
  //     numReservations = 1;
  //   }
  //   numReservations += reservations.upcoming.length;
  //   setNumOfReservations(numReservations);

  //   return () => {
  //     setNumOfReservations(0);
  //   }
  // }, [reservations]);

  // useEffect(() => {
  //   handleNumOfReservations()
  // }, [handleNumOfReservations])

  return (
    <header
      ref={headerRef}
      className={classnames('container sticky-top ', {
        'landing-nav': location.pathname === landing_path,
        'nav-bg-scrolled': isDown,
        'home-nav': location.pathname === home_path
      })}
    >
      <nav
        className={classnames('navbar navbar-expand-lg',
          {
            'landing-brand':
              location.pathname === landing_path && !isAuthenticated
          }
        )}
      >
        <div className="nav-item-page">
          {
            showNavigationArrow ?
              <Link to={() => navigateToParentUrl(location.pathname, reservation?.restaurant_id, reservation?.reservation_id)}>
                <ArrowBackIcon sx={{ color: "#020083!important" }} fontSize='medium' />
              </Link>
              :
              null

          }
          {/* {(!isAuthenticated && location.pathname !== home_path) || (location.pathname !== landing_path) ? (
            <Link to={''} className="logo-wrapper">
              {voilaLogoImage}
            </Link>
          ) : (
            voilaLogoImage
          )} */}
          {voilaLogoImageLink}

        </div>



        <div>

          <ul className="navbar-nav ml-auto">
            <div
              aria-describedby={popoverId}
              className={
                location.pathname === landing_path
                  ? 'landing-nav-items'
                  : location.pathname === home_path
                    ? 'home-nav-items'
                    : ''
              }
            >  {
                !isSmallViewPort ?
                  <CustomPopover
                    id={popoverId}
                    // paperClasses={classes.popoverPaper}
                    content={drawerContent}
                    open={openedPopover}
                    popoverEnter={onMouseEnter}
                    popoverLeave={onMouseLeave}
                    togglePopover={toggleMenuContent}
                    anchorEl={anchorEl}
                    disableRestoreFocus
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}

                  />
                  :
                  <CustomDrawer
                    // paperClasses={classes.drawerPaper}
                    anchor={anchor}
                    open={openDrawer}
                    toggleDrawer={toggleMenuContent}
                    content={drawerContent}
                    zIndex={9009}
                  />

              }

              {navItems[location.pathname === landing_path ? 'landing' : 'user']({
                onNavItemClick,
                onAuthProtectedClick,
                openModalHandler,
                toggleMenuContent,
                handlePopoverEnter,
                hadnlePopoverLeave,
                // isNewNavigationMenu,
                // numOfReservations: numOfReservations
              }).map(({ component: Component, props: navItemProps }, idx) => {
                return (
                  <Component
                    key={idx}
                    {...navItemProps} />
                )
              })}
            </div>
            {/*Change temporarily for production, when apps are done change back*/}
            {/*<GetTheAppButton*/}
            {/*  pathname={location.pathname}*/}
            {/*  renderConditionally={true} */}
            {/*/>*/}
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Navigation
