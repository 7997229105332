import axios from "axios"
import {
    SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED,
    SET_ACTIVE_MENU_SECTION_ITEMS_WITH_ROUND_FOR_USER_APPROVED,
    SET_HAS_INITIAL_MENU_OPENED,
    SET_IS_MENU_STEPS_FEATURE_ENABLED,
    SET_MENU_DRAWER_OPENED,
    SET_MENU_ITEM_RECOMMENDATIONS,
    SET_MENU_ORDERS_DRAWER_OPENED,
    SET_MENU_SELECTED_STEP,
    SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL,
    SET_RESTAURANT_FILTERED_MENU_BY_STEPS,
    SET_RESTAURANT_MENU_APPROVED
} from "../../../config/actionTypes"
import { get_restaurant_menu_approved_api } from "../../../config/api_endpoints"
import { setLoader, setToastMessage } from "../../../store/actions/feedbackActions"


export const getRestaurantMenuApproved = (restaurantId, reservationId, isNow) => async dispatch => {
    try {
        dispatch(setLoader(true))
        const { data } = await axios.post(get_restaurant_menu_approved_api.replace(":restaurantId", restaurantId), { reservationId, isNow })
        dispatch(setRestaurantMenuApproved(data))
    } catch (err) {
        if (err.response.data.message) {
            dispatch(setToastMessage({
                message: err.response?.data?.message || 'Failed to get menu',
                severity: "error",
            }))
        }
    }
    finally {
        dispatch(setLoader(false))
    }
}

export const setRestaurantFilteredMenuBySteps = payload => dispatch => {
    dispatch({ type: SET_RESTAURANT_FILTERED_MENU_BY_STEPS, payload })
}

export const setRestaurantMenuApproved = payload => dispatch => {
    dispatch({ type: SET_RESTAURANT_MENU_APPROVED, payload })
}
export const setMenuSelectedStep = payload => dispatch => {
    dispatch({ type: SET_MENU_SELECTED_STEP, payload })
}

export const setActiveMenuAndSectionInfoApproved = payload => dispatch => {
    dispatch({ type: SET_ACTIVE_MENU_AND_SECTION_AND_ITEMS_INFO_APPROVED, payload })
}
export const setActiveMenuSectionItemsWithRoundForUserApproved = payload => dispatch => {
    dispatch({ type: SET_ACTIVE_MENU_SECTION_ITEMS_WITH_ROUND_FOR_USER_APPROVED, payload })
}

export const setMenuItemRecommendations = payload => dispatch => {
    dispatch({ type: SET_MENU_ITEM_RECOMMENDATIONS, payload })
}

export const setOnMenuItemRecommendationsModal = payload => dispatch => {
    dispatch({ type: SET_ON_MENU_ITEM_RECOMMENDATIONS_MODAL, payload })
}

export const setMenuDrawerOpened = payload => dispatch => {
    dispatch({ type: SET_MENU_DRAWER_OPENED, payload })
}
export const setMenuOrdersDrawerOpened = payload => dispatch => {
    dispatch({ type: SET_MENU_ORDERS_DRAWER_OPENED, payload })
}

export const setHasInitialMenuOpened = payload => dispatch => {
    dispatch({ type: SET_HAS_INITIAL_MENU_OPENED, payload })
}

export const setIsMenuStepsFeatureEnabled = payload => dispatch => {
    dispatch({type: SET_IS_MENU_STEPS_FEATURE_ENABLED, payload})
}