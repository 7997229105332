import InfoIcon from '@mui/icons-material/Info';
import { Button } from "@mui/material";
import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VoilaLogo from '../../assets/logo-voila.svg';
import { privacy_page_path, terms_page_path } from "../../config/pages_paths";
import { colorsEnum } from "../../enums/colorsEnum";
import { updateUserName } from "../../pages/auth/services/actions";
import { setUserUpdated } from "../../store/actions/usersActions";
import CustomButton from "../common/CustomButton";
import Image from "../common/Image";
import InputField from "../common/InputField";
import Modal from "../modal/Modal";
import useFloatingIslandActions from '../../hooks/useFloatingIslandActions';
import { setOnFullPageModal } from '../../store/actions/feedbackActions';

function UpdateUserNameModal({ show, close }) {
    const [firstName, setFirstName] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [showPlatformMessage, setShowPlatformMessage] = useState(false)

    const dispatch = useDispatch()

    const {
        // restaurants: { restaurant },
        booking: { currentReservation, reservations: { ongoing } }
    } = useSelector(state => state)


    const handleCloseModal = () => {
        if (firstName.length < 3) {
            setErrorMsg("Your name should be at least 3 characters")
        }
        else if (firstName.length > 12) {
            setErrorMsg("Your name should be less than 12 characters")
        }
        else {
            if (ongoing) {
                dispatch(updateUserName({ first_name: firstName, reservation_id: ongoing.reservation_id }))
                dispatch(setUserUpdated(true))
            }
            close()
        }
    }

    const handleNameChange = e => {
        setFirstName(e.target.value)
        if ((firstName.length >= 2 || firstName.length < 12) && !isEmpty(errorMsg)) {
            setErrorMsg("")
        }
    }

    // const handleShowPlatformMessage = () => {
    //     setShowPlatformMessage(prevState => !prevState)
    // }

    // useEffect(() => {
    //   dispatch(setOnFullPageModal(true))
    //   return () => dispatch(setOnFullPageModal(false))
    // }, [dispatch])

    // const { createButtonPortal, proceed, DOMslot1, } = useFloatingIslandActions()

    return (
        <Modal show={show} className="update-name-modal" isPopUp={false}>
            {/* <ModalHeading title="Please enter your name" /> */}
            <div style={{ display: "flex", flexDirection: "column", minHeight: "95%" }}>
                {/* {!isEmpty(ongoing?.logo) && <img src={ongoing.logo} alt={'restaurant-logo'} style={{ width: "100%", margin: "0 0 1rem", borderRadius: "0.5rem" }} />} */}
                <Image src={currentReservation?.restaurant_logo ?? currentReservation?.restaurant_image} alt={'restaurant-logo'} style={{ width: "100%", margin: "0 0 1rem", borderRadius: "1rem", height: "200px", objectFit: "cover" }} restaurant />

                <h3>Please enter your name</h3>
                <InputField
                    name="firstName"
                    onChange={handleNameChange}
                    label=""
                    value={firstName}
                    error={errorMsg}
                    containerClassName='update-name-input'
                />

                <div style={{ marginBottom: "0.5rem" }}>
                    <p>Powered by:</p>
                    <img alt='logo_voila' className='voila-logo' src={VoilaLogo} />
                </div>

                <p>
                    By continuing you agree to the
                    <Link to={terms_page_path}>
                        <span style={{ color: "#020083" }}>
                            {' '} Voila Terms of Use {' '}
                        </span>
                    </Link>
                    and the
                    <Link to={privacy_page_path}>
                        <span style={{ color: "#020083" }}>
                            {' '} Voila Privacy Policy {' '}
                        </span>
                    </Link>
                    applies.
                </p>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0.5rem 0" }}>
                    {!showPlatformMessage ?
                        <Button onClick={setShowPlatformMessage}>
                            <InfoIcon fontSize="medium" color={colorsEnum.navyBlue} />
                        </Button> :
                        <p style={{ margin: "1rem 0 2rem" }}>By using our platform you will be charged a total of 20% of your check amount, that consists of a 3% platform fee and a 17% default tip, which is customizable and you can adjust it as you wish</p>
                    }
                </div>

                {/* <CustomButton
                    onClick={handleCloseModal}
                    text="Proceed"
                    style={{ margin: "1rem 0" }}
                /> */}

                {/* { createButtonPortal(proceed(handleCloseModal, null, null, true), DOMslot1) } */}

            </div>
            <div className={`floating-island-container`}
                // style={!!showPlatformMessage && window.innerHeight < 700 ? {bottom:"-10%", paddingBottom:"0.5rem"} : {}}
                style={{ position: "relative", marginTop: "auto" }}
            >
                <div className='floating-island-wrapper'>
                    <div className={`floating-island-button-wrapper-full`}>
                        <button type={'button'} onClick={handleCloseModal} className={"floating-island-button"}>Proceed</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default UpdateUserNameModal